import React, { useState } from 'react'
import Page from '../../components/Layout/Page'
import './Ticket.css'
import axios from 'axios'

function Ticket() {
  const [service_number, setNumber] = useState('')
  const [err, setErr] = useState('')
  const [saldo, setSaldo] = useState('')
  const [address, setAddress] = useState('')
  const [expiration, setExpiration] = useState('')
  const [url, setUrl] = useState('')
  const [loading, setLoading] = useState(false)

  function handleChange(event) {
    setNumber(event.target.value)
  }

  async function onButtonClick() {
    setLoading(true)
    setErr('');
    const response = await axios.post('/api/ticket', {
      service_number: service_number
    })

    if (response.status === 200) {
      if (response.data.success) {
        const data = response.data.data
        setSaldo(data.saldo)
        setAddress(data.install_address)
        setExpiration(data.expiration_date)
        setUrl(data.doc_url)
      } else {
        setErr(response.data.message);
      }
    } else {
      setErr('No se pudo cargar la información')
    }

    setLoading(false)
  }

  return (
    <Page
      title="Aguas Patagonia | Mi Boleta"
      current='ticket'
    >
      <div className='ticket-container'>
        <div className='icon-container-ticket'>
          <img src='/img/icon8.png' alt='icon'></img>
        </div>
        <h1>Mi boleta</h1>
        <div className='ticket-information'>
          <div className='image-container-ticket'>
            <img src='/img/boleta.png' alt='boleta'></img>
          </div>
          <div className='text-container-ticket'>
            <p>Ingrese su número de servicio para consultar su saldo e imprimir boleta:</p>
            <div className='input-container'>
              <label>
                Número Servicio sin dígito verificador<br />
                <input type="text" className='information-input-ticket' id='number' onChange={handleChange} />
              </label>
            </div>
            <button className='blue-button ticket-button' disabled={loading} onClick={onButtonClick}>Consultar Saldo</button>
            <div className="loading">
              {loading ?
                (<img src="/img/loading.gif" alt="Cargando..." />)
              : null}
            </div>
            <div className="error-message">
              {err !== '' ?
                (<p>{err}</p>)
              : null}
            </div>
            <div className="saldo">
              {saldo !== '' ?
                (<div><p><strong>Monto adeudado:</strong></p><p>{saldo}</p></div>)
              : null}
            </div>
            <div className="address">
              {address !== '' ?
                (<div><p><strong>Dirección de instalación:</strong></p><p>{address}</p></div>)
              : null}
            </div>
            <div className="expiration">
              {expiration !== '' ?
                (<div><p><strong>Fecha de vencimiento boleta:</strong></p><p>{expiration}</p></div>)
              : null}
            </div>
            <div className="url">
              {url !== '' ?
                (<p>Descarga tu boleta <a href={url} rel="noopener noreferrer" target="_blank">aquí</a></p>)
              : null}
            </div>
          </div>
        </div>
      </div>
    </Page >
  )
}

export default Ticket