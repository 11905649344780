import React, { useState, useEffect } from 'react'
import axios from 'axios';
import FileViewer from "react-file-viewer";
import {
  CircularProgress,
} from '@material-ui/core';
import Page from '../../components/Layout/Page'
import "./Education.css"

function Education() {

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const [planFiles, setPlanFiles] = useState([]);
  const [initialPlanFiles, setInitialPlanFiles] = useState([]);
  const [planLoadMore, setPlanLoadMore] = useState(false);

  const [docsFiles, setDocsFiles] = useState([]);
  const [initialDocsFiles, setInitialDocsFiles] = useState([]);
  const [docsLoadMore, setDocsLoadMore] = useState(false);

  const [galleryFiles, setGalleryFiles] = useState([]);
  const [initialGalleryFiles, setInitialGalleryFiles] = useState([]);
  const [galleryLoadMore, setGalleryLoadMore] = useState(false);

  const meta = [
    {
      name: 'aguas patagonia',
      content: 'aguaspatagonia'
    },
    {
      name: 'educacion',
      content: 'educacion'
    },
  ]

  useEffect(() => {
    const loadFiles = async () => {
      try {
        const response = await axios.get('/api/education/documents');
        setLoading(false);

        const { documents } = response.data.data;
        const plan = documents.reduce((p, c) => {
          if (c.content_type === 'Planificaciones' && !c.hidden) p.push(c);
          return p;
        }, []);
        const docs = documents.reduce((p, c) => {
          if (c.content_type === 'Documentos educativos' && !c.hidden) {
            if (c.title.includes('Consejos de inv')) p.unshift(c)
            else p.push(c)
          };
          return p;
        }, []);
        const gallery = documents.reduce((p, c) => {
          if (c.content_type === 'Galerías de fotos' && !c.hidden) p.push(c);
          return p;
        }, []);

        setPlanFiles(plan);
        setDocsFiles(docs);
        setGalleryFiles(gallery);

        setInitialPlanFiles(plan.slice(0, 3));
        setInitialDocsFiles(docs.slice(0, 3));
        setInitialGalleryFiles(gallery.slice(0, 3));
      } catch (err) {
        setLoading(false);
        setError('Ha ocurrido un error cargando los archivos.');
      }
    };

    loadFiles();
  }, []);
  
  const renderFiles = (items) => items ? items.map((f) => {
    return (
      <div className="file-gallery-item">
        {/* <a href={f.url_file} target="__blank" rel="noopener noreferrer">
          <FileViewer fileType={fileExt} filePath={f.url_file} />
        </a> */}
        <div className="file-gallery-item-thumbnail">
          <img src={f.thumbnail} alt={f.title} />
        </div>
        <div className="file-gallery-item-text">
          <h2><a href={f.url_file} target="__blank" rel="noopener noreferrer">{f.title}</a></h2>
          <p>{f.description}</p>
        </div>
      </div>
    );
  }) : null;

  return (
    <Page
      title="Aguas Patagonia | Información Educativa"
      current="sustainability"
      meta={meta}
    >
      <div className="page-container-forms">
        <div className="icon-container-dev">
          <img src="/img/icon5.png" alt="icon"></img>
        </div>
        <div className="text-wrapper-forms">
          <h1>Aguas Patagonia y Escuela</h1>
          <h2>Educación del cuidado del agua desde la perspectiva de las cuencas locales</h2>
          <p>
            En este espacio dirigido a docentes y apoderados, podrá encontrar contenidos educativos
            sobre el cuidado del agua, cuencas hídricas locales, proceso de producción de agua potable
            y el tratamiento de aguas servidas, considerando las responsabilidades de la comunidad en
            el buen funcionamiento del proceso.
          </p>
          <h3>Buscamos</h3>
          <ul>
            <li>Potenciar el vínculo de los estudiantes con el medioambiente desde el conocimiento de las cuencas locales y su importancia para las comunidades y el planeta.</li>
            <li>
              Entregar contenidos pedagógicos que sean de utilidad a la comunidad regional y
              nacional, que aborden específicamente la producción de agua potable y el
              tratamiento de aguas servidas desde la óptica de las cuencas hídricas locales.
            </li>
          </ul>
          <h3>Descarga acá los recursos educativos</h3>
          <h4>Documentos educativos</h4>
          <div className="file-gallery-wrapper">
            {loading ? (
              <CircularProgress />
            ) : (
              <div>
                {error ? (
                  <p>{error}</p>
                ) : (
                  <div>
                    <div className="file-gallery">
                      {docsLoadMore ? renderFiles(docsFiles) : renderFiles(initialDocsFiles)}
                    </div>
                    {docsLoadMore ? '' : (<div className="load-more-link" onClick={() => setDocsLoadMore(true)}>Cargar todos</div>)}
                  </div>
                )}
              </div>
            )}
          </div>
          <h4>Planificaciones</h4>
          <div className="file-gallery-wrapper">
            {loading ? (
              <CircularProgress />
            ) : (
              <div>
                {error ? (
                  <p>{error}</p>
                ) : (
                  <div>
                    <div className="file-gallery">
                      {planLoadMore ? renderFiles(planFiles) : renderFiles(initialPlanFiles)}
                    </div>
                    {planLoadMore ? '' : (<div className="load-more-link" onClick={() => setPlanLoadMore(true)}>Cargar todos</div>)}
                  </div>
                )}
              </div>
            )}
          </div>
          <h4>Galería de fotos</h4>
          <div className="file-gallery-wrapper">
            {loading ? (
              <CircularProgress />
            ) : (
              <div>
                {error ? (
                  <p>{error}</p>
                ) : (
                  <div>
                    <div className="file-gallery">
                      {galleryLoadMore ? renderFiles(galleryFiles) : renderFiles(initialGalleryFiles)}
                    </div>
                    {galleryLoadMore ? '' : (<div className="load-more-link" onClick={() => setGalleryLoadMore(true)}>Cargar todos</div>)}
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </Page>
  );
}

export default Education;
