import React from 'react'
import Page from '../../components/Layout/Page'
import './Video.css';

export default function Video() {
    return (
        <Page
            title="Aguas Patagonia | Video Corporativo"
            current='clients'
        >
            <div className="video-page-container">
                <div className='icon-container-shortage'>
                    <img src='/img/icon5.png' alt='icon'></img>
                </div>
                <div className='text-wrapper'>
                    <div className="paragraph-wrapper">
                      <h2>Te invitamos a conocer el ciclo del Agua Potable</h2>
                      <iframe src="https://www.youtube.com/embed/WnpQXlhJy4E?autoplay=1&mute=1" title="video"></iframe>
                      <p>Te invitamos a recorrer el ciclo del Agua Potable, desde la captación, pasando por nuestras Plantas de Tratamiento de Agua Potable, hasta el arranque de cada domicilio, para luego ser tratada en la Planta de Tratamiento de Aguas Servidas.</p>
                    </div>
                </div>
            </div>
        </Page >
    )
}