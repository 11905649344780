import React, { useState } from 'react';
import './ContactForm.css';
import axios from 'axios';
// import { Link } from 'react-router-dom';

function ContactForm() {
    const [formValues, setValues] = useState({
        name: '',
        surname: '',
        email: '',
        phone: '',
        address: '',
        city: '',
        reqs: ''
    });

    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')
    const [success, setSuccess] = useState('')

    function handleChange(event) {
        const id = event.target.id
        const value = event.target.value
        let newForm = { ...formValues }
        newForm[id] = value
        setValues(newForm)
    }

    const submitForm = async () => {
      setLoading(true)
      const response = await axios.post('/api/contacto', formValues)
      setLoading(false)

      if (response.status === 200) {
        if (response.data.success) {
          setSuccess('Mensaje enviado.')
          setValues({
            name: '',
            surname: '',
            email: '',
            phone: '',
            address: '',
            city: '',
            reqs: ''
          });
        } else {
          setError('Ha ocurrido un error. Por favor intente más tarde.')
        }
      } else {
        setError('Ha ocurrido un error. Por favor intente más tarde.')
      }
    }

    return (
        <div>
            <form className='contact-form' action="/contacto" method="POST">
                <div className='form-row'>
                    <div className='form-information'>
                        <label>
                            {'Nombre(s):'}<br />
                            <input type="text" name="Nombre" value={formValues.name} className='information-input' id='name' onChange={handleChange} />
                        </label>
                    </div>

                    <div className='form-information'>
                        <label>
                            {'Apellido(s):'}<br />
                            <input type="text" name="Apellido" value={formValues.surname} className='information-input' id='surname' onChange={handleChange} />
                        </label>
                    </div>
                </div>
                <div className='form-row'>
                    <div className='form-information'>
                        <label>
                            {'Email:'}<br />
                            <input type="text" name="Email" value={formValues.email} className='information-input' id='email' onChange={handleChange} />
                        </label>

                    </div>
                    <div className='form-information'>
                        <label >
                            {'Telefono:'}<br />
                        </label>
                        <input type="text" name="Teléfono" value={formValues.phone} className='information-input' id='phone' onChange={handleChange} />
                    </div>
                </div>
                <div className='form-row'>
                    <div className='form-information'>
                        <label>
                            {'Dirección:'}<br />
                            <input type="text" name="Direccion" value={formValues.address} className='information-input' id='address' onChange={handleChange} />
                        </label>
                    </div>
                    <div className='form-information'>
                        <label>
                            {'Ciudad:'}<br />
                            <input type="text" name="Ciudad" value={formValues.city} className='information-input' id='city' onChange={handleChange} />
                        </label>
                    </div>
                </div>
                <div className='form-row'>
                    <div className='form-information'>
                        <label>
                            {'Requerimientos y reclamos:'}<br />
                            <textarea name="Requerimientos y reclamos" value={formValues.reqs} onChange={handleChange} id='reqs' className='information-textarea' />
                        </label>
                    </div>
                </div>
                <button className="information-submit" disabled={loading} type="button" value="Enviar" onClick={() => submitForm()}>Enviar</button>
                <div className="loading">
                  {loading ?
                    (<img src="/img/loading.gif" alt="Cargando..." />)
                  : null}
                </div>
                <div>
                  <p className="success-message">{success}</p>
                  <p className="error-message">{error}</p>
                </div>
                {/* <input type="submit" value="Enviar" className='information-submit' /> */}
            </form>
        </div>
    );
}

export default ContactForm;