import React from 'react';
import Page from '../../components/Layout/Page';
import './FondoConcursable.css';


const FondoConcursable = () => (
  <Page
      title="Aguas Patagonia | II Versión Fondo Concursable Juntas de Vecinos - Patagones Todos"
      current='clients'
    >
    <div className="fondo-concursable-wrapper">
      <div className="fondo-concursable">

        <h1>Fondo Concursable "Patagones Todos"<br/>Juntas de Vecinos Puerto Aysén Urbano</h1>
        <h2>Formulario de Postulación</h2>
        <p>
          El Fondo Concursable de Aguas Patagonia busca contribuir a la calidad de vida de nuestros clientes y al desarrollo regional, con especial énfasis en los focos de nuestro Plan de Sostenibilidad, a través del apoyo a proyectos de Juntas de vecinos que quieran contribuir a potenciar esta línea regional.
        </p>
        <ol>
          <li>A través de este aporte se espera que las Juntas de Vecinos puedan impulsar sus proyectos en las líneas de medio ambiente, inclusión y comunidad, turismo, educación y deporte, emprendimiento.</li>
          <li>Podrán participar Juntas de Vecinos pertenecientes a la localidad de Puerto Aysén.</li>
        </ol>
        <a href="https://aguas-patagonia-public-files.s3.sa-east-1.amazonaws.com/BASES_Patagones+Todos+Ayse%CC%81n.docx.pdf" target="_blank" rel="noopener noreferrer">Descarga las bases aquí</a>
        <h2>¿Cómo postular mi idea?</h2>
        <p>Completa la información de tu organización en el siguiente enlace:</p>
        <a href="https://docs.google.com/forms/d/e/1FAIpQLSdhp8Bx5Wy4CAK_iUL7b-POhXl8Vwl3kEVD1aWgtay9UAfxDw/viewform">Formulario</a>
        <p>Cuando completes tus datos y envíes este formulario nos pondremos en contacto con tu organización.</p>
        <h2>Contacto</h2>
        <ul>
          <li>Franchesca Alarcón: <a href="mailto:falarcon@aguaspatagonia.cl">falarcon@aguaspatagonia.cl</a> Teléfono: (+569) 4417 5962</li>
          <li>Paulina Vera: <a href="mailto:paulinavera@simondecirene.cl">paulinavera@simondecirene.cl</a> Teléfono:  (+569) 4341 8475</li>
        </ul>
        <div className="fondo-post">
          <img src="https://aguas-patagonia-public-files.s3.sa-east-1.amazonaws.com/PatagonesTodos_Post.png" alt="Patagones Todos" />
        </div>
      </div>
    </div>
  </Page>
);

export default FondoConcursable;
