import React, { useState } from 'react'
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';
import './OfficesMap.css'

function OfficesMap(props) {
  const [openDropdown, setOpenDropdown] = useState(false)
  const [currentOffice, setCurrentOffice] = useState({
    name: 'Coyhaique',
    address: 'Carlos Condell 22, Coyhaique, Aysén',
    times: 'Lunes a Viernes 8:45-17:00 horario continuado',
    coordinates: { lat: -45.569478, lng: -72.064395 }
  })

  const offices = [
    {
      name: 'Coyhaique',
      address: 'Carlos Condell Nº22',
      times: 'Lunes a Viernes 8:45-17:00 horario continuado',
      coordinates: { lat: -45.569478, lng: -72.064395 }
    },
    {
      name: 'Puerto Aysén',
      address: 'Av. Eusebio Ibar Nº693',
      times: 'Lunes a Viernes 9:30-13:00 y 14:15-17:00',
      coordinates: { lat: -45.4004771, lng: -72.6870 }
    },
    {
      name: 'Puerto Cisnes',
      address: 'R. Sotomayor Nº056',
      times: 'Lunes a Viernes 9:30-13:30',
      coordinates: { lat: -44.7255126, lng: -72.6819 }
    },
    {
      name: 'Balmaceda',
      address: 'José Silva Ormeño Nº54',
      times: 'Lunes, Miércoles y Viernes 10:00-13:00',
      coordinates: { lat: -45.9098211, lng: -71.6988 }
    },
    {
      name: 'Puerto Ibañez',
      address: 'Av. Padre Antonio Ronchi Nº114',
      times: 'Lunes, Míercoles y Viernes 10:00-13:00',
      coordinates: { lat: -46.29404, lng: -71.9360 }
    },
    {
      name: 'Chile Chico',
      address: 'Manuel Rodriguez Nº2',
      times: 'Lunes a Viernes 9:30-13:30',
      coordinates: { lat: -46.5365523, lng: -71.7316 }
    },
    {
      name: 'Cochrane',
      address: 'Las Golondrinas S/N',
      times: 'Lunes a Viernes 9:30-13:30',
      coordinates: { lat: -47.2579063, lng: -72.5743 }
    }
  ]

  const displayOffices = () => (
    <div className="offices-list">
      <button
        type="button"
        className={
          openDropdown ? 'offices-list-title active' : 'offices-list-title'
        }
        onClick={() => setOpenDropdown(!openDropdown)}
      >
        Oficinas
      </button>
      <div
        className={
          openDropdown ? 'offices-list-items open' : 'offices-list-items'
        }
      >
        {offices.map((of, i) => (
          <button
            type="button"
            key={of.name}
            className={
              (of.name === currentOffice.name ? 'selected-office ' : '') +
              (i === 0 ? 'offices-first' : '')
            }
            onClick={() => setCurrentOffice(of)}
          >
            {of.name}
          </button>
        ))}
      </div>
    </div>
  )

  const mapStyles = {
    maxWidth: '900px',
    width: '60vw',
    maxHeight: '500px',
  }

  return (
    <div className="offices-map-container">
      {/* <div className="office-img-hours">
        <img src="https://aguas-patagonia-public-files.s3.sa-east-1.amazonaws.com/Horario+atencion.png" alt="horarios" />
      </div> */}
      <div className="map-address-wrapper-off">
        <div className="offices-list-container">{displayOffices()}</div>
        <div className="info-and-map">
          <div className="address-and-hours">
            <div>Dirección: {currentOffice.address}</div>
            <div>Horario: {currentOffice.times}</div>
          </div>
          <div className="map-display-off">
            <Map
              google={props.google}
              zoom={18}
              style={mapStyles}
              center={currentOffice.coordinates}
              initialCenter={currentOffice.coordinates}
            >
              <Marker position={currentOffice.coordinates} title={currentOffice.name} />
            </Map>
          </div>
        </div>
      </div>
    </div>
  )
}

export default GoogleApiWrapper({
  apiKey: 'AIzaSyD-HsQOnWTQ1iDLTyhgaahBxkzpbX9Vznc',
})(OfficesMap)
