import React from 'react'
import MuiAccordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import { withStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Page from '../../components/Layout/Page'
import './Covid.css';

const Accordion = withStyles({
  root: {
    backgroundColor: '#00A69D',
    color: '#1E274A',
    marginTop: '10px',
    marginBottom: '10px',
  },
})(MuiAccordion);

function Covid() {
  return (
    <Page
      title="Aguas Patagonia | Ley 21.423 Covid-19"
      current='clients'
    >
      <div className="page-container-covid">
        <div className='icon-container-dev'>
          <img src='/img/icon5.png' alt='icon'></img>
        </div>
        <div className='text-wrapper-covid'>
          <h1>Ley 21.423</h1>
          <h2>Ley Nº 21.423 Subsidios a los Servicios Básicos</h2>
          <h4>Regula el prorrateo y pago de deuda generadas hasta el 31 de diciembre de 2021 y establece subsidios a clientes vulnerables.</h4>
          <p>
            La crisis sanitaria producto del virus Covid-19 ha afectado a muchas personas, de distintas formas.
            Consciente de ello, Aguas Patagonia ofrece facilidades excepcionales de pago en las cuentas de agua a los hogares más vulnerables.
            Asimismo, puso en práctica las disposiciones de la Ley de servicios básicos, que estuvo vigente hasta el 31 de diciembre de 2021.
            El 11 de febrero de 2022 fue promulgada la Ley 21.423 de subsidio a los servicios básicos,
            que busca ayudar a las familias más vulnerables que han acumulado deuda en sus cuentas de servicios básicos, durante la pandemia.
          </p>
          <div className="covid-collapse">
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <h3>¿Qué contempla la nueva Ley de subsidio a los servicios básicos?</h3>
              </AccordionSummary>
              <AccordionDetails>
                <div>
                  <p>Contempla que el Estado se hará cargo de una parte de las deudas acumuladas por los clientes más vulnerables entre el 18 de marzo de 2020 y el 31 de diciembre de 2021.</p>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1b-content"
                id="panel1b-header"
              >
                <h3>¿A quiénes beneficia?</h3>
              </AccordionSummary>
              <AccordionDetails>
                <div>
                  <p>Los beneficiarios son los clientes residenciales o domiciliarios que aún mantengan deudas generadas entre el 18 de marzo de 2020 y el 31 de diciembre de 2021, y cuyo consumo promedio mensual durante 2021 no supere los 15 m3.</p>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1c-content"
                id="panel1c-header"
              >
                <h3>¿Cómo se calcula el monto mensual que será subsidiado?</h3>
              </AccordionSummary>
              <AccordionDetails>
                <div>
                  <p>La cuota mensual de subsidio se calcula en tres pasos:</p>
                  <ol>
                    <li>El monto total adeudado correspondiente a las cuentas facturadas entre el 18 de marzo de 2020 y el 31 de diciembre de 2021, y se divide por 48.</li>
                    <li>Se calcula el 15% de la cuenta promedio mensual del año 2021.</li>
                  </ol>
                  <p>Una vez que se implemente la ley (desde marzo 2022), se incluirá en la cuenta mensual de los beneficiarios la cuota y el subsidio, compensándose entre sí, lo que en la práctica significa que los clientes sólo deberán pagar su consumo mensual.</p>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1d-content"
                id="panel1d-header"
              >
                <h3>¿Cómo se calcula el consumo promedio de un cliente beneficiado por esta ley?</h3>
              </AccordionSummary>
              <AccordionDetails>
                <div>
                  <p>Para el cálculo se consideran los consumos mensuales facturados de 2021. Si durante ese año no se contara con registro de los 12 meses de facturación por consumo, el promedio se determinará con el consumo de los meses de los que se tenga registro. Cabe señalar, que para este cálculo se excluyen los consumos prorrateados.</p>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1e-content"
                id="panel1e-header"
              >
                <h3>¿Se debe hacer algún trámite para obtener el beneficio?</h3>
              </AccordionSummary>
              <AccordionDetails>
                <div>
                  <p>No es necesario hacer trámite alguno, pues el beneficio es automático para quienes cumplan con las condiciones estipuladas en la ley, es decir, los clientes de las categorías señaladas en el inciso primero del artículo 1° de la ley N° 21.249 (categorías descritas en pregunta 2), con deuda entre el 18 de marzo de 2020 y el 31 de diciembre de 2021, y cuyo consumo promedio mensual en 2021 sea inferior o igual a los 15 m3 al mes.</p>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1f-content"
                id="panel1f-header"
              >
                <h3>¿Cuándo comienza a aplicarse el beneficio?</h3>
              </AccordionSummary>
              <AccordionDetails>
                <div>
                  <p>Se aplicará automáticamente a partir de marzo de 2022.</p>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1g-content"
                id="panel1g-header"
              >
                <h3>¿Qué pasa con las deudas anteriores o posteriores al período comprendido entre el 18 de marzo de 2020 y el 31 de diciembre de 2021?</h3>
              </AccordionSummary>
              <AccordionDetails>
                <div>
                  <p>Las deudas contraídas antes del 18 de marzo de 2020 o después del 31 de diciembre de 2021, no están consideradas en esta ley de subsidio, por lo que su no pago habilita a la compañía suspender el suministro de agua potable previo aviso al cliente, de acuerdo a lo que señala la normativa vigente.</p>
                  <i>* Cualquier cliente que se encuentre en estas situaciones puede acercarse a la compañía para regularizar su deuda.</i>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1h-content"
                id="panel1h-header"
              >
                <h3>¿Se puede perder el subsidio?</h3>
              </AccordionSummary>
              <AccordionDetails>
                <div>
                  <p>
                    Sí. Si un cliente deja de pagar su cuenta mensual, y queda en condiciones de ser cortado por deuda. La empresa notifica de corte con 2 saldos impagos.
                    Para conservar el beneficio lo invitamos a mantener su cuenta al día, y en el caso de entrar en morosidad le ofrecemos facilidades de pago para regularizar su deuda.
                  </p>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1i-content"
                id="panel1i-header"
              >
                <h3>¿Dónde y cómo se verá reflejado este beneficio?</h3>
              </AccordionSummary>
              <AccordionDetails>
                <div>
                  <p>En la cuenta que se emita a contar de marzo 2022 se indicará claramente el monto total adeudado, el monto subsidiado y la cantidad a pagar por el usuario.</p>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1j-content"
                id="panel1j-header"
              >
                <h3>¿Cómo puedo saber si soy beneficiario de esta Ley?</h3>
              </AccordionSummary>
              <AccordionDetails>
                <div>
                  <p>A contar de marzo de 2022, en las cuentas de agua se incluirá un mensaje, indicando el monto de la deuda y el valor de la cuota/subsidio mensual.</p>
                  {/*<p>Además, la empresa pondrá a disposición de sus clientes una página en su sitio Web, donde podrán consultar si su suministro es beneficiario de la Ley 21.423 con sólo ingresar su número de cliente.</p>*/}
                </div>
              </AccordionDetails>
            </Accordion>
          </div>
        </div>
      </div>
    </Page >
  )
}

export default Covid;
