import React from 'react'
import Page from '../../components/Layout/Page'
import ContactForm from '../../components/ContactForm/ContactForm'
import './Contact.css';

function Contact() {
    return (
        <Page
            title="Aguas Patagonia | Contacto"
            current='contact'
        >
            <div className='contact-wrapper'>
                <div className='icon-container-contact'>
                    <img src='/img/icon6.png' alt='icon'></img>
                </div>
                <h1>Contacto</h1>
                <div className='form-wrapper'>
                    <ContactForm />
                </div>
            </div>
        </Page>
    )
}

export default Contact