import React, { useState } from 'react'
import Page from '../../components/Layout/Page'
import './Winter.css'

function Winter() {
  const [currentImage, setCurrentImage] = useState(0);
  const images = [
    "img/CampanÞa-invierno-APA-2021-003.jpg",
    "img/CampanÞa-invierno-APA-2021-004.jpg",
    "img/CampanÞa-invierno-APA-2021-005.jpg",
    "img/CampanÞa-invierno-APA-2021-006.jpg",
    "img/CampanÞa-invierno-APA-2021-008.jpg"
  ];

  const nextImage = () => {
    setCurrentImage((currentImage + 1) % images.length);
  };

  const prevImage = () => {
    setCurrentImage((currentImage - 1 + images.length) % images.length);
  };

  return (
    <Page current="winter">
      <div className='center-container'>
        <div className='center-container-column'>
          <div id="winter-text-header" className="winter-header">
            <div className="winter-image-container">
              <img src="img/CampanÞa-invierno-APA-2021-002.jpg" alt="Invierno" />
            </div>
            <div className="winter-text-container">
              <h1>Recomendaciones de invierno</h1>
              <p>El medidor es un componente clave en la distribución de agua. Durante el invierno, es fundamental protegerlo del frío extremo para evitar posibles daños. Recomendamos aislarlo adecuadamente utilizando materiales aislantes como el plumavit entero o trozado, además de que tenga su tapa en adecuadas condiciones. Esto ayudará a mantenerlo en óptimas condiciones y garantizar un suministro confiable.</p>
            </div>
          </div>
          <div className="winter-gallery">
            <div className="winter-carousel">
              <button className="winter-carousel-button previous-button" onClick={prevImage}>&lt;</button>
              <img src={images[currentImage]} alt={`Imagen ${currentImage + 1}`} />
              <button className="winter-carousel-button next-button" onClick={nextImage}>&gt;</button>
            </div>
          </div>
        </div>
      </div>
    </Page>
  );
}

export default Winter;
