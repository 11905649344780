import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'

function AuthGuard({ children }) {
  const account = useSelector((state) => state.account)

  if (!account.user) {
    window.location.href = '/login'
  } else {
    return children
  }
}

AuthGuard.propTypes = {
  children: PropTypes.node.isRequired,
}

export default AuthGuard
