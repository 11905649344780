import React from "react";
import { Router } from "react-router-dom";
import { createBrowserHistory } from "history";
import { create } from "jss";
import rtl from "jss-rtl";
import MomentUtils from '@date-io/moment';
import { SnackbarProvider } from "notistack";
import {
  jssPreset,
  StylesProvider,
  ThemeProvider,
  makeStyles,
  createStyles,
} from "@material-ui/core";
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import "moment/locale/es";
import GoogleAnalytics from "./components/GoogleAnalytics";
import ScrollReset from "./components/ScrollReset";
import useSettings from "./hooks/useSettings";
import Auth from "./components/Auth/Auth";
import Routes from "./Routes";
import { createTheme } from "./theme";
import "./App.css";

const history = createBrowserHistory();
const jss = create({ plugins: [...jssPreset().plugins, rtl()] });

const useStyles = makeStyles(() =>
  createStyles({
    "@global": {
      "*": {
        // boxSizing: "border-box",
        // margin: 0,
        padding: 0,
      },
      html: {
        "-webkit-font-smoothing": "antialiased",
        "-moz-osx-font-smoothing": "grayscale",
        height: "100%",
        width: "100%",
      },
      body: {
        height: "100%",
        width: "100%",
      },
      "#root": {
        height: "100%",
        width: "100%",
      },
    },
  })
);

function App() {
  useStyles();

  const { settings } = useSettings();

  return (
    <ThemeProvider theme={createTheme(settings)}>
      <StylesProvider jss={jss}>
        <MuiPickersUtilsProvider locale="es" utils={MomentUtils}>
          <SnackbarProvider maxSnack={1}>
            <Router history={history}>
              <Auth>
                <ScrollReset />
                <GoogleAnalytics />
                <Routes />
              </Auth>
            </Router>
          </SnackbarProvider>
        </MuiPickersUtilsProvider>
      </StylesProvider>
    </ThemeProvider>
  );
}

export default App;
