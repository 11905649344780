import React from 'react'
import Page from '../../components/Layout/Page'
import './Forms.css';

function Forms() {
  return (
    <Page
      title="Aguas Patagonia | Formularios"
      current='clients'
    >
      <div className="page-container-forms">
        <div className='icon-container-dev'>
          <img src='/img/icon5.png' alt='icon'></img>
        </div>
        <div className='text-wrapper-forms'>
          <h1>Formularios</h1>
          <h2>Tratamiento y análisis de RILES</h2>
          <p>Una solución integral a la disposición final de residuos industriales líquidos (RILES) es la suscripción de un convenio con Aguas Patagonia para descargar los RILES a nuestras redes de alcantarillado. Disponemos de la capacidad técnica y operativa para recibir estos residuos de la industria regional, dándoles un adecuado tratamiento y una disposición final que cumple con toda la normativa legal vigente.</p>
          <p>A través de esta alternativa ofrecemos soluciones definitivas y sustentables con una implementación ágil para la industria regional.</p>
          <p>Para mayor información o para contratar nuestros servicios llámenos al 67-2215555</p>
          <h2>Descarga de Formularios</h2>
          <div className='form-link'>
            <a href='https://aguas-patagonia-public-files.s3-sa-east-1.amazonaws.com/solicitud_factibilidad.pdf' target="_blank" rel="noopener noreferrer">Solicitud de Factibilidad</a>
          </div>
          <div className="form-link">
            <a href="https://aguas-patagonia-public-files.s3-sa-east-1.amazonaws.com/aviso_iniciacion.pdf" target="_blank" rel="noopener noreferrer">Aviso de Iniciación</a>
          </div>
          <div className="form-link">
            <a href="https://aguas-patagonia-public-files.s3-sa-east-1.amazonaws.com/solicitud_recepcion.pdf" target="_blank" rel="noopener noreferrer">Solicitud de Recepción</a>
          </div>
          <div className="form-link">
            <a href="https://aguas-patagonia-public-files.s3-sa-east-1.amazonaws.com/actualizacion_antecedentes.pdf" target="_blank" rel="noopener noreferrer">Actualización de Antecedentes</a>
          </div>
          <div className="form-link">
            <a href="https://aguas-patagonia-public-files.s3-sa-east-1.amazonaws.com/faq.pdf" target="_blank" rel="noopener noreferrer">Preguntas frecuentes e instrucciones de llenado</a>
          </div>
          <div className="form-link">
            <a href="https://aguas-patagonia-public-files.s3-sa-east-1.amazonaws.com/fomulario_motorhome.pdf">Punto de descarga para casas rodantes Motorhome</a>
          </div>
        </div>
      </div>
    </Page >
  )
}

export default Forms