import React from 'react'
import Page from '../../components/Layout/Page'
import './Rate.css';

function Rate() {
  return (
    <Page
      title="Aguas Patagonia | Tarifas"
      current='clients'
    >
      <div className='rate-wrapper'>
        <div className='icon-container-rate'>
          <img src='/img/icon3.png' alt='icon'></img>
        </div>
        <h1>Tarifas Vigentes</h1>
        <div className='information-wrapper-rate'>
          <div className='image-container-rate'>
            <embed src='https://aguas-patagonia-public-files.s3.sa-east-1.amazonaws.com/Tarifas_Vigentes_092024.pdf' alt='tarifas'></embed>
          </div>
          <a href="https://aguas-patagonia-public-files.s3.sa-east-1.amazonaws.com/Tarifas_Vigentes_092024.pdf" target="_blank" rel="noopener noreferrer">Descargue el documento aquí</a>
          <p>Tarifas Vigentes 2022</p>
        </div>
        <h1>Tarifas No Monopólicas</h1>
        <div className='information-wrapper-rate'>
          <a href="https://aguas-patagonia-public-files.s3.sa-east-1.amazonaws.com/Tarifas+No+Reguladas+2023_12.pdf" target="_blank" rel="noopener noreferrer">Descargue el documento aquí</a>
          <p>Tarifas No Monopólicas 2023</p>
        </div>
      </div>
    </Page>

  )
}

export default Rate