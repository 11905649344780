import React from 'react'
import './Footer.css'

function Footer() {
  return (
    <div className="footer-wrapper">
      <hr />
      <div className="footer-container">
        <div className="footer-column logo-container">
          <a href="/"><img src="/img/logo-aguas.png" alt="logo" /></a>
        </div>
        <div className="footer-column">
          <div>
            <a href="/nosotros">
              <h1>Nuestra Empresa</h1>
            </a>
            <div className="sub-division-footer-column">
              <h2>Nosotros</h2>
              <a href="/nosotros#quienes-somos"><h3>Quiénes Somos</h3></a>
              <a href="/nosotros#mision-vision"><h3>Misión y Visión</h3></a>
            </div>
            <div className="sub-division-footer-column">
              <h2>Equipo</h2>
              <a href="/nosotros#directorio"><h3>Directorio</h3></a>
              <a href="/nosotros#organigrama"><h3>Organigrama</h3></a>
            </div>
          </div>
        </div>
        <div className="footer-column">
          <div>
            <div style={{height: '47px'}}></div>
            <div className="sub-division-footer-column">
              <h2>Documentos</h2>
              <a href="/nosotros#memorias-estados-financieros"><h3>Memorias</h3></a>
              <a href="/nosotros#memorias-estados-financieros"><h3>Estados Financieros</h3></a>
            </div>
            <div className="sub-division-footer-column">
              <h2>Otros</h2>
              <a href="https://www.siss.gob.cl/586/w3-propertyvalue-6432.html" target="_blank" rel="noopener noreferrer"><h3>Marco Regulatorio</h3></a>
              <a href="/areas-de-concesion"><h3>Áreas de Concesión</h3></a>
              <a href="/trabaje-con-nosotros"><h3>Trabaje con Nosotros</h3></a>
              <a href="/obras-y-proyectos"><h3>Obras y Proyectos</h3></a>
            </div>
          </div>
        </div>
        <div className="footer-column">
          <div>
            <a href="/clientes"><h1>Clientes</h1></a>
            <div>
              <a href="/video"><h3>Video Corporativo</h3></a>
              <a href="/comunicados"><h3>Comunicados y Noticias</h3></a>
              <a href="/cortes"><h3>Cortes Programados</h3></a>
              <a href="/lugares-y-medios-de-pago"><h3>Lugares y Medios de Pago</h3></a>
              <a href="/venta-de-agua-y-servicios"><h3>Venta de Agua y Servicios</h3></a>
            </div>
            <div className="second-row-footer-column">
              <a href="/subsidios"><h2>Subsidios</h2></a>
              <a href="/formularios"><h2>Formularios</h2></a>
              <a href="/tarifas"><h2>Tarifas</h2></a>
              <a href="/apr"><h2>APR</h2></a>
              <a href="/preguntas-frecuentes"><h2>Preguntas Frecuentes</h2></a>
            </div>
          </div>
        </div>
        <div className="footer-column">
          <div>
            <a href="/sostenibilidad"><h1>Sostenibilidad</h1></a>
            <div>
              <a href="/sostenibilidad#plan-de-sostenibilidad"><h3>Plan de Sostenibilidad</h3></a>
              <a href="/sostenibilidad#medioambiente"><h3>Medioambiente</h3></a>
              <a href="/sostenibilidad#informacion-educativa"><h3>Información Educativa</h3></a>
            </div>
            <div className="second-row-footer-column">
              <a href="/pago"><h2>Paga tu Cuenta</h2></a>
              <a href="/oficinas"><h2>Oficinas y Horarios</h2></a>
              <a href="/boleta"><h2>Mi Boleta</h2></a>
            </div>
          </div>
        </div>
        <div className="footer-column">
          <div>
            <h1>Contacto</h1>
            <div>
              <a href="tel://+56672215555"><h2>+ 56 672 215 555</h2></a>
              <a href="mailto:contacto@aguaspatagonia.cl"><h2>contacto@aguaspatagonia.cl</h2></a>
            </div>
            <div className="second-row-footer-column">
              <h1>Redes Sociales</h1>
              <div className="footer-social-media-icons">
                <a href="https://www.instagram.com/aguaspatagonia" target="_blank" rel="noopener noreferrer"><img src="/img/instagram.png" alt="instagram" /></a>
                <a href="https://twitter.com/AguasPatagonia" target="_blank" rel="noopener noreferrer"><img src="/img/twitter.png" alt="twitter" /></a>
                <a href="https://www.facebook.com/AguasPatagonia" target="_blank" rel="noopener noreferrer"><img src="/img/facebook.png" alt="facebook" /></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer
