import React, { useState } from 'react'
import Page from '../../components/Layout/Page'
import './Piping.css'

function Piping() {
  const [currentImage, setCurrentImage] = useState(0);
  const images = [
    "img/06.jpg",
    "img/05.jpg",
  ];

  const nextImage = () => {
    setCurrentImage((currentImage + 1) % images.length);
  };

  const prevImage = () => {
    setCurrentImage((currentImage - 1 + images.length) % images.length);
  };

  return (
    <Page current="piping">
      <div className='center-container'>
        <div className='center-container-column'>
          <div className="piping-header">
            <div className="piping-image-container">
              <img src="img/03.jpg" alt="Invierno" />
            </div>
            <div className="piping-text-container-left">
              <h1>Aisla las tuberías y llaves</h1>
              <p>Las tuberías expuestas al frío extremo corren el riesgo de congelarse, lo que puede causar obstrucciones y rupturas. Aísla las tuberías con materiales aislantes como medias cañas de plumavit, material poliestireno o cintas térmicas y, si sospechas que alguna se ha congelado, no utilices fuego directo para descongelarla, y NUNCA agua caliente. En su lugar, utiliza métodos seguros con anticipación y así evitaras interrupciones en el suministro.</p>
              <b>Recuerda que las tuberías expuestas son más propensas a congelarse. Recuerda, mientras más aislamiento utilices, más protegidas estarán sus tuberías.</b>
            </div>
          </div>
          <div className="piping-header">
            <div className="piping-text-container-right">
              <h1>Filtración interior</h1>
              <p>Revisa tus instalaciones y procura que no exista ninguna filtración y/o goteo.</p>
              <b>¿Por qué ?</b>
              <p>Esto disminuye las probabilidades que tus cañerías tanto interiores como exteriores revienten con el frío.</p>
            </div>
            <div className="piping-image-container">
              <img src="img/05.jpg" alt="Invierno" />
            </div>
          </div>
          <div className="piping-header">
            <div className="piping-image-container">
              <img src="img/09.jpg" alt="Invierno" />
            </div>
            <div className="piping-text-container-left">
              <h1>Llaves del jardín </h1>
              <p>Frente las intensas lluvias, o escarcha te recomendamos desconectar la manguera de tu llave de Jardín</p>
              <b>¿Por qué ?</b>
              <p>Esto disminuye las probabilidades que tus cañerías tanto interiores como exteriores revienten con el frío.</p>
              <p>También evitarás congelamiento desde el lugar donde proviene la grieta de la tubería, codos, etc.</p>
              <p><b>Así estamos dándole un adecuado uso a nuestra llave de jardín dado que en invierno no necesitamos de esta instalación por precipitaciones.</b></p>
            </div>
          </div>
          <div className="measurement-header">
            <div className="measurement-text-container-center">
              <p>En Aguas Patagonia, nos preocupamos por tu bienestar y queremos asegurarnos de que cuentes con un suministro confiable de agua potable durante todo el año, incluso en las condiciones invernales más desafiantes. Nuestra Campaña de Invierno tiene como objetivo recordarte la importancia de tomar medidas preventivas para proteger el recurso más valioso: el agua. Te animamos a seguir nuestras recomendaciones y compartir esta información con tus seres queridos. Juntos, podemos cuidar el agua y garantizar su disponibilidad para las generaciones futuras.</p>
              <p>¡Cuenta con nosotros en esta temporada invernal!</p>
            </div>
          </div>
          <div className="winter-gallery">
            <div className="winter-carousel">
              <button className="winter-carousel-button previous-button" onClick={prevImage}>&lt;</button>
              <img src={images[currentImage]} alt={`Imagen ${currentImage + 1}`} />
              <button className="winter-carousel-button next-button" onClick={nextImage}>&gt;</button>
            </div>
          </div>
        </div>
      </div>
    </Page>
  );
}

export default Piping;
