import React from 'react'
import Page from '../../components/Layout/Page'
import './APR.css';

function APR() {
  return (
    <Page
      title="Aguas Patagonia | Agua Potable Rural"
      current='clients'
    >
      <div className="page-container-wrapper">
        <div className='icon-container-dev'>
          <img src='/img/icon5.png' alt='icon'></img>
        </div>
        <div className='text-wrapper-apr'>
          <h1>Agua Potable Rural</h1>
          <div className='information-wrapper'>
            <div className='image-container'>
              <embed src='https://aguas-patagonia-public-files.s3-sa-east-1.amazonaws.com/apr.pdf' alt='APR'></embed>
            </div>
          </div>
          <a href="https://aguas-patagonia-public-files.s3-sa-east-1.amazonaws.com/apr.pdf" rel="noopener noreferrer">Descargue el documento aquí</a>
        </div>
      </div>
    </Page >
  )
}

export default APR