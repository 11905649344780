import React from 'react'
import Page from '../../components/Layout/Page'
import './Sustainability.css'

function Sustainability() {
  return (
    <Page current="sustainability">
      <div id="plan-de-sostenibilidad" className="sustainability-title">
        <h1>Plan de Sostenibilidad</h1>
        <p>Aguas Patagonia es una empresa comprometida con el desarrollo de la región de Aysén. Para ello capta, produce y distribuye agua potable y recolecta y trata aguas servidas poniendo el acento en la calidad de servicio y el compromiso con la comunidad y el medioambiente. En este contexto, realiza programas de inversión social y valor compartido basados en los valores propios de las comunidades locales con el fin de potenciarlos.</p>
      </div>
      <div id="medioambiente" className="politica-medio-ambiental">
        <div className="politica-img">
          <img src="/img/bridge.jpg" alt="medioambiente" style={{borderTopRightRadius: '20px', borderBottomRightRadius: '20px'}} />
        </div>
        <div className="politica-icon">
          <img src="/img/icon-medioambiente.png" alt="icono-medioambiente"/>
        </div>
        <div className="politica-box">
          <div className="politica-box-content">
            <h2>Medioambiente</h2>
            <p>El trabajo de Aguas Patagonia va de la mano con el cuidado del privilegiado entorno natural de la Región de Aysén. Su mirada incluye crear conciencia acerca del ciclo del agua. Pero también respecto de la calidad del aire y la gestión de residuos.</p>
            <p>En esta línea se ha desarrollado un programa de reutilización de lodos, que permite recuperar suelos degradados. También se toma parte de la Mesa de Educación para el Desarrollo Sustentable de Aysén. Además se trabaja con el Ministerio de Medio Ambiente en un concurso sobre aire limpio.</p>
          </div>
        </div>
      </div>
      <div className="turismo">
        <div className="turismo-img">
          <img src="/img/boat.jpg" alt="turismo" />
        </div>
        <div className="turismo-icon">
          <img src="/img/icon-turismo.png" alt="icono-turismo"/>
        </div>
        <div className="turismo-box">
          <div className="turismo-box-content">
            <h2>Turismo</h2>
            <p>El turismo es fundamental para la Región de Aysén y Aguas Patagonia apalanca aquellas acciones que tengan que ver con la sostenibilidad de esta actividad, especialmente en lo que tiene que ver con el consumo responsable del agua y el cuidado de los ríos.</p>
            <p>Está en desarrollo un proyecto para proveer de estaciones de descarga de aguas servidas a casas rodantes. Se ha apoyado asimismo el manejo sanitario de emprendimientos locales ante la pandemia de COVID-19.</p>
          </div>
        </div>
      </div>
      <div className="emprendimiento">
        <div className="emprendimiento-img">
          <img src="/img/emprendimiento.jpg" alt="emprendimiento" style={{borderTopRightRadius: '20px', borderBottomRightRadius: '20px'}} />
        </div>
        <div className="emprendimiento-icon">
          <img src="/img/icon-emprendimiento.png" alt="icono-emprendimiento" />
        </div>
        <div className="emprendimiento-box">
          <div className="emprendimiento-box-content">
            <h2>Emprendimiento</h2>
            <p>De la mano del turismo, existen en la región múltiples actividades productivas y servicios locales que apoyamos en su desarrollo, por ejemplo, a través de actividades de capacitación. Nuestra empresa trabaja además con 290 proveedores, todos de la región.</p>
          </div>
        </div>
      </div>
      <div id="informacion-educativa" className="educacion">
        <div className="educacion-img">
          <img src="/img/deporte.jpg" alt="educacion" />
        </div>
        <div className="educacion-icon">
          <img src="/img/icon-educacion.png" alt="icono-educacion"/>
        </div>
        <div className="educacion-box">
          <div className="educacion-box-content">
            <h2>Educación y Deportes</h2>
            <p>En línea con la preocupación por el entorno natural, el cuidado del agua, el turismo sustentable y los valores locales, se trabaja con colegios e instituciones académicas, municipalidades y organizaciones en el fomento del deporte y la educación ambiental.</p>
            <p>Se realizan charlas y seminarios en colegios y universidades, concurso anual de fotografía de ríos y lagos, programa de prácticas laborales con Inacap.</p>
          </div>
        </div>
      </div>
      <div className="inclusion">
        <div className="inclusion-img">
          <img src="/img/inclusion.jpg" alt="inclusion" style={{borderRadius: '20px'}} />
        </div>
        <div className="inclusion-icon">
          <img src="/img/icon-inclusion.png" alt="icono-inclusion"/>
        </div>
        <div className="inclusion-box">
          <div className="inclusion-box-content">
            <h2>Inclusión</h2>
            <p>Como parte de nuestro compromiso con la comunidad, Aguas Patagonia colabora con comunidades y grupos vulnerables, tales como organizaciones de mujeres, de adultos mayores y personas con distintos tipos de discapacidad. Hemos suscrito convenios y colaboramos con organizaciones como el Centro de Rehabilitación del Club de Leones.</p>
          </div>
        </div>
      </div>
    </Page>
  )
}

export default Sustainability