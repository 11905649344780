import React from 'react'
import Page from '../../components/Layout/Page'
import './Customers.css'

function Customers() {
  return (
    <Page current="clients">
      <div className="customers-container">
        <div className="customers-title-container">
          <h1>Clientes</h1>
          <p>Ingresa a uno de los siguientes links para acceder a toda la información para nuestros clientes</p>
        </div>
        <div className="customer-boxes-container">
          <div className="customer-boxes-row">
            <div className="customer-box communications-customer-box">
              <h2>Comunicados y Noticias</h2>
              <p>Ingresa para ver los últimos comunicados de Aguas Patagonia</p>
              <button
                type="button"
                onClick={() => { window.location.href = '/comunicados'; }}
                className="blue-button"
              >
                Ingresar
              </button>
              <img className="light-blue-circle-communications" src="/img/light-blue-circle.png" alt="light-blue-circle" />
              <img className="blue-circle-communications" src="/img/blue-circle.png" alt="blue-circle" />
              <img src="/img/doc-icon.png" alt="doc" />
            </div>
            <div className="customer-box shortage-customer-box">
              <h2>Cortes Programados</h2>
              <p>Revisa en detalle los cortes de emergencia que puedan afectar a tu hogar o lugar de trabajo</p>
              <button
                type="button"
                onClick={() => { window.location.href = '/cortes'; }}
                className="blue-button"
              >
                Más información
              </button>
              <img className="light-blue-circle-shortage" src="/img/light-blue-circle.png" alt="light-blue-circle" />
              <img src="/img/drops-icon.png" alt="drops" />
            </div>
          </div>
          <div className="customer-boxes-row">
            <div className="customer-box payments-customer-box">
              <div className="places-payments">
                <h2>Lugares y Medios de Pago</h2>
                <div className="links-customer-box">
                  <a href="https://ww3.servipag.com/pagoenlinea/portal/desktop/public/todas/!ut/p/b1/04_Sj9CPykssy0xPLMnMz0vMAfGjzOKNgs0CLZ0MHQ38zQPcDBx9gs1CzAK9Df0DTYAKIvEo8DanTL-HEXH6DXAARwNC-r30o9Jz8pOAXg3Xj8KrGOQXvApAjgUrwOMaP4_83FT9gtzQ0IjKYE9dR0VFADNEynw!/dl4/d5/L2dBISEvZ0FBIS9nQSEh/?idServicio=1&idBiller=58" target="_blank" rel="noopener noreferrer">Servipag</a>
                  <a href="https://www.sencillito.com/pago">Sencillito</a>
                  <a href="https://www.bancoestado.cl/">Banco Estado</a>
                  <a href="https://www.unired.cl/">Unired</a>
                </div>
              </div>
              <img className="blue-circle-payments" src="/img/blue-circle.png" alt="blue-circle" />
              <img src="/img/money-icon.png" alt="money" />
            </div>
            <div className="customer-box sales-customer-box">
              <h2>Venta de Agua y Servicios</h2>
              <div className="links-customer-box">
                {/* TODO */}
                <a href="/">Venta de agua a Granel</a>
                <a href="/">Descarga limpiafosas</a>
              </div>
              <img className="calypso-circle-sales" src="/img/calypso-circle.png" alt="calypso-circle" />
              <img src="/img/truck-icon.png" alt="truck" />
            </div>
          </div>
        </div>
      </div>
    </Page>
  )
}

export default Customers
