import React from 'react';
import Page from '../../components/Layout/Page';
import './Factibilidad.css';


const Factibilidad = () => (
  <Page
      title="Aguas Patagonia | Solicitud de factibilidad"
      current='clients'
    >
    <div className="factibilidad-wrapper">
      <div className="factibilidad">
        <h1>Solicitud de Factibilidad</h1>
        <p>
          Para tramitar la factibilidad de Agua Potable por favor descargue y complete los siguientes documentos:
        </p>
        <div>
          <a href="https://aguas-patagonia-public-files.s3.sa-east-1.amazonaws.com/Solicitud_factibilidad_Formulario.pdf" target="_blank" rel="noopener noreferrer">Descargue la solicitud de factibilidad aquí (pdf)</a>
        </div>
        <div>
          <a href="https://aguas-patagonia-public-files.s3.sa-east-1.amazonaws.com/Solicitud_Factibilidad_Propietario.xlsm" target="_blank" rel="noopener noreferrer">Descargue la solicitud de factibilidad aquí (excel)</a>
        </div>
        <p>
          Por favor completar ambos documentos, enviar a <a href="mailto:proyectos@aguaspatagonia.cl">proyectos@aguaspatagonia.cl</a> adjuntando la siguiente documentación:
        </p>
        <ul>
          <li>Solicitud Completa con firmas originales de proyectista y propietario en formato PDF, además enviar en formato Excel (sin firmas)</li>
          <li>Fotocopia carnet de propietario (formato pdf)</li>
          <li>Fotocopia de escritura de inmueble (formato pdf)</li>
          <li>Boleta de agua (Si es servicio existente)</li>
          <li>Ubicación google maps (formato pdf, demarcado con un rectángulo el inmueble e indicando el Norte) ( de igual manera Deberá incorporarlo en el apartado de la solicitud)</li>
          <li>Certificado de Título Proyectista</li>
        </ul>
      </div>
    </div>
  </Page>
);

export default Factibilidad;
