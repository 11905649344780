/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { Link } from 'react-router-dom'
import { HashLink } from 'react-router-hash-link'

function NavBarDesktop({ current }) {
  return (
    <>
      <div className="navbar-top">
        <div className='phone-wrapper'>
          <img src='/img/phone.png' alt='phone' />
          <p>Fono Clientes: <a href="tel://+56672215555">+56 672 215 555</a></p>
        </div>
        <div className="navbar-top-tab">
          <button
            type="button"
            onClick={() => { window.location.href = '/'; }}
            className={current === 'home' ? 'blue-button' : 'transparent-button'}
          >
            Inicio
          </button>
        </div>
        <div className="navbar-top-tab">
          <div className="nav-drop-container">
            <button
              type="button"
              onClick={() => { window.location.href = '/nosotros'; }}
              className='transparent-button'
            >
              {current === 'company' ?
                <div className="blue-button">Nuestra Empresa</div>
              : 'Nuestra Empresa'}
            </button>
            <div className="nav-drop-content">
              <div className="nav-drop-wrapper">
                <div className="nav-second-drop-container arrow">
                  <Link href={'/servicios#'}>
                    <a>Nosotros</a>
                    <div className="nav-second-drop-content">
                      <div>
                        <HashLink to="/nosotros#quienes-somos">
                          <a>Quiénes Somos</a>
                        </HashLink>
                        <HashLink to="/nosotros#mision-vision">
                          <a>Misión y Visión</a>
                        </HashLink>
                        {/* <HashLink to="/nosotros#historia">
                          <a>Historia</a>
                        </HashLink> */}
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="nav-second-drop-container arrow">
                  <Link href={'/servicios#'}>
                    <a>Equipo</a>
                    <div className="nav-second-drop-content">
                      <div>
                        <HashLink to="/nosotros#directorio">
                          <a>Directorio</a>
                        </HashLink>
                        <HashLink to="/nosotros#organigrama">
                          <a>Organigrama</a>
                        </HashLink>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="nav-second-drop-container arrow">
                  <Link href={'/servicios#'}>
                    <a>Documentos</a>
                    <div className="nav-second-drop-content">
                      <div>
                        <HashLink to="/nosotros#memorias-estados-financieros">
                          <a>Memorias</a>
                        </HashLink>
                        <HashLink to="/nosotros#memorias-estados-financieros">
                          <a>Estados Financieros</a>
                        </HashLink>
                        <HashLink to="/nosotros#plan-de-desarrollo">
                          <a>Plan de Desarrollo</a>
                        </HashLink>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="nav-second-drop-container arrow">
                  <Link href={'/servicios#'}>
                    <a>Otros</a>
                    <div className="nav-second-drop-content">
                      <div>
                        <Link to="/marco-regulatorio">
                          <a>Marco Regulatorio</a>
                        </Link>
                        <Link to="/areas-de-concesion">
                          <a>Áreas de Concesión</a>
                        </Link>
                        <Link to="/trabaje-con-nosotros">
                          <a>Trabaje con Nosotros</a>
                        </Link>
                        <Link to="/obras-y-proyectos">
                          <a>Obras y Proyectos</a>
                        </Link>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="navbar-top-tab">
          <div className="nav-drop-container">
            <button
              type="button"
              onClick={() => { window.location.href = '/clientes'; }}
              className='transparent-button'
            >
              {current === 'clients' ?
                <div className="blue-button">Clientes</div>
              : 'Clientes'}
            </button>
            <div className="nav-drop-content">
              <div className="nav-drop-wrapper">
                <div className="nav-second-drop-container arrow">
                  <Link href={'/servicios#'}>
                    <a>Información Clientes</a>
                    <div className="nav-second-drop-content">
                      <div>
                        <Link to="/video">
                          <a>Video Corporativo</a>
                        </Link>
                        <Link to="/comunicados">
                          <a>Comunicados y Noticias</a>
                        </Link>
                        <Link to="/cortes">
                          <a>Cortes Programados</a>
                        </Link>
                        <Link to="/lugares-y-medios-de-pago">
                          <a>Lugares y Medios de Pago</a>
                        </Link>
                        <Link to="/venta-de-agua-y-servicios">
                          <a>Venta de Agua y Servicios</a>
                        </Link>
                      </div>
                    </div>
                  </Link>
                </div>
                <Link to="/subsidios">
                  <a>Subsidios</a>
                </Link>
                <Link to="/formularios">
                  <a>Formularios</a>
                </Link>
                <Link to="/tarifas">
                  <a>Tarifas</a>
                </Link>
                <Link to="/apr">
                  <a>APR</a>
                </Link>
                <Link to="/preguntas-frecuentes">
                  <a>Preguntas Frecuentes</a>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="navbar-top-tab">
          <div className="nav-drop-container">
            <button
              type="button"
              onClick={() => { window.location.href = '/ilidad'; }}
              className='transparent-button'
            >
              {current === 'sustainability' ?
                <div className="blue-button">Sostenibilidad</div>
              : 'Sostenibilidad'}
            </button>
            <div className="nav-drop-content">
              <div className="nav-drop-wrapper">
                <HashLink to="/sostenibilidad#plan-de-sostenibilidad">
                  <a>Plan de Sostenibilidad</a>
                </HashLink>
                <HashLink to="/sostenibilidad#medioambiente">
                  <a>Medioambiente</a>
                </HashLink>
                <HashLink to="/educacion">
                  <a>Información Educativa</a>
                </HashLink>
                <div className="nav-second-drop-container arrow">
                  <Link to={'/invierno'}>
                    <a>Invierno</a>
                    <div className="nav-second-drop-content">
                      <div>
                        <Link to="/medidor">
                          <a>Medidor</a>
                        </Link>
                        <Link to="/tuberias">
                          <a>Tuberías</a>
                        </Link>
                      </div>
                    </div>
                  </Link>
                </div>
                {/* <a href="https://aguas-patagonia-public-files.s3-sa-east-1.amazonaws.com/Concurso-de-dibujo-Dia-mundial-del-Agua.pdf" target="_blank" rel="noopener noreferrer"><a>Día del Agua 2021</a></a> */}
              </div>
            </div>
          </div>
        </div>
        <div className="navbar-top-tab">
        <button
            type="button"
            onClick={() => { window.location.href = '/compliance'; }}
            className={current === 'Compliance' ? 'blue-button' : 'transparent-button'}
          >
            Compliance
          </button>
        </div>
        <div className="navbar-top-tab">
          <button
            type="button"
            onClick={() => { window.location.href = '/contacto'; }}
            className={current === 'contact' ? 'blue-button' : 'transparent-button'}
          >
            Contacto
          </button>
        </div>
        <div className="social-media-icons">
          <a href="https://www.instagram.com/aguaspatagonia" target="_blank" rel="noopener noreferrer"><img src="/img/instagram.png" alt="instagram" /></a>
          <a href="https://twitter.com/AguasPatagonia" target="_blank" rel="noopener noreferrer"><img src="/img/twitter.png" alt="twitter" /></a>
          <a href="https://www.facebook.com/AguasPatagonia" target="_blank" rel="noopener noreferrer"><img src="/img/facebook.png" alt="facebook" /></a>
        </div>
      </div>
      <div className='navbar-bottom'>
        <div className="navbar-bottom-first">
          <div className="navbar-bottom-logo">
            <Link to="/"><img src="/img/logo-aguas.png" alt="logo" /></Link>
          </div>
          <div className="main-button payment-nav-button">
            <button
              type="button"
              onClick={() => { window.location.href = '/pago'; }}
              className="blue-button"
            >
              PAGA AQUÍ
            </button>
          </div>
          <div className="main-button offices-nav-button">
            <button
              type="button"
              onClick={() => { window.location.href = '/oficinas'; }}
              className="blue-button"
            >
              OFICINAS Y HORARIOS
            </button>
          </div>
          <div className="main-button my-bill-nav-button">
            <button
              type="button"
              onClick={() => { window.location.href = '/boleta'; }}
              className="blue-button"
            >
              MI BOLETA
            </button>
          </div>
        </div>
        {/* {current === 'home' ? <div className="navbar-bottom-second">
          <p>Infórmate del beneficio Covid-19 Ley 21.304, prórroga de Ley 21.249 haciendo <a href="/beneficios-covid-19">Click Aquí</a> Revisa Ley 21.423 de Subsidio a los servicios básicos haciendo <a href="/ley-covid-21423">Click Aquí</a></p>
        </div> : null} */}
      </div>
    </>
  )
}

export default NavBarDesktop