import React from 'react'
import Page from '../../components/Layout/Page'
import './Subsidios.css';

function Subsidios() {
  return (
    <Page
      title="Aguas Patagonia | Subsidios"
      current='clients'
    >
      <div className="page-container-dev-subs">
        <div className='icon-container-dev'>
          <img src='/img/icon5.png' alt='icon'></img>
        </div>
        <div className='text-wrapper-subs'>
          <h1>Subsidios</h1>
          <p>El subsidio al Agua Potable es un beneficio dirigido a familias o personas que por su realidad socio económica se vean en la imposibilidad de cancelar el monto total del valor de las prestaciones por consumo de agua potable y/o servicio de alcantarillado de aguas servidas. Este subsidio es aplicable a los cargos fijos y variables correspondientes a la vivienda. Tanto para el área urbana como rural. Consiste en el financiamiento que hace el Gobierno a una parte o porcentaje del pago mensual al consumo de agua potable este porcentaje solo cubre hasta un consumo máximo de 15 M3.</p>
          <h2>¿Cómo y Dónde Postular?</h2>
          <p>Este beneficio debe ser solicitado en el Departamento Social de cada Municipalidad.</p>
          <p>Requisitos para Postular:</p>
          <ul>
            <li>Presentando la última boleta cancela y sin deuda</li>
            <li>Jefe de Familia debe estar inscrito con la Ficha de Protección Social</li>
            <li>Tener una facturación mayor o igual al 3% de su ingreso mensual</li>
          </ul>
          <h2>¿Cómo opera el subsidio y su asignación?</h2>
          <ul>
            <li>Se establece el subsidio al pago de agua potable y servicio de alcantarillado de aguas servidas, que favorecerá a usuarios residenciales de escaso recursos.</li>
            <li>El subsidio será aplicable a los cargos fijos y variables correspondiente a la vivienda que habiten en forma permanente sus beneficiarios.</li>
            <li>El beneficio es otorgado por los alcaldes en un decreto ley emitido una vez cada mes, donde se detallará todas las personas beneficiadas, el porcentaje de beneficio y los datos necesarios del servicio del beneficiario al cual será otorgado.</li>
            <li>El subsidio se devengará a contar del primer día del mes siguiente a aquel en que haya sido dictada la resolución que otorga el beneficio y su pago se efectuara por la municipalidad respectiva al prestador.</li>
          </ul>
          <h2>Causas por la que se extinguirá el subsidio</h2>
          <ol>
            <li>La acumulación de 3 cuentas consecutivas insolutas.</li>
            <li>Vencimiento de plazo legal (3 años).</li>
            <li>Por cambio de domicilio fuera de la comuna.</li>
            <li>Por renuncia voluntaria.</li>
          </ol>
          <h2>Cómo opera la causal de extinción</h2>
          <ol>
            <li>La municipalidad dictará un decreto mensual, dentro de los 10 primeros días del mes, en el cual se incluirá la totalidad de los beneficiarios a los cuales se les extinga el beneficio.</li>
            <li>El prestado o administrador una vez informado de la extinción del beneficio, procederá a cobrar el monto total del consumo y cargo fijo directamente a los usuarios, sin considerar subsidio de ningún tipo para la vivienda.</li>
          </ol>
          <p>Al 31 de diciembre de 2009, los subsidios aplicados se distribuyeron como sigue:</p>
          <table cellpadding="10" border="1" cellspacing="0">
            <thead>
              <tr>
                <th>Servicio</th>
                <th>Nº Subsidios</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Coyhaique y Balmaceda</td>
                <td>5.844</td>
              </tr>
              <tr>
                <td>Puerto Aysén y Puerto Chacabuco</td>
                <td>2.284</td>
              </tr>
              <tr>
                <td>Chile Chico</td>
                <td>336</td>
              </tr>
              <tr>
                <td>Cochrane</td>
                <td>452</td>
              </tr>
              <tr>
                <td>Puerto Cisnes</td>
                <td>408</td>
              </tr>
              <tr>
                <td>Puerto Ibáñez</td>
                <td>316</td>
              </tr>
              <tr>
                <td><strong>Total</strong></td>
                <td><strong>9.640</strong></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </Page>
  )
}

export default Subsidios