import React, {
    forwardRef,
} from 'react';
import { Helmet } from 'react-helmet';
import NavBar from './NavBar';
import Footer from './Footer';

const Page = forwardRef(({
    title,
    children,
    current,
    meta,
}, ref) => {

    return (
        <div
            ref={ref}
            className="page-container"
        >
            <Helmet
              title={title}
              meta={meta}
            />
            <NavBar current={current} />
            <div className="page-sub-container">
                {children}
            </div>
            <Footer />
        </div>
    );
});

export default Page;
