export const UPDATE_CART = '@cart/update'
export const ADD_ONE_TO = '@cart/add-one'
export const SUBTRACT_ONE_FROM = '@cart/subtract-one'
export const REMOVE_PRODUCT = '@cart/remove-product'
export const UPDATE_SHIPPING = '@cart/update-shipping'
export const RESET_CART = '@cart/reset-cart'

export function resetCart() {
  return (dispatch) =>
    dispatch({
      type: RESET_CART,
    })
}

export function updateCart(cart) {
  return (dispatch) =>
    dispatch({
      type: UPDATE_CART,
      payload: {
        cart,
      },
    })
}

export function updateShipping(shipping) {
  return (dispatch) =>
    dispatch({
      type: UPDATE_SHIPPING,
      payload: {
        shipping,
      },
    })
}

export function addOneTo(productId) {
  return (dispatch) =>
    dispatch({
      type: ADD_ONE_TO,
      payload: {
        productId,
      },
    })
}

export function subtractOneFrom(productId) {
  return (dispatch) =>
    dispatch({
      type: SUBTRACT_ONE_FROM,
      payload: {
        productId,
      },
    })
}

export function removeFromCart(productId) {
  return (dispatch) =>
    dispatch({
      type: REMOVE_PRODUCT,
      payload: {
        productId,
      },
    })
}
