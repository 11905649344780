import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import moment from 'moment'
import thousandSeparator from '../../utils/thousandSeparator'
import axios from '../../utils/axios'
import './TbkFinal.css'
import Page from '../../components/Layout/Page'

function TbkFinal() {
  const [tbkPayment, setTbkPayment] = useState(null)
  const [bill, setBill] = useState(null)
  const { tbkPaymentId, billId } = useParams()

  useEffect(() => {
    const getTbkPayment = async () => {
      if (tbkPaymentId) {
        const tbkPaymentResponse = await axios.get(
          `/api/tbk-payments/${tbkPaymentId}`
        )
        setTbkPayment(tbkPaymentResponse.data.data.tbkPayment)
      }
    }

    const getBill = async () => {
      if (billId) {
        const billResponse = await axios.get(`/api/bills/${billId}`)
        setBill(billResponse.data.data.bill)
      }
    }

    getTbkPayment()
    getBill()
  }, [tbkPaymentId, billId])

  const parsePaymentTypeCode = (code) => {
    switch (code) {
      case 'VN':
        return 'Crédito';

      case 'VD':
        return 'Débito';

      case 'VC':
        return 'Crédito (En cuotas)';
      
      case 'SI':
        return 'Crédito (3 cuotas sin interés)';

      case 'S2':
        return 'Crédito (2 cuotas sin interés)';

      case 'NC':
        return 'Crédito (cuotas sin interés)';

      default:
        return 'Crédito';
    }
  };

  const paymentMethod = () => {
    switch (bill.payment_method) {
      default:
        return 'Webpay Plus'
      case 'servipag':
        return 'Servipag'
    }
  }
  if(!bill){
    return <Page></Page>
  }
  return (
      <Page>
        <div className="container">
          <div className='icon-container-payment'>
            <img src='/img/icon1.png' alt='icon'></img>
          </div>
          <div className="info-title">
            <div className="info-title-img">
              <div className="info-check">
                <img src="/img/checked.png" alt="success"/>
              </div>
              <h1>Aguas Patagonia:</h1>
            </div>
            <h3>¡Tu cuenta está pagada!</h3>
          </div>
          <div className="info">
            <div className="info-total">
              <p style={{ 'font-weight': 600 }}>
                Monto: $ {thousandSeparator(bill.price, '.')}
              </p>
            </div>
            <div className="info-payment">
              {tbkPayment && (
                <>
                  <h3>Detalle de la transacción:</h3>
                  <p>Método de pago: {parsePaymentTypeCode(tbkPayment.payment_type_code)}</p>
                  <p>Monto: $ {thousandSeparator(bill.price, '.')}</p>
                  <p>Código de Autorización: {tbkPayment.authorization_code}</p>
                  <p>
                    Fecha:{' '}
                    {moment(tbkPayment.transaction_date).format(
                      'DD-MM-YYYY HH:mm:ss'
                    )}
                  </p>
                  <p>Tarjeta: **** **** **** {tbkPayment.card_number}</p>
                  {tbkPayment.installments_number > 0 && (
                    <p>{`Nº Cuotas: ${tbkPayment.installments_number}`}</p>
                  )}
                </>
              )}
            </div>
          </div>
          <button
            type="button"
            className="info-submit"
            onClick={() => {
              window.location.href = '/'
            }}
          >
            Volver
          </button>
        </div>
      </Page>
    )
}

export default TbkFinal
