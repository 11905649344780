import React from 'react'
import Page from '../../components/Layout/Page'
import './Areas.css';

function Areas() {
  const estado = 'Página en proceso de desarrollo.'

  return (
    <Page
      title="Aguas Patagonia | Áreas de Concesión"
      current='company'
    >
      <div className="page-container-areas">
        <div className='icon-container-dev'>
          <img src='/img/icon5.png' alt='icon'></img>
        </div>
        <div className='text-wrapper-areas'>
          <h1>Áreas de Concesión</h1>
          <div className="paragraph-wrapper">
            <p>
              {estado}
            </p>
          </div>
        </div>
      </div>
    </Page >
  )
}

export default Areas