import React, { useState } from 'react'
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';
import './DumpingPointsMap.css'

function DumpingPointsMap(props) {
  const [openDropdown, setOpenDropdown] = useState(false)
  const [currentDumpingPoint, setCurrentDumpingPoint] = useState({
    name: 'Coyhaique',
    coordinates: { lat: -45.556444, lng: -72.070778 }
  })

  const dumpingPoints = [
    {
      name: 'Coyhaique',
      coordinates: { lat: -45.556444, lng: -72.070778 }
    },
    {
      name: 'Cochrane',
      coordinates: { lat: -47.258111, lng: -72.573861 }
    },
    {
      name: 'Puerto Cisnes',
      coordinates: { lat: -44.72927446124712, lng: -72.68353405827794 }
    },
    {
      name: 'Chile Chico',
      coordinates: { lat: -46.53652622991895, lng: -71.73179801419501 }
    },
  ]

  const displayDumpingPoints = () => (
    <div className="dumpingPoints-list">
      <button
        type="button"
        className={
          openDropdown ? 'dumpingPoints-list-title active' : 'dumpingPoints-list-title'
        }
        onClick={() => setOpenDropdown(!openDropdown)}
      >
        Oficinas
      </button>
      <div
        className={
          openDropdown ? 'dumpingPoints-list-items open' : 'dumpingPoints-list-items'
        }
      >
        {dumpingPoints.map((of, i) => (
          <button
            type="button"
            key={of.name}
            className={
              (of.name === currentDumpingPoint.name ? 'selected-office ' : '') +
              (i === 0 ? 'dumpingPoints-first' : '')
            }
            onClick={() => setCurrentDumpingPoint(of)}
          >
            {of.name}
          </button>
        ))}
      </div>
    </div>
  )

  const mapStyles = {
    maxWidth: '500px',
    width: '300px',
    maxHeight: '500px',
  }

  return (
    <div className="dumpingPoints-map-container">
      <div className="map-address-wrapper">
        <div className="dumpingPoints-list-container">{displayDumpingPoints()}</div>
        <div className="info-and-map">
          <div className="map-display">
            <Map
              google={props.google}
              zoom={12}
              style={mapStyles}
              center={currentDumpingPoint.coordinates}
              initialCenter={currentDumpingPoint.coordinates}
            >
              <Marker position={currentDumpingPoint.coordinates} title={currentDumpingPoint.name} />
            </Map>
          </div>
        </div>
      </div>
    </div>
  )
}

export default GoogleApiWrapper({
  apiKey: 'AIzaSyD-HsQOnWTQ1iDLTyhgaahBxkzpbX9Vznc',
})(DumpingPointsMap)
