import React from 'react';
import Page from '../../components/Layout/Page';
import './HorariosFiestasPatrias.css';


const HorariosFiestasPatrias = () => (
  <Page
      title="Aguas Patagonia | Horarios de Atención Fiestas Patrias"
      current='clients'
    >
    <div className="horarios-wrapper">
      <div className="horarios">
        <h1>Horarios de Atención Comercial Fiestas Patrias</h1>
        <div className="horarios-img">
          <img src="/img/horarios-fiestas-patrias-2022.png" alt="Horarios Fiestas Patrias" />
        </div>
        <br />
        <h3>Oficina Coyhaique</h3>
        <p>Lunes 12 al miércoles 14 de septiembre 2022: 8:30 Hrs a 18:00 Hrs - horario continuado</p>
        <p>Jueves 15 de septiembre 2022: Desde 08:30 a 12:00 horas</p>
        <br />
        <h3>Oficina Puerto Aysén</h3>
        <p>Lunes 12 al miércoles 14 de septiembre 2022</p>
        <p>Mañana: 09:30 a 13:00 Hrs</p>
        <p>Tarde 14:15 a 18:15 Hrs</p>
        <p>Jueves 15 de septiembre 2022</p>
        <p>Desde 09:30 hasta las 12:00 horas</p>
        <br />
        <h3>Puerto Cisnes - Chile Chico - Cochrane</h3>
        <p>Lunes 12 al miércoles 14 de septiembre 2022: Horario de atención normal</p>
        <p>Jueves 15 de septiembre 2022: Hasta las 12:00 horas</p>
      </div>
    </div>
  </Page>
);

export default HorariosFiestasPatrias;