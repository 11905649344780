import React, { useRef } from 'react'
import { useHistory } from 'react-router-dom'
import DumpingPointsMap from '../../components/DumpingPointsMap/DumpingPointsMap'
import Page from '../../components/Layout/Page'
import "./DumpingPoints.css"

function DumpingPoints() {
  const dumpingRef = useRef(null)
  const formRef = useRef(null)
  const history = useHistory()
  // const phoneRef = useRef(null)
  const indicationsRef = useRef(null)

  const chooseRef = (number) => {
    switch(number) {
      default: return formRef
      case '2': return dumpingRef
      case '3': return null
      case '4': return indicationsRef
    }
  }
  const usageStep = (number, text) => {
    const refToUse = chooseRef(number)
    return (
      <div className="usage-step" onClick={() => { if (refToUse) window.scrollTo(0, refToUse.current.offsetTop) }}>
        <div className="step-number">
          {number}
        </div>
        <div className="step-text">
          {text}
        </div>
      </div>
    )
  }

  const meta = [
    {
      name: 'punto de descarga casa rodante',
      content: 'puntodedescargacasarodante'
    },
    {
      name: 'dumping point',
      content: 'dumpingpoint'
    },
    {
      name: 'motor home dumping point',
      content: 'motorhomedumpingpoint'
    },
    {
      name: 'aguas patagonia',
      content: 'aguaspatagonia'
    },
    {
      name: 'punto de descarga',
      content: 'puntodedescarga'
    },
  ]

  return (
    <Page
      title="Aguas Patagonia | Puntos de descarga"
      current='clients'
      meta={meta}
    >
      <div className="dumping-points-container">
        <div className="dumping-points-intro">
          <h1 className="dumping-points-title">Punto de descarga para casas rodantes</h1>
          <p className="dumping-points-translation">Motorhome dumping points</p>
          <p className="dumping-points-subtitle">
            Poniendo a disposición a la comunidad este punto de descarga para
            casas rodantes, buscamos ayudar a quienes nos visiten, a cuidar los
            ríos y lagos de la región, para así poder cuidar el agua y el medio ambiente,
            principal legado para las futuras generaciones.
          </p>
          <p className="dumping-points-translation">
            By having this unloading point for motor homes available to the community,
            we seek to help those who visit us to take care of the rivers and lakes of the region,
            in order to take care of water and the environment , the main legacy for future generations.
          </p>
        </div>
        <div className="dumping-points-indications">
          <h1>Indicaciones para uso de Punto de descarga</h1>
          <p className="dumping-points-translation">Dumping point usage indications</p>
          <div className="indications-container">
            {usageStep('1', 'Complete el formulario con sus datos')}
            {usageStep('2', 'Diríjase al punto de descarga')}
            {usageStep('3', 'Llame al teléfono señalado y espere que le abran el portón.')}
            {usageStep('4', 'Siga las indicaciones para uso del punto de descarga.')}
          </div>
        </div>
        <div className="dumping-points-attending">
          <h1>Horarios de atención</h1>
          <p className="dumping-points-translation">Opening hours</p>
          <div className="attending-hours">
            <p>Lunes a viernes de 9:00 a 13:00 y 15:00 a 17:00 hrs.</p>
            <p className="dumping-points-translation">Monday to Friday from 9:00 a.m. to 1:00 p.m. and 3:00 p.m. to 5:00 p.m.</p>
          </div>
        </div>
        <div ref={formRef} className="dumping-points-form">
          <h1>Click para abrir al formulario</h1>
          <p className="dumping-points-translation">Click to open form</p>
          <button onClick={() => history.push('/puntodedescarga')}>Formulario puntos de descarga</button>
          <p className="dumping-points-translation">Dumping points form</p>
        </div>
        <div ref={dumpingRef} className="dumping-points-map">
          <h1>Ubicación puntos de descarga</h1>
          <p className="dumping-points-translation">Dumping point locations</p>
          <div className="dumping-points-map-container">
            <DumpingPointsMap />
          </div>
        </div>
        <div ref={indicationsRef} className="dumping-points-instructions">
          <h1>Indicaciones para uso de punto de descarga</h1>
          <p className="dumping-points-translation">Dumping point instructions</p>
          <div>
            <div className="dumping-instruction-box">
              <p>
                <b>1. </b>Antes de comenzar a descargar el tanque de agua residual de su casa rodante,
                recomendamos contar con los siguientes implementos y tomar las siguientes precauciones:
              </p>
              <ul>
                <li>
                  Estacione fuera de la línea amarilla del punto de descarga.
                </li>
                <li>
                  Use guantes desechables.
                </li>
                <li>
                  Debe contar con una manguera de aguas residuales.
                </li>
                <li>
                  Debe contar con productos y desinfectante para manos. 
                </li>
              </ul>
            </div>
            <div className="dumping-instruction-box">
              <p>
                <b>2. </b>Asegúrese de que la válvula del tanque de aguas residuales de su casa rodante esté bien cerrada.
                Levante las dos tapas del punto de descarga. Conecte la manguera o tubería de aguas residuales de su
                casa rodante a la salida de descarga. Asegúrese de que la manguera esté bien introducida.
                Suelte la válvula del tanque de agua negra. Debería escuchar el desperdicio que fluye.
                Dejar que haga su trabajo hasta que ya no pueda escuchar nada.
              </p>
            </div>
            <div className="dumping-instruction-box">
              <p>
                <b>3. </b>Descargue su inodoro varias veces para asegurarse de que se eliminen todos los
                desechos. Desconecte la manguera y luego el receptáculo de descarga.Tome la
                manguera de agua disponible e higienice el sector.Lave sus manos con agua y jabón.
              </p>
            </div>
          </div>
          <h1 className="instructions-english-title instructions-italic">Instructions in english</h1>
          <div>
            <div className="dumping-instruction-box instructions-italic">
              <p>
                <b>1. </b>Before starting to discharge the waste water tank of your motorhome,
                we recommend having the following implements and taking the following precautions:
              </p>
              <ul>
                <li>
                  Park outside the dump station yellow line.
                </li>
                <li>
                  Wear disposable gloves.
                </li>
                <li>
                  You must have a sewage hose.
                </li>
                <li>
                  You must have sanitizer products.
                </li>
              </ul>
            </div>
            <div className="dumping-instruction-box instructions-italic">
              <p>
                <b>2. </b>Make sure the valve on your Motorhome sewage tank is properly closed.
                Lift the two covers of the dump station. Connect your Motorhome sewage hose or pipe to the discharge
                outlet. Make sure the hose is properly inserted. Release the valve on the black water tank first.
                Second discharge gray water. You should hear the waste flowing.
                Allow it to work until you can no longer hear anything.
              </p>
            </div>
            <div className="dumping-instruction-box instructions-italic">
              <p>
                <b>3. </b>Flush your toilet several times to make sure all waste is flushed out. Disconnect
                the hose and then the discharge receptacle.Take the available water hose and sanitize the area.
                Wash your hands with soap and water.

              </p>
            </div>
          </div>
        </div>
      </div>
    </Page>
    
  )
}

export default DumpingPoints