import React, { useState } from 'react';
import axios from 'axios';
import './WorkWithUsForm.css';
import { useSnackbar } from 'notistack'
// import { Link } from 'react-router-dom';

function WorkWithUsForm() {
    const [formValues, setValues] = useState({
      name: '',
      email: '',
      rut: '',
      address: '',
      curriculumFilePath:''
    })
    const [selectedFile, setSelectedFile] = useState(null)

    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')
    const [success, setSuccess] = useState('')
    const { enqueueSnackbar } = useSnackbar()

    function handleChange(event) {
      const id = event.target.id
      const value = event.target.value
      let newForm = { ...formValues }
      newForm[id] = value
      setValues(newForm)
    }
    function handleChangeFile(event){
      let newForm = {...formValues}
      newForm['curriculumFilePath'] = event.target.files[0].name
      setValues(newForm)
      setSelectedFile(event.target.files[0])
    }

    const submitForm = async () => {
      var ext = formValues.curriculumFilePath.split('.').pop()
      if (!['pdf', 'doc', 'docx'].includes(ext) && formValues.curriculumFilePath !== '') {
        enqueueSnackbar('Sólo se permiten archivos .pdf o .doc', {
          variant: 'error',
        })
      }
      if(!formValues.email || !formValues.rut || ! formValues.name ){
        enqueueSnackbar('Debe rellenar los campos obligatorios: Nombre, Email y Rut', {
          variant: 'error',
        })
      }
      else {
        setLoading(true)
        const formData = new FormData()
        formData.append('name', formValues.name)
        formData.append('email', formValues.email)
        formData.append('rut', formValues.rut)
        formData.append('address', formValues.address)
        formData.append('fileName', formValues.curriculumFilePath)
        formData.append('file', selectedFile)
        const response = await axios.post('/api/job', formData, {
          headers: {
            'Content-Type': `multipart/form-data; boundary=${formData._boundary}`
          },
        })
        setLoading(false)

        if (response.status === 200) {
          if (response.data.success) {
            setSuccess('Mensaje enviado.')
          } else {
            setError('Ha ocurrido un error. Por favor intente más tarde.')
          }
        } else {
          setError(response.data.message)
        }
      }
    }

    return (
      <div>
        <form
          className="work-with-us-form"
          action="/job"
          method="POST"
          enctype="multipart/form-data"
        >
          <div className="work-with-us-form-row">
            <div className="work-with-us-form-information">
              <label>
                {'Nombre(s):'} *
                <br />
                <input
                  type="text"
                  name="Nombre"
                  className="work-with-us-information-input"
                  id="name"
                  onChange={handleChange}
                  required
                />
              </label>
            </div>

            <div className="work-with-us-form-information">
              <label>
                {'Email:'} *
                <br />
                <input
                  type="text"
                  name="Email"
                  className="work-with-us-information-input"
                  id="email"
                  onChange={handleChange}
                  required
                />
              </label>
            </div>
          </div>
          <div className="work-with-us-form-row">
            <div className="work-with-us-form-information">
              <label>
                {'Rut:'} *
                <br />
                <input
                  type="text"
                  name="Rut"
                  className="work-with-us-information-input"
                  id="rut"
                  onChange={handleChange}
                  required
                />
              </label>
            </div>
            <div className="work-with-us-form-information">
              <label>
                {'Dirección:'}
                <br />
              </label>
              <input
                type="text"
                name="Dirección"
                className="work-with-us-information-input"
                id="address"
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="work-with-us-form-row">
            <div className="work-with-us-form-information file-info">
              <label htmlFor="curriculumFilePath">
                {'Curriculum:'}
                <p className="upload-cv-button">Subir archivo</p>
                <input
                  type="file"
                  style={{ display: 'none' }}
                  name="curriculumFilePath"
                  id="curriculumFilePath"
                  onChange={handleChangeFile}
                  accept=".pdf, .doc"
                />
              </label>
              <p className="file-name-show">{selectedFile ? selectedFile.name : ''}</p>
            </div>
          </div>
          {/* <div className="work-with-us-form-row">
                  <br />
                  <input type="submit" value="Enviar" className='work-with-us-information-submit' />
                </div> */}
          <button
            className="information-submit"
            disabled={loading}
            type="button"
            value="Enviar"
            onClick={() => submitForm()}
          >
            Enviar
          </button>
          <div className="loading">
            {loading ? <img src="/img/loading.gif" alt="Cargando..." /> : null}
          </div>
          <div>
            <p className="success-message">{success}</p>
            <p className="error-message">{error}</p>
          </div>
          {/* <input type="submit" value="Enviar" className='information-submit' /> */}
        </form>
      </div>
    )
}

export default WorkWithUsForm;