/* eslint no-param-reassign: ["error", { "props": false }] */
import produce from 'immer'
import {
  UPDATE_CART,
  ADD_ONE_TO,
  SUBTRACT_ONE_FROM,
  REMOVE_PRODUCT,
  UPDATE_SHIPPING,
  RESET_CART,
} from '../actions/cartActions'

const initialState = {
  cart: {},
  shipping: 'Chile Express',
}

const cartReducer = (state = initialState, action) => {
  switch (action.type) {
    case RESET_CART: {
      return produce(state, (draft) => {
        // Ensure we clear current session
        draft.cart = {}
      })
    }

    case UPDATE_CART: {
      const { cart } = action.payload

      return produce(state, (draft) => {
        // Ensure we clear current session
        draft.cart = cart
      })
    }

    case UPDATE_SHIPPING: {
      const { shipping } = action.payload

      return produce(state, (draft) => {
        // Ensure we clear current session
        draft.shipping = shipping
      })
    }

    default: {
      return state
    }

    case ADD_ONE_TO: {
      const { productId } = action.payload
      let newCart = {}

      return produce(state, (draft) => {
        // Ensure we clear current session
        newCart = { ...state.cart }
        newCart[productId] = {
          ...newCart[productId],
          amount: newCart[productId].amount + 1,
        }
        draft.cart = { ...newCart }
      })
    }

    case SUBTRACT_ONE_FROM: {
      const { productId } = action.payload
      let newCart = {}

      return produce(state, (draft) => {
        // Ensure we clear current session
        newCart = { ...state.cart }
        newCart[productId] = {
          ...newCart[productId],
          amount: newCart[productId].amount - 1,
        }
        draft.cart = { ...newCart }
      })
    }

    case REMOVE_PRODUCT: {
      const { productId } = action.payload
      let newCart = {}
      return produce(state, (draft) => {
        // Ensure we clear current session
        newCart = { ...state.cart }
        delete newCart[productId]
        draft.cart = { ...newCart }
      })
    }
  }
}

export default cartReducer
