import React from 'react'
import Page from '../../components/Layout/Page'
import './Services.css';

function Services() {
  return (
    <Page
      title="Aguas Patagonia | Venta de Agua y Servicios"
      current='clients'
    >
      <div className="page-container-services">
        <div className='icon-container-dev'>
          <img src='/img/icon5.png' alt='icon'></img>
        </div>
        <div className='text-wrapper-services'>
          <h1>Venta de Agua y Servicios</h1>
          <p>Si necesita comprar agua potable a granel, debe dirigirse a nuestra oficina comercial ubicada en Condell Nº 22 - Coyhaique o llámenos al 67-2215555</p>
        </div>
      </div>
    </Page >
  )
}

export default Services