import React from 'react'
import { Link } from 'react-router-dom'
import Page from '../../components/Layout/Page'
import './Covid.css';

function Covid() {
  return (
    <Page
      title="Aguas Patagonia | Beneficios Covid-19"
      current='clients'
    >
      <div className="page-container-covid">
        <div className='icon-container-dev'>
          <img src='/img/icon5.png' alt='icon'></img>
        </div>
        <div className='text-wrapper-covid'>
          <h1>Beneficio COVID-19 Ley 21.304<br />Extension de Ley 21.249<br />Ley de No Corte de Servicios Básicos</h1>
          <h2>Ley  N° 21.304</h2>
          <h3>&quot;Prorroga los efectos de la ley N°21.249 que dispone de manera excepcional, las medidas que indica en favor de los usuarios finales de servicios sanitarios, electricidad y gas de red&quot; del 05-01-2021</h3>
          <p><strong>Artículo único.-</strong> Modifícase la ley Nº 21.249, que dispone, de manera excepcional, las medidas que indica en favor de los usuarios finales de servicios sanitarios, electricidad y gas de red, de la manera que sigue:</p>
          <ol style={{ marginLeft: '25px' }}>
            <li>Reemplázase, en el artículo 1, la frase &quot;Durante los doscientos setenta días siguientes a la publicación de esta ley&quot; por la siguiente: &quot;Hasta el 31 de diciembre de 2021&quot;.</li>
            <li>Sustitúyese, en el artículo 2, la frase &quot;los doscientos setenta días posteriores a la publicación de esta ley&quot; por &quot;el 31 de diciembre de 2021&quot;.</li>
            <li>Reemplázase, en el artículo 2, la expresión &quot;treinta y seis&quot; por &quot;cuarenta y ocho&quot;.</li>
            <li>Reemplázase, en la letra a) del artículo 3, el guarismo &quot;60&quot; por &quot;80&quot;.</li>
            <li>
              En el artículo 7:<br/>
              a) Sustitúyese, en el inciso primero, la frase &quot;Durante los doscientos setenta días siguientes a la publicación de esta ley&quot; por &quot;Hasta el 31 de diciembre de 2021&quot;.<br />
              b) Sustitúyese, en el inciso segundo, la frase &quot;los doscientos setenta días posteriores a ella&quot; por la siguiente: &quot;el 31 de diciembre de 2021&quot;.
            </li>
            <li>Reemplázase, en el artículo 10, el guarismo &quot;36&quot; por &quot;48&quot;.</li>
            <li>Incorpórase el siguiente artículo 11, nuevo:</li>
          </ol>
          <p>&quot;Artículo 11. Cumplido el plazo indicado en los artículos 1, 2 y 7, si aún se encontrare vigente la declaración de Estado de Excepción Constitucional de Catástrofe por pandemia de Covid 19, declarado en el decreto 104, de 18 de marzo de 2020, del Ministerio del  Interior y Seguridad Pública, y sus prórrogas, dichos plazos se extenderán hasta 60 días desde terminado dicho estado de excepción constitucional&quot;.</p>
          {/* <p><strong>Artículo 9.-</strong> Sin perjuicio del plazo establecido en el artículo 2, los beneficiarios señalados en los artículos 3 y 4 tendrán un plazo de 30 días adicionales para el solo efecto de acogerse a lo dispuesto en el artículo 2. Quince días antes del vencimiento del plazo establecido en el inciso primero del artículo 1, las empresas deberán remitir a los clientes finales la información correspondiente al monto de su deuda y a los beneficios a los que se pueden acoger de conformidad a esta ley.</p>
          <p><strong>Artículo 10.-</strong> Las empresas de servicios sanitarios, empresas y cooperativas de distribución de electricidad y empresas de gas de red deberán informar en sus sitios web y en las cuentas, ya sean físicas o virtuales, la deuda que mantiene el usuario por la aplicación de esta normativa, de haberla, y la forma cómo podría prorratearse, de 1 a 36 cuotas.</p> */}
          <h2>Ley N° 21.249</h2>
          <h3>&quot;Dispone de manera excepcional, las medidas que indica en favor de los usuarios finales de servicios sanitarios, electricidad y gas de red&quot; del 08-08-2020</h3>
          <p><strong>Artículo 1.-</strong> Durante los noventa días siguientes a la publicación de esta ley, las empresas proveedoras de servicios sanitarios, empresas y cooperativas de distribución de electricidad y las empresas de distribución de gas de red no podrán cortar el suministro por mora en el pago a las personas, usuarios y establecimientos, en adelante usuarios, clientes o beneficiarios, que a continuación se indican:</p>
          <ol style={{ listStyleType: 'lower-latin', marginLeft: '25px' }}>
            <li>Usuarios residenciales o domiciliarios.</li>
            <li>Hospitales y centros de salud.</li>
            <li>Cárceles y recintos penitenciarios.</li>
            <li>Hogares de menores en riesgo social, abandono o compromiso delictual.</li>
            <li>Hogares y establecimientos de larga estadía de adultos mayores.</li>
            <li>Bomberos.</li>
            <li>Organizaciones sin fines de lucro.</li>
            <li>Microempresas, de acuerdo a lo establecido en la ley N° 20.416, que fija normas especiales para las empresas de menor tamaño.</li>
          </ol>
          <p>Se suspende para los clientes señalados en el inciso anterior, por el plazo a que se refiere este artículo, la aplicación de los incisos tercero, cuarto, quinto y sexto del artículo 36 del decreto con fuerza de ley N° 323, de 1931, del Ministerio del Interior, Ley de Servicios de Gas; del artículo 141 y del inciso segundo del literal q) del artículo 225 del decreto con fuerza de ley N°4, de 2007, del Ministerio de Economía, Fomento y Reconstrucción, que fija el texto refundido, coordinado y sistematizado del decreto con fuerza de ley N° 1, de Minería, de 1982, Ley General de Servicios Eléctricos, en materia de energía eléctrica, y lo establecido en la letra d) del artículo 36 del decreto con fuerza de ley Nº 382, de 1988, del Ministerio de Obras Públicas, Ley General de Servicios Sanitarios.<br />Se excluye de la aplicación de la presente ley a las empresas sanitarias con menos de 12.000 clientes que constituyan una sola unidad económica y no sean filial de otra empresa sanitaria, y a las cooperativas y comités de agua potable rural, sin perjuicio de los convenios, descuentos o facilidades de pago que otorguen a sus clientes.</p>
          <p><strong>Artículo 2.-</strong> Las deudas contraídas con las empresas de servicios sanitarios, empresas y cooperativas de distribución de electricidad y empresas de gas de red, que se generen entre el 18 de marzo de 2020 y hasta los noventa días posteriores a la publicación de esta ley, se prorratearán en el número de cuotas mensuales iguales y sucesivas que determine el usuario final a su elección, las que no podrán exceder de doce, a partir de la facturación siguiente al término de este último plazo, y no podrán incorporar multas, intereses ni gastos asociados.<br />Adicionalmente, a elección del usuario final, el prorrateo podrá incluir deudas generadas antes de las contraídas según lo señalado en el inciso anterior, hasta el monto de diez unidades de fomento para las empresas distribuidoras y cooperativas de electricidad y hasta el monto de cinco unidades de fomento para las empresas de servicios sanitarios y de distribución de gas de red, en las mismas condiciones.</p>
          <p>Artículo 3.- Solo podrán acogerse a lo dispuesto en el artículo 2 los clientes finales que cumplan con, al menos, uno de los siguientes requisitos:</p>
          <ol style={{ listStyleType: 'lower-latin', marginLeft: '25px' }}>
            <li>Encontrarse dentro del 60 por ciento de vulnerabilidad, de conformidad al Registro Social de Hogares.</li>
            <li>Tener la calidad de adulto mayor, de acuerdo a la ley N° 19.828, que crea el Servicio Nacional del Adulto Mayor.</li>
            <li>Estar percibiendo las prestaciones de la ley N° 19.728, que establece un seguro de desempleo.</li>
            <li>Estar acogido a alguna de las causales de la ley N° 21.227, que faculta el acceso a prestaciones del seguro de desempleo de la ley N° 19.728, en circunstancias excepcionales, ya sea por la suspensión de la relación laboral o por la celebración de un pacto de reducción temporal de jornada.</li>
            <li>Ser trabajador independiente o informal no comprendido en alguna de las categorías anteriores, y expresar, mediante declaración jurada simple, que está siendo afectado por una disminución significativa de ingresos que justifica el acceso a los beneficios. La utilización maliciosa de la declaración se sancionará de conformidad al artículo 210 del Código Penal. Los requisitos señalados en el inciso anterior no serán exigibles a los beneficiarios indicados en los literales b), c), d), e), f), g) y h) del artículo 1.</li>
          </ol>
          <p><strong>Artículo 4.-</strong> Los usuarios finales no comprendidos en el artículo anterior que acrediten estar imposibilitados de dar cumplimiento a las obligaciones de pago que han contraído con la respectiva empresa o cooperativa prestadora, y así lo expresen mediante declaración jurada simple, podrán solicitar acogerse a la postergación y prorrateo de los pagos, tratándose de las empresas y cooperativas indicadas en el artículo 1. La utilización maliciosa de la declaración se sancionará de conformidad con lo dispuesto en el artículo 210 del Código Penal. La negativa de la empresa prestadora podrá ser objeto de reclamo ante la subsecretaría, superintendencia u organismo fiscalizador respectivo, y se sujetará a la normativa sectorial que corresponda.</p>
          <p><strong>Artículo 5.-</strong> Dentro de los cinco días siguientes a la publicación de esta ley, las empresas y cooperativas proveedoras de los servicios señalados en ella, deberán establecer plataformas de atención al cliente, por internet y telefonía, que permitan formular las solicitudes para acceder a los beneficios que la presente ley establece.<br />En cualquiera de los casos previstos en esta ley, las empresas y cooperativas proveedoras deberán resolver las solicitudes efectuadas por los interesados, dentro de los cinco días hábiles siguientes a su formulación. Respecto de los usuarios que reúnan cualquiera de las condiciones indicadas en el inciso primero del artículo 3, no procederá rechazo alguno, y el beneficio será aplicado, de pleno derecho, por parte de las empresas proveedoras y cooperativas.<br />La respuesta de la correspondiente empresa o cooperativa deberá ser comunicada al solicitante por medio de correo electrónico o mensaje de texto, dentro del señalado plazo. En caso de que la respuesta fuere negativa, la empresa o cooperativa deberá mencionar y justificar las razones del rechazo.<br />Del mismo modo, las empresas deberán informar sus resoluciones a la subsecretaría, superintendencia u organismo fiscalizador respectivo y, quincenalmente, deberán publicar en su página web el número y porcentaje de solicitudes aceptadas y rechazadas, conforme a lo establecido en el inciso anterior.<br />Las empresas y cooperativas deberán elaborar un registro y estadísticas de los solicitantes beneficiarios, en un plazo no mayor a diez días hábiles desde la publicación de esta ley, y deberán actualizarlo quincenalmente.<br />Las denuncias de infracciones de esta ley deberán ser tratadas, por parte de las subsecretarías, superintendencias u organismos fiscalizadores respectivos, como reclamos, de acuerdo a la normativa vigente.</p>
          <p><strong>Artículo 6.-</strong> Las infracciones de lo dispuesto en la presente ley serán sancionadas de conformidad a la normativa sectorial respectiva.</p>
          <p><strong>Artículo 7.-</strong> Durante los noventa días siguientes a la publicación de esta ley, las empresas generadoras y transmisoras de energía eléctrica, deberán continuar proveyendo con normalidad sus servicios a las empresas distribuidoras domiciliarias de energía y a las cooperativas eléctricas.<br />Dentro del plazo comprendido entre los treinta días previos a la publicación de esta ley y los noventa días posteriores a ella, de manera excepcional, el pago de las cooperativas eléctricas a las empresas generadoras y transmisoras podrá ser realizado en cuotas, en el mismo número de meses en que se prorratearán las cuentas de sus beneficiarios, sin multas, intereses ni gastos asociados.</p>
          <p><strong>Artículo 8.-</strong> Si los beneficiarios de esta ley hubiesen sido objeto de cortes o suspensiones de suministro o servicio, por mora en el pago de cualquiera de los servicios señalados en el artículo 1, la respectiva empresa proveedora o cooperativa deberá proceder a la reposición inmediata del servicio, sin costo alguno para el usuario, una vez publicada la presente ley.</p>
          <div className='form-link'>
            Revise los detalles en el siguiente <a href='https://aguas-patagonia-public-files.s3-sa-east-1.amazonaws.com/Beneficio_COVID.pdf' target="_blank" rel="noopener noreferrer">documento</a>
          </div>
          <div className="covid-info">
            <p><strong>Si usted cumple con los requisitos postule a través de un correo en el siguiente link, indicando en la casilla de comentarios "Postulación Covid-19":</strong></p>
            <p><Link to="/contacto">Enviar correo</Link></p>
            <p>Una vez recibido nuestros ejecutivos le responderán a la brevedad.</p>
          </div>
          <div className="covid-table">
            <h2>Solicitudes</h2>
            <table cellpadding="10" cellspacing="0" border="1">
              <thead>
                <tr>
                  <th>60% más vulnerable</th>
                  <th>Tercera edad</th>
                  <th>Cesantía</th>
                  <th>Total</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>136</td>
                  <td>26</td>
                  <td>7</td>
                  <td>169</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="covid-table">
            <h2>Resultados</h2>
            <table cellpadding="10" cellspacing="0" border="1">
              <thead>
                <tr>
                  <th></th>
                  <th>60% más vulnerable</th>
                  <th>Tercera edad</th>
                  <th>Cesantía</th>
                  <th>Total</th>
                  <th>%</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Otorgados</td>
                  <td>117</td>
                  <td>21</td>
                  <td>7</td>
                  <td>145</td>
                  <td>86%</td>
                </tr>
                <tr>
                  <td>No otorgados</td>
                  <td>19</td>
                  <td>5</td>
                  <td>0</td>
                  <td>24</td>
                  <td>14%</td>
                </tr>
                <tr>
                  <td>En evaluación</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0%</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </Page >
  )
}

export default Covid