import React, { useEffect, useState } from 'react'
import axios from 'axios';
import csc from 'country-state-city'
import Page from '../../components/Layout/Page'
import './MotorHome.css'
import vpn from 'validate-phone-number-node-js'
import { validateEmail } from '../../utils/validator'

function MotorHome() {
  const [submit, setSubmit] = useState(false)
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)
  const [inputValues, setInputValues] = useState({
    name: '',
    surname: '',
    rut: '',
    email: '',
    phone: '',
    nationality: '43',
    realNationality: '43',
    // Procedencia
    country: '43',
    region: '728',
    city: '11111',
    realCountry: '43',
    realRegion: '728',
    realCity: '11111',
    address: '',
    // acceptance
    accepts: false,
  })

  const [sent, setSent] = useState(false)

  useEffect(() => {
    const postForm = async () => {
      setLoading(true)
      await axios.post('/api/motorhome', inputValues)
      setSent(true)

      setTimeout(() => {
        setLoading(false)
        setSent(false)
      }, 10000)
    }

    setError(null)
    if (submit && inputValues.accepts) {
      postForm()
    }
  }, [submit, inputValues])

  const handleChange = (event) => {
    setInputValues({ ...inputValues, [event.target.name]: event.target.value });
  };

  const submitForm = () => {
    if (!inputValues.accepts) {
      setError('Debe aceptar las condiciones del servicio')
      return
    }

    if (!validateEmail(inputValues.email)) {
      setError('Debe ingresar un email válido')
      return
    }

    if (!vpn.validate(inputValues.phone)) {
      setError('Debe ingresar un teléfono válido')
      return
    }

    // Change address codes to names
    setInputValues({
      ...inputValues,
      realCountry: csc.getCountryById(inputValues.country).name,
      realCity: csc.getCityById(inputValues.city).name,
      realRegion: csc.getStateById(inputValues.region).name,
      realNationality: csc.getCountryById(inputValues.nationality).name,
    });

    setSubmit(true)
  }

  return (
    <Page
      title="Aguas Patagonia | Motorhome"
      current='clients'
    >
      <div className="motorhome-container">
        <h1>Datos de contacto responsable de Casa Rodante</h1>
        <p className="language-translation-motorhome main-title-translation">Driver Contact Information</p>
        <div className="motorhome-personal-info">
          <h1>Información personal</h1>
          <p className="language-translation-motorhome title-translation">Personal Information</p>
          <div className="motorhome-personal-info-form">
            <div className="column-motorhome-form">
              <div className="motorhome-input">
                <label>Nombre</label>
                <p className="language-translation-motorhome">Name</p>
                <input type="text" name="name" value={inputValues.name} onChange={handleChange} />
              </div>
              <div className="motorhome-input">
                <label>Apellidos</label>
                <p className="language-translation-motorhome">Last Name</p>
                <input type="text" name="surname" value={inputValues.surname} onChange={handleChange} />
              </div>
              <div className="motorhome-input">
                <label>RUT/Pasaporte</label>
                <p className="language-translation-motorhome">Pessport</p>
                <input type="text" name="rut" value={inputValues.rut} onChange={handleChange} />
              </div>
            </div>
            <div className="column-motorhome-form">
              <div className="motorhome-input">
                <label>Correo</label>
                <p className="language-translation-motorhome">Mail</p>
                <input type="text" name="email" value={inputValues.email} onChange={handleChange} />
              </div>
              <div className="motorhome-input">
                <label>Teléfono</label>
                <p className="language-translation-motorhome">Phone Number</p>
                <input type="text" name="phone" value={inputValues.phone} onChange={handleChange} />
              </div>
              <div className="motorhome-input">
                <label>Nacionalidad</label>
                <p className="language-translation-motorhome">Nationality</p>
                <select type="text" name="nationality" value={inputValues.nationality} onChange={handleChange}>
                  {csc.getAllCountries().map((s, index) => (
                    <option key={index} value={s.id}>{s.name}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        </div>
        <div className="motorhome-address-info">
          <h1>Lugar de procedencia</h1>
          <p className="language-translation-motorhome title-translation">Country of Origin</p>
          <div className="motorhome-address-info-form">
            <div className="column-motorhome-form">
              <div className="motorhome-input">
                <label>País</label>
                <p className="language-translation-motorhome">Country</p>
                <select type="text" name="country" value={inputValues.country} onChange={handleChange}>
                  {csc.getAllCountries().map((c, index) => (
                    <option key={index} value={c.id}>{c.name}</option>
                  ))}
                </select>
              </div>
              <div className="motorhome-input">
                <label>Región</label>
                <p className="language-translation-motorhome">Region or State</p>
                <select type="text" name="region" value={inputValues.region} onChange={handleChange}>
                  {csc.getStatesOfCountry(inputValues.country).map((s, index) => (
                    <option key={index} value={s.id}>{s.name}</option>
                  ))}
                </select>
              </div>
              <div className="motorhome-input">
                <label>Ciudad</label>
                <p className="language-translation-motorhome">City</p>
                <select type="text" name="city" value={inputValues.city} onChange={handleChange}>
                  {csc.getCitiesOfState(inputValues.region).map((r, index) => (
                    <option key={index} value={r.id}>{r.name}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="column-motorhome-form">
            <div className="motorhome-input">
                <label>Dirección</label>
                <p className="language-translation-motorhome">Address</p>
                <input type="text" name="address" value={inputValues.address} onChange={handleChange} />
              </div>
            </div>
          </div>
        </div>
        <div className="accept-terms">
          <p>Acepto las <a href="https://aguas-patagonia-public-files.s3-sa-east-1.amazonaws.com/terminos_del_servicio.pdf" target="_blank" rel="noopener noreferrer">condiciones del servicio</a></p>
          <input type="checkbox" checked={inputValues.accepts} onChange={() =>setInputValues({ ...inputValues, accepts: !inputValues.accepts })} />
        </div>
        <p className="language-translation-motorhome full-width-translation">Accept terms of service</p>
        <div className="motorhome-submit-container">
          <button disabled={loading} className="motorhome-submit blue-button" type="button" onClick={submitForm}>
            <p>ENVIAR</p>
          </button>
          <p className="language-translation-motorhome">SEND</p>
        </div>
        <p className="success-message">{sent && "Formulario enviado exitosamente."}</p>
        <p className="error-message">{error}</p>
        <div className="download-motorhome-form">
          <a href="https://aguas-patagonia-public-files.s3-sa-east-1.amazonaws.com/fomulario_motorhome.pdf" target="_blank" rel="noopener noreferrer">Descargue el formulario aquí</a>
          <p className="language-translation-motorhome">Download from here</p>
        </div>
      </div>
    </Page>
  )
}

export default MotorHome