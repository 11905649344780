import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { SnackbarProvider } from "notistack";
import App from "./App";
import { configureStore } from "./store";
import { unregister } from "./serviceWorker";
import { SettingsProvider } from "./context/SettingsContext";
import LoadingScreen from "./components/Loading/LoadingScreen";
import { restoreSettings } from "./utils/settings";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "react-quill/dist/quill.snow.css";
import "nprogress/nprogress.css";
import "react-perfect-scrollbar/dist/css/styles.css";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import "./index.css";

const { store, persistor } = configureStore();
const settings = restoreSettings();

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={<LoadingScreen />} persistor={persistor}>
      <React.StrictMode>
        <SnackbarProvider maxSnack={1}>
          <SettingsProvider settings={settings}>
            <App />
          </SettingsProvider>
        </SnackbarProvider>
      </React.StrictMode>
    </PersistGate>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
unregister();
