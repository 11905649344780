import React from 'react'
import Page from '../../components/Layout/Page'
import './PaymentMethods.css';

function PaymentMethods() {
  return (
    <Page
      title="Aguas Patagonia | Lugares y Medios de Pago"
      current='clients'
    >
      <div className="page-container-dev-pm">
        <div className='icon-container-dev-pm'>
          <img src='/img/icon5.png' alt='icon'></img>
        </div>
        <div className='text-wrapper-pm'>
          <h1>Lugares y Medios de Pago</h1>
          <p>Puedes pagar de manera presencial en nuestras oficinas y en los siguientes sistemas habilitados.</p>
          <div className="payment-methods">
            <ul>
              <li>
                <div className="payment-item">
                  <div className="payment-item-img">
                    <a href="https://ww3.servipag.com/pagoenlinea/portal/desktop/public/todas/!ut/p/b1/04_Sj9CPykssy0xPLMnMz0vMAfGjzOKNgs0CLZ0MHQ38zQPcDBx9gs1CzAK9Df0DTYAKIvEo8DanTL-HEXH6DXAARwNC-r30o9Jz8pOAXg3Xj8KrGOQXvApAjgUrwOMaP4_83FT9gtzQ0IjKYE9dR0VFADNEynw!/dl4/d5/L2dBISEvZ0FBIS9nQSEh/?idServicio=1&idBiller=58" target="_blank" rel="noopener noreferrer"><img src="https://aguas-patagonia-public-files.s3-sa-east-1.amazonaws.com/servipag.jpg" alt="Servipag" /></a>
                  </div>
                </div>
              </li>
              <li>
                <div className="payment-item">
                  <div className="payment-item-img">
                    <a href="https://www.sencillito.com/pago" target="_blank" rel="noopener noreferrer"><img src="https://aguas-patagonia-public-files.s3-sa-east-1.amazonaws.com/sencillito.jpg" alt="Sencillito" /></a>
                  </div>
                </div>
              </li>
              {/* <li>
                <div className="payment-item">
                  <div className="payment-item-img">
                    <a href="https://www.bancoestado.cl/" target="_blank" rel="noopener noreferrer"><img src="https://aguas-patagonia-public-files.s3-sa-east-1.amazonaws.com/bancoestado.jpg" alt="BancoEstado" /></a>
                  </div>
                </div>
              </li> */}
              <li>
                <div className="payment-item">
                  <div className="payment-item-img">
                    <a href="https://www.unired.cl/" target="_blank" rel="noopener noreferrer"><img src="https://aguas-patagonia-public-files.s3-sa-east-1.amazonaws.com/unired.png" alt="Unired" /></a>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </Page >
  )
}

export default PaymentMethods