import React, { useEffect, useState } from 'react'
import Popover from "@material-ui/core/Popover";

import Page from '../../components/Layout/Page'
import Carousel from '../../components/Carousel/Carousel'
import './Home.css'

function Popup() {
  const seen = window.localStorage.getItem('video-seen');
  let videoSeen = false;
  if (!seen) {
    videoSeen = false
  }

  if (seen === 'true') {
    videoSeen = true;
  }

  const [open, setOpen] = useState(!videoSeen);

  const handleClose = () => {
    window.localStorage.setItem('video-seen', 'true');
    setOpen(false);
  };


  const width = window.innerWidth * 0.6;
  const height = window.innerWidth * 0.6 * 0.75;

  return (
    <>
    <Popover
      open={open}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "center",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "center",
        horizontal: "center",
      }}
    >
      <div style={{ marginLeft: '25px', marginRight: '25px' }}>
        <video width={width} height={height} autoplay="true" muted="true" poster="https://aguas-patagonia-public-files.s3-sa-east-1.amazonaws.com/imagen_fugas.jpg" controls>
          <source src="https://aguas-patagonia-public-files.s3.sa-east-1.amazonaws.com/NUEVA_EDICION_AGUAS_PATAGONIA_23.mp4" type="video/mp4" />
        </video>
      </div>
    </Popover>
    </>
  );
}

function Notice() {
  const [openNotice, setOpenNotice] = useState(true);

  const handleClose = () => {
    setOpenNotice(false);
  };


  const width = window.innerWidth * 0.6 < 800 ? window.innerWidth * 0.6 : 800;
  const height = window.innerWidth * 0.6 < 800 ? window.innerWidth * 0.6 : 800;

  return (
    <>
    <Popover
      open={openNotice}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "center",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "center",
        horizontal: "center",
      }}
    >
      <div style={{ marginLeft: '25px', marginRight: '25px' }}>
        <img src="https://aguas-patagonia-public-files.s3.sa-east-1.amazonaws.com/horario_atencion_sept_2024.png" width={width} height={height} alt="Horarios Fiestas Patrias" />
      </div>
    </Popover>
    </>
  );
}

function Home() {
  return (
    <Page
      title="Aguas Patagonia | Inicio"
      current='home'
    >
      <div>
        <Carousel />
      </div>
      <div className="home-container">
        <div className="mobile-home-buttons">
          <div className="mobile-home-button">
            <a href="/video">
              <div className="mobile-home-button-img">
                <img src="/img/play.png" alt="video" />
              </div>
              <div className="mobile-home-button-text">video corporativo</div>
            </a>
          </div>
          <div className="mobile-home-button">
            <a href="/comunicados">
              <div className="mobile-home-button-img">
                <img src="/img/doc-icon.png" alt="comunicados" />
              </div>
              <div className="mobile-home-button-text">comunicados y noticias</div>
            </a>
          </div>
          <div className="mobile-home-button">
            <a href="/cortes">
              <div className="mobile-home-button-img">
                <img src="/img/drops-icon.png" alt="cortes-programados" />
              </div>
              <div className="mobile-home-button-text">cortes programados</div>
            </a>
          </div>
          <div className="mobile-home-button">
            <a href="/lugares-y-medios-de-pago">
              <div className="mobile-home-button-img">
                <img src="/img/money-icon.png" alt="medios-de-pago" />
              </div>
              <div className="mobile-home-button-text">lugares y medios de pago</div>
            </a>
          </div>
          <div className="mobile-home-button">
            <a href="/venta-de-agua-y-servicios">
              <div className="mobile-home-button-img">
              <img src="/img/truck-icon.png" alt="venta-de-agua-y-servicios" />
              </div>
              <div className="mobile-home-button-text">venta de agua y servicios</div>
            </a>
          </div>
        </div>
        <div className="first-part-home">
          <div className="intro-sustainability">
            <div className="intro-sustainability-text">
              <div className="intro-text-box">
                <h1>Comprometidos con la Sostenibilidad</h1>
                <p>Aguas Patagonia es una empresa comprometida con el desarrollo y la sostenibilidad de la Región de Aysén, poniendo el acento en la calidad de servicio y el compromiso con la comunidad y el medioambiente.</p>
                <button
                  className="blue-button"
                  onClick={() => {
                    window.location.href = '/sostenibilidad'
                  }}
                >
                  Conoce más
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="second-part-home">
         <img
         alt="compromiso=sustentabilidad"
         className="home-middle-image"
         src="https://aguas-patagonia-public-files.s3.sa-east-1.amazonaws.com/MPD.jpg"/> 
        </div>
        <div className="second-part-home">
          <div className="message-or-complaint-home-box">
            <div className="message-or-complaint-text">
              <img className="circle-imgs-home blue" src="/img/blue-circle.png" alt="blue-circle" />
              <img className="circle-imgs-home light-blue" src="/img/light-blue-circle.png" alt="light-blue-circle" />
              <img className="circle-imgs-home cyan" src="/img/calypso-circle.png" alt="calypso-circle" />
              <h2>¿Tienes algún mensaje o reclamo?</h2>
              <p>
                Ponte en contacto con nosotros y
                veremos la mejor forma para
                ayudarte a solucionar tus problemas.
              </p>
              <button
                className="blue-button"
                onClick={() => {
                  window.location.href = '/contacto'
                }}
              >
                Contacto
              </button>
            </div>
          </div>
          <div className="social-media-home-box">
            <div className="social-media-home-text">
              <img className="circle-imgs-home blue" src="/img/blue-circle.png" alt="blue-circle" />
              <img className="circle-imgs-home light-blue" src="/img/light-blue-circle.png" alt="light-blue-circle" />
              <img className="circle-imgs-home cyan" src="/img/calypso-circle.png" alt="calypso-circle" />
              <h2>Síguenos en nuestras Redes Sociales</h2>
              <p>
                Pincha nuestros links para estar al día
                de todas las actividades que
                realizamos en la empresa.
              </p>
              <div className="social-media-icons-home">
                <a href="https://www.instagram.com/aguaspatagonia" target="_blank" rel="noopener noreferrer"><img src="/img/instagram.png" alt="instagram" /></a>
                <a href="https://twitter.com/AguasPatagonia" target="_blank" rel="noopener noreferrer"><img src="/img/twitter.png" alt="twitter" /></a>
                <a href="https://www.facebook.com/AguasPatagonia" target="_blank" rel="noopener noreferrer"><img src="/img/facebook.png" alt="facebook" /></a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Popup />
    </Page>
  )
}

export default Home