import React, { useState } from 'react'
import Page from '../../components/Layout/Page'
import './WinterMeasurement.css'

function Measurement() {
  const [currentImage, setCurrentImage] = useState(0);
  const images = [
    "img/10.jpg",
    "img/15.jpg",
    "img/16.jpg",
  ];

  const nextImage = () => {
    setCurrentImage((currentImage + 1) % images.length);
  };

  const prevImage = () => {
    setCurrentImage((currentImage - 1 + images.length) % images.length);
  };

  return (
    <Page current="measurement">
      <div className='center-container'>
        <div className='center-container-column'>
          <div className="measurement-header">
            <div className="measurement-image-container">
              <img src="img/01.jpg" alt="Invierno" />
            </div>
            <div className="measurement-text-container-left">
              <h1>Aisla tu medidor del frío extremo</h1>
              <p>El medidor es un componente clave en la
                distribución de agua. Durante el invierno,
                es fundamental protegerlo del frío extremo para evitar posibles daños.
                Recomendamos aislarlo adecuadamente utilizando materiales aislantes como el
                plumavit entero o trozado, además de que tenga su tapa en adecuadas condiciones.
                Esto ayudará a mantenerlo en óptimas condiciones y garantizar un suministro confiable.</p>
              <b>Nunca utilices plumavit en perla, ni ropa o frazadas para aislar.</b>
            </div>
          </div>
          <div className="measurement-header">
            <div className="measurement-text-container-right">
              <h1>En invierno, si sales de casa por varios días recuerda</h1>
              <div>Las bajas temperaturas pueden congelar las llaves interiores, lo que puede ocasionar rupturas en las tuberías. Antes de que llegue el frío, asegúrate de cerrar bien todas las llaves de agua en tu hogar y protégelas del frío envolviéndolas con materiales aislantes. <b>Previene siempre.</b></div>
              <br />
              <p>
                <b>Sí debes salir de casa por varios días desagüe las cañerías, ¿Cómo?</b>
              </p>
              <br />
              <p><b>1) Corta el ingreso de agua desde la llave de paso de tu medidor.</b></p>
              <p><b>2) Abre las llaves interiores para sacar toda el agua de tus cañerías</b></p>
              <p><b>3) Saca la totalidad del agua del calefón</b></p>
            </div>
            <div className="measurement-image-container">
              <img src="img/02.jpg" alt="Invierno" />
            </div>
          </div>
          <div className="measurement-header">
            <div className="measurement-text-container-center">
              <p>En Aguas Patagonia, nos preocupamos por tu bienestar y queremos asegurarnos de que cuentes con un suministro confiable de agua potable durante todo el año, incluso en las condiciones invernales más desafiantes. Nuestra Campaña de Invierno tiene como objetivo recordarte la importancia de tomar medidas preventivas para proteger el recurso más valioso: el agua. Te animamos a seguir nuestras recomendaciones y compartir esta información con tus seres queridos. Juntos, podemos cuidar el agua y garantizar su disponibilidad para las generaciones futuras.</p>
              <p>¡Cuenta con nosotros en esta temporada invernal!</p>
            </div>
          </div>
          <div className="winter-gallery">
            <div className="winter-carousel">
              <button className="winter-carousel-button previous-button" onClick={prevImage}>&lt;</button>
              <img src={images[currentImage]} alt={`Imagen ${currentImage + 1}`} />
              <button className="winter-carousel-button next-button" onClick={nextImage}>&gt;</button>
            </div>
          </div>
        </div>
      </div>
    </Page>
  );
}

export default Measurement;
