import React from 'react'
import Page from '../../components/Layout/Page'
import './FAQ.css';

function FAQ() {
  return (
    <Page
      title="Aguas Patagonia | Preguntas Frecuentes"
      current='clients'
    >
      <div className="page-container-wrap">
        <div className='icon-container-dev-faq'>
          <img src='/img/icon5.png' alt='icon'></img>
        </div>
        <div className='text-wrapper-faq'>
          <h1>Preguntas Frecuentes</h1>
          <h2>1.- ¿Dónde se puede cancelar una cuenta atrasada?</h2>
          <p>En cualquier centro de pago autorizado.</p>
          <h2>2.- ¿Qué hacer si no tiene la boleta?</h2>
          <p>En caso de no tener la boleta de consumo puede solicitar un comprobante para pagar su cuenta en cualquiera de nuestras oficinas comerciales.</p>
          <h2>3.- ¿Por qué motivos se puede cambiar el medidor de Agua Potable?</h2>
          <p>El medidor de Agua Potable puede ser reemplazado cuando este se encuentra en mal estado o funcionando mal, por otro nuevo de similares características, no existiendo cobro para el Cliente. Sólo se efectuará cobro en aquellos casos en que éste haya sido sustraído o dañado por personas ajenas a la Empresa.</p>
          <h2>4.- ¿Quién es responsable ante un congelamiento de cañería?</h2>
          <p>En términos generales, la Empresa es responsable de lo que ocurra con el arranque de agua potable hasta la segunda llave de paso del medidor, inclusive. Sin embargo, el usuario es responsable del cuidado y protección adecuada del medidor, lo que implica que debe asegurarse de que éste se mantenga tapado y protegido ante las bajas temperaturas.</p>
          <p>No obstante lo anterior, aun con esta protección el medidor podría congelarse. En este caso, la Empresa repondrá el medidor dañado sin costo para el cliente.</p>
          <p>Si el medidor se congeló por encontrarse desprotegido, corresponderá al cliente costear la reposición de éste.</p>
          <h2>5.- ¿Qué es el subsidio de Agua Potable?</h2>
          <p>El subsidio es la forma en que el Estado ayuda a las familias de menores recursos en el pago de la cuenta del agua. Para acceder a este beneficio el cliente deberá postular en su Municipalidad respectiva. Para mantener el subsidio tendrá que mantener su cuenta al día.</p>
          <h2>6.- ¿Qué significa que mi cuenta esté con "Corte en Trámite"?</h2>
          <p>Es una advertencia de corte de suministro que es usada en todos los clientes que a la fecha de facturación presenten un saldo anterior pendiente.</p>
          <h2>7.- ¿Quién fija las tarifas aplicadas por Aguas Patagonia?</h2>
          <p>La tarifa aplicada es producto de una negociación que se realiza cada 5 años entre la Superintendencia de Servicios Sanitarios y Aguas Patagonia, de acuerdo a la legislación vigente.</p>
          <h2>8.- ¿Por qué se cobra cargo fijo y a qué corresponde este cobro?</h2>
          <p>Es un cobro mensual que se aplica independientemente de sí existe o no consumo en la propiedad. Este cobro financia gastos de comercialización, tales como lectura de medidor, emisión y distribución de la cuenta mensual entre otros.</p>
          <h2>9.- ¿A qué corresponde el cobro por uso de alcantarillado?</h2>
          <p>Corresponde al cobro por los servicios de Recolección y Disposición de Aguas Servidas. Se factura sobre la base del agua potable consumida.</p>
          <h2>10.- Respecto a las instalaciones sanitarias, ¿Cuál es el límite de responsabilidad entre Aguas Patagonia y el Cliente?</h2>
          <p>A la Empresa le corresponde efectuar la mantención y reparación de las instalaciones hasta la segunda llave de paso del medidor. Al cliente le corresponde la instalación domiciliaria interior, que es toda aquella existente desde la segunda llave de paso hasta el interior de la propiedad. En el caso de obstrucciones domiciliarias de alcantarillado, la responsabilidad de la Empresa es hasta la primera cámara de inspección.</p>
          <h2>11.- ¿A cuánto equivale un m3 de Agua Potable?</h2>
          <p>Un metro cúbico corresponde a 1.000 litros de agua.</p>
          <h2>12.- ¿Se puede utilizar el agua de grifos para regar?</h2>
          <p>No, este tipo de instalación sólo puede ser utilizada y manipulada por personal de la Empresa o por Bomberos para la extinción de incendios. Su manipulación es un delito.</p>
          <h2>13.- ¿Qué debo hacer para cambiar de ubicación del medidor de Agua Potable?</h2>
          <p>Se debe solicitar la autorización de reubicación ante la Empresa. Los costos asociados al cambio de ubicación de un medidor de agua potable son de exclusiva responsabilidad del cliente, para lo cual debe contratar a un gásfiter o contratista particular.</p>
        </div>
      </div>
    </Page >
  )
}

export default FAQ