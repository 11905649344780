import React, {
  // Suspense,
  Fragment,
  lazy,
  Suspense,
} from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Home from "./views/Home/Home";
import Company from "./views/Company/Company";
import Contact from "./views/Contact/Contact";
import Compliance from "./views/Compliance/Compliance";
import Denuncias from "./views/Denuncias/Denuncias";
import Shortage from "./views/Shortage/Shortage";
import Offices from "./views/Offices/Offices";
import Payment from "./views/Payment/Payment";
import Ticket from "./views/Ticket/Ticket";
import Rate from "./views/Rate/Rate";
import Customers from "./views/Customers/Customers";
import WorkWithUs from "./views/WorkWithUs/WorkWithUs";
import Sustainability from "./views/Sustainability/Sustainability";
import News from "./views/News/News";
import PaymentMethods from "./views/PaymentMethods/PaymentMethods";
import Areas from "./views/Areas/Areas";
import Projects from "./views/Projects/Projects";
import Services from "./views/Services/Services";
import Subsidios from "./views/Subsidios/Subsidios";
import Forms from "./views/Forms/Forms";
import APR from "./views/APR/APR";
import FAQ from "./views/FAQ/FAQ";
import Covid from "./views/Covid/Covid";
import Video from "./views/Video/Video";
import TbkFinal from "./views/Payment/TbkFinal";
import TbkReject from "./views/Payment/TbkReject"
import AuthGuard from "./components/Auth/AuthGuard";
import LoadingScreen from "./components/Loading/LoadingScreen";
import AdminLayout from "./components/AdminLayout";
import MotorHome from "./views/MotorHome/MotorHome";
import DumpingPoints from "./views/DumpingPoints/DumpingPoints";
import FondoConcursable from "./views/FondoConcursable/FondoConcursable";
import TbkNull from "./views/Payment/TbkNull";
import Covid21423 from "./views/Covid/Covid21423";
import Education from "./views/Education/Education";
import Factibilidad from "./views/Factibilidad/Factibilidad";
import Obras from "./views/Obras/Obras";
import HorariosFiestasPatrias from "./views/HorariosFiestasPatrias/HorariosFiestasPatrias"
import Winter from "./views/Winter/Winter";
import WinterMeasurement from "./views/WinterMeasurement/WinterMeasurement";
import Piping from "./views/Piping/Piping";

const routesConfig = [
  {
    id: 100,
    path: "/admin",
    guard: AuthGuard,
    layout: AdminLayout,
    routes: [
      {
        id: 101,
        exact: true,
        path: "/admin",
        component: () => <Redirect to="/admin/pagos" />,
      },
      {
        id: 102,
        exact: true,
        path: "/admin/administradores",
        component: lazy(() => import("./views/admin/admins/AdminListView")),
      },
      {
        id: 103,
        exact: true,
        path: "/admin/administradores/nuevo",
        component: lazy(() => import("./views/admin/admins/AdminCreateView")),
      },
      {
        id: 104,
        exact: true,
        path: "/admin/administradores/:adminId/editar",
        component: lazy(() => import("./views/admin/admins/AdminEditView")),
      },
      {
        id: 105,
        exact: true,
        path: "/admin/documentos",
        component: lazy(() => import("./views/admin/documents/DocumentListView")),
      },
      {
        id: 106,
        exact: true,
        path: "/admin/documentos/nuevo",
        component: lazy(() => import("./views/admin/documents/DocumentCreateView")),
      },
      {
        id: 107,
        exact: true,
        path: "/admin/documentos/:documentId/editar",
        component: lazy(() => import("./views/admin/documents/DocumentEditView")),
      },
      {
        id: 108,
        exact: true,
        path: "/admin/comunicados",
        component: lazy(() => import("./views/admin/reports/ReportListView")),
      },
      {
        id: 109,
        exact: true,
        path: "/admin/comunicados/nuevo",
        component: lazy(() => import("./views/admin/reports/ReportCreateView")),
      },
      {
        id: 110,
        exact: true,
        path: "/admin/comunicados/:reportId/editar",
        component: lazy(() => import("./views/admin/reports/ReportEditView")),
      },
      {
        id: 111,
        exact: true,
        path: "/admin/pagos",
        component: lazy(() => import("./views/admin/bills/BillListView")),
      },
      {
        id: 112,
        exact: true,
        path: "/admin/pagos/:billId/detalle",
        component: lazy(() => import("./views/admin/bills/BillDetailView")),
      },
      {
        id: 113,
        exact: true,
        path: "/admin/pagos/exportar",
        component: lazy(() => import("./views/admin/bills/BillExportView")),
      },
      {
        id: 114,
        exact: true,
        path: "/admin/educacion",
        component: lazy(() => import("./views/admin/education/EducationListView")),
      },
      {
        id: 115,
        exact: true,
        path: "/admin/educacion/nuevo",
        component: lazy(() => import("./views/admin/education/EducationCreateView")),
      },
      {
        id: 116,
        exact: true,
        path: "/admin/educacion/:documentId/editar",
        component: lazy(() => import("./views/admin/education/EducationEditView")),
      },
    ],
  },
  {
    id: 99,
    exact: true,
    path: "/login",
    component: lazy(() => import("./views/admin/Login/Login")),
  },
  {
    id: 1,
    exact: true,
    path: "/",
    component: () => <Home />,
  },
  {
    id: 2,
    exact: true,
    path: "/nosotros",
    component: () => <Company />,
  },
  {
    id: 3,
    exact: true,
    path: "/cortes",
    component: () => <Shortage />,
  },
  {
    id: 4,
    exact: true,
    path: "/contacto",
    component: () => <Contact />,
  },
  {
    id: 5,
    exact: true,
    path: "/oficinas",
    component: () => <Offices />,
  },
  {
    id: 6,
    exact: true,
    path: "/pago",
    component: () => <Payment />,
  },
  {
    id: 7,
    exact: true,
    path: "/boleta",
    component: () => <Ticket />,
  },
  {
    id: 8,
    exact: true,
    path: "/tarifas",
    component: () => <Rate />,
  },
  {
    id: 9,
    exact: true,
    path: "/clientes",
    component: () => <Customers />,
  },
  {
    id: 10,
    exact: true,
    path: "/trabaje-con-nosotros",
    component: () => <WorkWithUs />,
  },
  {
    id: 11,
    exact: true,
    path: "/sostenibilidad",
    component: () => <Sustainability />,
  },
  {
    id: 12,
    exact: true,
    path: "/marco-regulatorio",
    component: () => {
      window.open(
        "https://www.siss.gob.cl/586/w3-propertyvalue-6432.html",
        "_blank"
      );
      window.history.back();
      return null;
    },
  },
  {
    id: 13,
    exact: true,
    path: "/comunicados",
    component: () => <News />,
  },
  {
    id: 14,
    exact: true,
    path: "/lugares-y-medios-de-pago",
    component: () => <PaymentMethods />,
  },
  {
    id: 15,
    exact: true,
    path: "/areas-de-concesion",
    component: () => <Areas />,
  },
  {
    id: 16,
    exact: true,
    path: "/obras-y-proyectos",
    component: () => <Projects />,
  },
  {
    id: 17,
    exact: true,
    path: "/venta-de-agua-y-servicios",
    component: () => <Services />,
  },
  {
    id: 18,
    exact: true,
    path: "/subsidios",
    component: () => <Subsidios />,
  },
  {
    id: 19,
    exact: true,
    path: "/formularios",
    component: () => <Forms />,
  },
  {
    id: 20,
    exact: true,
    path: "/apr",
    component: () => <APR />,
  },
  {
    id: 21,
    exact: true,
    path: "/preguntas-frecuentes",
    component: () => <FAQ />,
  },
  {
    id: 22,
    exact: true,
    path: "/beneficios-covid-19",
    component: () => <Covid />,
  },
  {
    id: 23,
    exact: true,
    path: "/video",
    component: () => <Video />,
  },
  {
    id: 24,
    exact: true,
    path: '/tbk-final/:tbkPaymentId/:billId',
    component: () => <TbkFinal />,
  },
  {
    id: 25,
    exact: true,
    path: '/tbk-reject',
    component: () => <TbkReject />,
  },
  {
    id: 26,
    exact: true,
    path: '/puntodedescarga',
    component: () => <MotorHome />,
  },
  {
    id: 27,
    exact: true,
    path: '/motorhome',
    component: () => <DumpingPoints />,
  },
  {
    id: 28,
    exact: true,
    path: "/patagones-todos",
    component: () => <FondoConcursable />,
  },
  {
    id: 29,
    exact: true,
    path: '/tbk-null',
    component: () => <TbkNull />,
  },
  {
    id: 30,
    exact: true,
    path: '/ley-covid-21423',
    component: () => <Covid21423 />,
  },
  {
    id: 31,
    exact: true,
    path: '/educacion',
    component: () => <Education />,
  },
  {
    id: 32,
    exact: true,
    path: '/factibilidad',
    component: () => <Factibilidad />,
  },
  {
    id: 33,
    exact: true,
    path: '/obras',
    component: () => <Obras />,
  },
  {
    id: 34,
    exact: true,
    path: '/horarios-fiestas-patrias',
    component: () => <HorariosFiestasPatrias />,
  },
  {
    id: 35,
    exact: true,
    path: '/invierno',
    component: () => <Winter />,
  },
  {
    id: 36,
    exact: true,
    path: '/medidor',
    component: () => <WinterMeasurement />,
  },
  {
    id: 37,
    exact: true,
    path: '/tuberias',
    component: () => <Piping />,
  },
  {
    id: 38,
    exact: true,
    path: '/compliance',
    component: () => <Compliance />,
  },
  {
    id: 39,
    exact: true,
    path: '/denuncias',
    component: () => <Denuncias />,
  },
  {
    id: 0,
    component: () => <Home />,
  },
];

const renderRoutes = (routes) =>
  routes ? (
    <Suspense fallback={<LoadingScreen />}>
      <Switch>
        {routes.map((route) => {
          const Guard = route.guard || Fragment;
          const Component = route.component;
          const Layout = route.layout || Fragment;

          return (
            <Route
              key={route.id}
              path={route.path}
              exact={route.exact}
              render={(props) => (
                <Guard>
                  <Layout>
                    {route.routes ? (
                      renderRoutes(route.routes)
                    ) : (
                      <Component {...props} />
                    )}
                  </Layout>
                </Guard>
              )}
            />
          );
        })}
      </Switch>
    </Suspense>
  ) : null;

function Routes() {
  return renderRoutes(routesConfig);
}

export default Routes;
