import React from 'react'
import Page from '../../components/Layout/Page'
import './Offices.css'
import OfficesMap from '../../components/OfficesMap/OfficesMap'

function Offices() {
  return (
    <Page
      title="Aguas Patagonia | Oficinas y Horarios"
      current='offices'
    >
      <div className="offices-wrapper">
        <div className="offices-title-container">
          <div className='icon-container-offices'>
            <img src='/img/icon4.png' alt='icon'></img>
          </div>
          <h1>Oficinas y Horarios</h1>
        </div>
        {/* <div className="offices-images">
          <div className="office-img">
            <img src="https://aguas-patagonia-public-files.s3.sa-east-1.amazonaws.com/Horario_1_SI.png" alt="Coyhaique" />
          </div>
          <div className="office-img">
            <img src="https://aguas-patagonia-public-files.s3.sa-east-1.amazonaws.com/horario_2.png" alt="Coyhaique" />
          </div>
          <div className="office-img">
            <img src="https://aguas-patagonia-public-files.s3.sa-east-1.amazonaws.com/Horario_3.png" alt="Coyhaique" />
          </div>
        </div> */}
        <OfficesMap />
      </div>
    </Page>

  )
}

export default Offices