import React from 'react'
import Page from '../../components/Layout/Page'
import WorkWithUsForm from '../../components/WorkWithUsForm/WorkWithUsForm'
import './WorkWithUs.css';

function WorkWithUs() {
    return (
        <Page
            title="Aguas Patagonia | Trabaje con Nosotros"
            current='company'
        >
            <div className='work-with-us-wrapper'>
                <div className='icon-container-work-with-us'>
                    <img src='/img/icon6.png' alt='icon'></img>
                </div>
                <h1>Trabaje con Nosotros</h1>
                <p>Aguas Patagonia está interesado en contar con profesionales de calidad para desempeñarse en sus diferentes áreas. Te invitamos a enviarnos tus datos. Únete a nuestra empresa</p>
                <div className='work-with-us-form-wrapper'>
                    <WorkWithUsForm />
                </div>
            </div>
        </Page>
    )
}

export default WorkWithUs