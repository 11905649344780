import React, { useState, useEffect} from 'react'
import Page from '../../components/Layout/Page'
import './Company.css';
import axios from '../../utils/axios'
import {
  Box,
  FormHelperText
} from '@material-ui/core'

function Company() {
  const [years, setYears] = useState({
    memories: null,
    financial: null
  })
  const [memories, setMemories] = useState([])
  const [financial, setFinancial] = useState([])
  const [errors, setErrors] = useState({
    memories: null,
    financial: null
  })

  useEffect(() => {
    const getDocuments = async () => {
      const res = await axios.get('/api/documents')
      const response = res.data
      var memories = response.data.documents.filter(function (document) {
        return document.type === "Memoria" && document.url_file;
      }).sort((a, b) => {
        return a.year - b.year
      }).reverse();
      var financial = response.data.documents.filter(function (document) {
        return document.type === "Estado Financiero" && document.url_file;
      }).sort((a, b) => {
        return a.year - b.year
      }).reverse()
      setMemories(memories)
      setFinancial(financial)
    }
    getDocuments()
  }, [])

  const handleDropdownChange = (event) => {
    setErrors({ ...errors, [event.target.id]: null})
    setYears({ ...years, [event.target.id]: event.target.value })
  }

  const handleDownload = (docType) => {
    if(!docType || !years[docType]){
      setErrors({ ...errors, [docType]: "Tienes que seleccionar un año"})
    }
    else{
      window.open(`/api/download?fileType=${docType}&year=${years[docType]}`)
    }
  }

  // Plan de Desarrollo
  const [selectedDocument, setSelectedDocument] = useState({
    balmaceda: '',
    chileChico: '',
    cochrane: '',
    coyhaique: '',
    puertoAysen: '',
    puertoChacabuco: '',
    puertoCisnes: '',
    puertoIbanez: '',
  });

  const devPlan = {
    balmaceda: {
      anexos: [],
      cronograma: [{
        url: 'https://aguas-patagonia-public-files.s3.sa-east-1.amazonaws.com/plan-desarrollo/balmaceda/cronograma/2022_SC11-04_CB_Balmaceda.pdf',
        name: '2022 SC11-04 CB Balmaceda.pdf',
      }],
      fat: [{
        url: 'https://aguas-patagonia-public-files.s3.sa-east-1.amazonaws.com/plan-desarrollo/balmaceda/ficha-fat/SC11-04_FAT.pdf',
        name: 'SC11-04 FAT.pdf',
      }],
      planos: [{
        url: 'https://aguas-patagonia-public-files.s3.sa-east-1.amazonaws.com/plan-desarrollo/balmaceda/planos/SC11-04_Plano.pdf',
        name: 'SC11-04 Plano.pdf',
      }],
      pd: [{
        url: 'https://aguas-patagonia-public-files.s3.sa-east-1.amazonaws.com/plan-desarrollo/balmaceda/texto-pd/SC11-04_Texto_PD.pdf',
        name: 'SC11-04 Texto PD.pdf',
      }],
    },
    chileChico: {
      anexos: [],
      cronograma: [{
        url: 'https://aguas-patagonia-public-files.s3.sa-east-1.amazonaws.com/plan-desarrollo/chile-chico/cronograma/2022_SC11-06_CB_Chile_Chico.pdf',
        name: '2022 SC11-06 CB Chile Chico.pdf',
      }],
      fat: [{
        url: 'https://aguas-patagonia-public-files.s3.sa-east-1.amazonaws.com/plan-desarrollo/chile-chico/ficha-fat/SC11-06_FAT_Chile_Chico.pdf',
        name: 'SC11-06 FAT Chile Chico.pdf',
      }],
      planos: [{
        url: 'https://aguas-patagonia-public-files.s3.sa-east-1.amazonaws.com/plan-desarrollo/chile-chico/planos/SC11-06_Plano_TO_Chile_Chico.pdf',
        name: 'SC11-06 Plano TO Chile Chico.pdf',
      }],
      pd: [{
        url: 'https://aguas-patagonia-public-files.s3.sa-east-1.amazonaws.com/plan-desarrollo/chile-chico/texto-pd/SC11-06_Texto_PD_Chile_Chico.pdf',
        name: 'SC11-06 Texto PD Chile Chico',
      }],
    },
    cochrane: {
      anexos: [],
      cronograma: [{
        url: 'https://aguas-patagonia-public-files.s3.sa-east-1.amazonaws.com/plan-desarrollo/cochrane/cronograma/2022_SC_11-03_CB_Cochrane.pdf',
        name: '2022 SC11-03 CB Cochrane.pdf',
      }],
      fat: [{
        url: 'https://aguas-patagonia-public-files.s3.sa-east-1.amazonaws.com/plan-desarrollo/cochrane/ficha-fat/SC11-03_FAT_Cochrane.pdf',
        name: 'SC11-03 FAT Cochrane.pdf',
      }],
      planos: [{
        url: 'https://aguas-patagonia-public-files.s3.sa-east-1.amazonaws.com/plan-desarrollo/cochrane/planos/SC11-03_Plano.pdf',
        name: 'SC11-03 Plano.pdf',
      }],
      pd: [{
        url: 'https://aguas-patagonia-public-files.s3.sa-east-1.amazonaws.com/plan-desarrollo/cochrane/texto-pd/SC11-03_Texto_PD_Cochrane.pdf',
        name: 'SC11-03 Texto PD Cochrane.pdf',
      }],
    },
    coyhaique: {
      anexos: [],
      cronograma: [{
        url: 'https://aguas-patagonia-public-files.s3.sa-east-1.amazonaws.com/plan-desarrollo/coyhaique/cronograma/2021_SC11-01_CB.pdf',
        name: '2021 SC11-01 CB.pdf',
      }],
      fat: [{
        url: 'https://aguas-patagonia-public-files.s3.sa-east-1.amazonaws.com/plan-desarrollo/coyhaique/ficha-fat/SC11-01_FAT.pdf',
        name: 'SC11-01 FAT.pdf',
      }],
      planos: [{
        url: 'https://aguas-patagonia-public-files.s3.sa-east-1.amazonaws.com/plan-desarrollo/coyhaique/planos/1605-PD-C-TOP-01-01-Coyhaique.pdf',
        name: '1605-PD-C-TOP-01-01-Coyhaique.pdf',
      }],
      pd: [{
        url: 'https://aguas-patagonia-public-files.s3.sa-east-1.amazonaws.com/plan-desarrollo/coyhaique/texto-pd/SC_11-01_PD.pdf',
        name: 'SC 11-01 PD.pdf',
      }],
    },
    puertoAysen: {
      anexos: [],
      cronograma: [{
        url: 'https://aguas-patagonia-public-files.s3.sa-east-1.amazonaws.com/plan-desarrollo/puerto-aysen/cronograma/2022_SC11-02_CB_Pto_Ays%C3%A9n.pdf',
        name: '2022 SC11-02 CB Puerto Aysén.pdf',
      }],
      fat: [{
        url: 'https://aguas-patagonia-public-files.s3.sa-east-1.amazonaws.com/plan-desarrollo/puerto-aysen/ficha-fat/SC11-02_FAT.pdf',
        name: 'SC11-02 FAT.pdf',
      }],
      planos: [{
        url: 'https://aguas-patagonia-public-files.s3.sa-east-1.amazonaws.com/plan-desarrollo/puerto-aysen/planos/SC11-02+Plano.pdf',
        name: 'Plano Puerto Aysén.pdf',
      }],
      pd: [{
        url: 'https://aguas-patagonia-public-files.s3.sa-east-1.amazonaws.com/plan-desarrollo/puerto-aysen/texto-pd/SC11-02_Texto.pdf',
        name: 'SC11-02 Texto.pdf',
      }],
    },
    puertoChacabuco: {
      anexos: [],
      cronograma: [{
        url: 'https://aguas-patagonia-public-files.s3.sa-east-1.amazonaws.com/plan-desarrollo/puerto-chacabuco/cronograma/SC11-08+CB+Puerto+Chacabuco.pdf',
        name: 'SC11-08 CB Puerto Chacabuco.pdf',
      }],
      fat: [{
        url: 'https://aguas-patagonia-public-files.s3.sa-east-1.amazonaws.com/plan-desarrollo/puerto-chacabuco/cronograma/2022_SC11-08_FAT_Puerto_Chacabuco.pdf',
        name: '2022 SC11-08 FAT Puerto Chacabuco.pdf',
      }],
      planos: [{
        url: 'https://aguas-patagonia-public-files.s3.sa-east-1.amazonaws.com/plan-desarrollo/puerto-chacabuco/planos/SC11-08_Plano_TO_Puerto_Chacabuco.pdf',
        name: 'SC11-08 Plano TO Puerto Chacabuco.pdf',
      }],
      pd: [{
        url: 'https://aguas-patagonia-public-files.s3.sa-east-1.amazonaws.com/plan-desarrollo/puerto-chacabuco/texto-pd/SC11-08_Texto_PD_Puerto_Chacabuco.pdf',
        name: 'SC11-08 Texto PD Puerto Chacabuco.pdf',
      }],
    },
    puertoCisnes: {
      anexos: [],
      cronograma: [{
        url: 'https://aguas-patagonia-public-files.s3.sa-east-1.amazonaws.com/plan-desarrollo/puerto-cisnes/cronograma/2022_SC11-07_CB_Pto_Cisnes.pdf',
        name: '2022 SC11-07 CB Pto Cisnes.pdf',
      }],
      fat: [{
        url: 'https://aguas-patagonia-public-files.s3.sa-east-1.amazonaws.com/plan-desarrollo/puerto-cisnes/ficha-fat/SC11-07_FAT_Puerto_Cisnes_.pdf',
        name: 'SC11-07 FAT Puerto Cisnes.pdf',
      }],
      planos: [{
        url: 'https://aguas-patagonia-public-files.s3.sa-east-1.amazonaws.com/plan-desarrollo/puerto-cisnes/planos/SC11-07_Plano_TO_Puerto_Cisnes.pdf',
        name: 'SC11-07 Plano TO Puerto Cisnes.pdf',
      }],
      pd: [{
        url: 'https://aguas-patagonia-public-files.s3.sa-east-1.amazonaws.com/plan-desarrollo/puerto-cisnes/texto-pd/SC11-07_Texto_PD.pdf',
        name: 'SC11-07 Texto PD.pdf',
      }],
    },
    puertoIbanez: {
      anexos: [],
      cronograma: [{
        url: 'https://aguas-patagonia-public-files.s3.sa-east-1.amazonaws.com/plan-desarrollo/puerto-ibanez/cronograma/2022_SC11-05_CB_Pto_Iba%C3%B1ez_.pdf',
        name: '2022 SC11-05 CB Pto Ibañez.pdf',
      }],
      fat: [{
        url: 'https://aguas-patagonia-public-files.s3.sa-east-1.amazonaws.com/plan-desarrollo/puerto-ibanez/ficha-fat/SC11-05_FAT.pdf',
        name: 'SC11-05 FAT.pdf',
      }],
      planos: [{
        url: 'https://aguas-patagonia-public-files.s3.sa-east-1.amazonaws.com/plan-desarrollo/puerto-ibanez/planos/SC11-05_Plano_TO.pdf',
        name: 'SC11-05 Plano TO.pdf',
      }],
      pd: [{
        url: 'https://aguas-patagonia-public-files.s3.sa-east-1.amazonaws.com/plan-desarrollo/puerto-ibanez/texto-pd/SC11-05_Texto_PD_Puerto_Iba%C3%B1ez_.pdf',
        name: 'SC11-05 Texto PD Puerto Ibañez',
      }],
    },
  };

  const handleDocumentOptionChange = (e) => {
    setSelectedDocument({ ...selectedDocument, [e.target.id]: e.target.value, })
  }

  const handleDocumentDownload = (section) => {
    const url = selectedDocument[section];
    window.open(url)
  }

  return (
    <Page
      title="Aguas Patagonia | Nuestra Empresa"
      current='company'
    >
      <div className='company-container-wrapper'>
        <div className="text-wrapper-company">
            <h1>Nuestra Empresa</h1>
            <div className="company-photo-and-description">
              <img src="/img/quienes-somos.jpg" alt="nosotros" />
              <div id="quienes-somos" className="company-description">
                <h2>Quiénes Somos</h2>
                <p>Aguas Patagonia es una empresa regional de capitales chilenos que opera la concesión del Estado para la producción y distribución de agua potable, alcantarillado y tratamiento de aguas servidas en la Región de Aysén. Atiende a más de 24 mil clientes, los cuales representan una población superior a los 80 mil habitantes.</p>
                <p>Comprometida con el desarrollo y la sostenibilidad en una región tan extensa y de accidentada geografía, Aguas Patagonia pone el acento en la calidad de servicio y el compromiso con las comunidades y el medioambiente.</p>
                <p>Trabaja con 120 colaboradores y cuenta con 290 proveedores y contratistas, todos habitantes de la Patagonia chilena.</p>
              </div>
            </div>
            <div id="mision-vision" className="mision-vision-container">
              <div className="mision">
                <h2>Misión</h2>
                <p>
                  Ser reconocido como un excelente operador de
                  servicios sanitarios de Agua Potable y
                  Alcantarillado, eficiente, innovador y orientado al
                  cliente, con énfasis en el desarrollo de un equipo
                  humano de excelencia, participando activamente
                  en el crecimiento regional con un alto
                  compromiso social y ecológico.
                </p>
              </div>
              <div className="vision">
                <h2>Visión</h2>
                <p>
                  Aguas Patagonia es una Empresa comprometida
                  con la región, queriendo ser partícipe y artífice
                  del desarrollo de Aysén y sus habitantes.
                </p>
              </div>
            </div>
        </div>
        <div id="directorio" className='directory-wrapper'>
            <h2>Directorio</h2>
            <table className='directory-table'>
                <tr>
                    <th>CARGO</th>
                    <th>NOMBRE</th>
                    <th>PROFESIÓN</th>
                    <th>RUT</th>
                </tr>
                <tr>
                    <td>Presidente</td>
                    <td>Sergio Icaza Pérez</td>
                    <td>Ingeniero Civil</td>
                    <td>6.372.836-5</td>
                </tr>
                <tr>

                    <td>Vicepresidente</td>
                    <td>Juan Diéguez Manfredini</td>
                    <td>Ingeniero Civil</td>
                    <td>5.712.106-8</td>
                </tr>
                <tr>
                    <td>Director</td>
                    <td>Jorge Icaza Pérez</td>
                    <td>Ingeniero Civil</td>
                    <td>7.173.382-3</td>
                </tr>
                <tr>
                    <td>Director</td>
                    <td>Pelayo Santa María Muxica</td>
                    <td>Ingeniero Civil</td>
                    <td>14.578.268-6</td>
                </tr>
                <tr>
                    <td>Director</td>
                    <td>Guillermo Ruiz Donoso</td>
                    <td>Ingeniero Civil</td>
                    <td>18.396.777-0</td>
                </tr>
                <tr>
                    <td>Director</td>
                    <td>Matías Prato Castro</td>
                    <td>Ingeniero Comercial</td>
                    <td>18.395.777-5</td>
                </tr>
            </table>
        </div>
        <div id="organigrama" className='chart-container'>
            <h2>Organigrama</h2>
            <img src='https://aguas-patagonia-public-files.s3.sa-east-1.amazonaws.com/ORGANIGRAMA_WEB_APA.jpg' alt='organigrama'></img>
        </div>
        <div id="memorias-estados-financieros" className='data-container'>
            <div className='selector-container'>
                <h2>Memorias</h2>
                <div className="selector-content">
                  <div className="selector-and-error">
                    <select required value={years.memories} className="selector" id="memories" onChange={(e) => handleDropdownChange(e)}>
                        <option value="" selected disabled hidden>Seleccionar año</option>
                      {memories.map( mDocument => <option value={mDocument.year}>{mDocument.year}</option> )}
                    </select>
                    {errors.memories && <Box mt={3}>
                      <FormHelperText error>{errors.memories}</FormHelperText>
                    </Box>}
                  </div>
                <button className='go-button' onClick={() => handleDownload('memories')}>Descargar</button>
                </div>
            </div>
            <div className='selector-container'>
                <h2>Estados Financieros</h2>
                <div className="selector-content">
                  <div className="selector-and-error">
                  <select required value={years.financial} className="selector" id="financial" onChange={(e) => handleDropdownChange(e)}>
                      <option value="" selected disabled hidden>Seleccionar año</option>
                      {financial.map( fDocument => <option value={fDocument.year}>{fDocument.year}</option> )}
                  </select>
                  {errors.financial && <Box mt={3}>
                    <FormHelperText error>{errors.financial}</FormHelperText>
                  </Box>}
                  </div>
                  <button className='go-button' onClick={() => handleDownload('financial')}>Descargar</button>
                </div>
          </div>
        </div>
        <div id="plan-de-desarrollo" className="data-container-title">
          <h2>Plan de Desarrollo</h2>
        </div>
        <div className="data-container">
          <div className="selector-container">
            <h2>Balmaceda</h2>
            <div className="selector-content">
              <div className="selector-and-error">
                <select id="balmaceda" value={selectedDocument.balmaceda} className="selector" onChange={(e) => handleDocumentOptionChange(e)}>
                  <option value="" selected disabled hidden>Seleccionar Documento</option>
                  {devPlan.balmaceda.anexos.length > 0 ? (
                    <optgroup label="Anexos">
                      {devPlan.balmaceda.anexos.map(d => <option value={d.url}>{d.name}</option>)}
                    </optgroup>
                  ) : null}
                  {devPlan.balmaceda.cronograma.length > 0 ? (
                    <optgroup label="Cronograma Base">
                      {devPlan.balmaceda.cronograma.map(d => <option value={d.url}>{d.name}</option>)}
                    </optgroup>
                  ) : null}
                  {devPlan.balmaceda.fat.length > 0 ? (
                    <optgroup label="Ficha FAT">
                      {devPlan.balmaceda.fat.map(d => <option value={d.url}>{d.name}</option>)}
                    </optgroup>
                  ) : null}
                  {devPlan.balmaceda.planos.length > 0 ? (
                    <optgroup label="Planos">
                      {devPlan.balmaceda.planos.map(d => <option value={d.url}>{d.name}</option>)}
                    </optgroup>
                  ) : null}
                  {devPlan.balmaceda.pd.length > 0 ? (
                    <optgroup label="Texto PD">
                      {devPlan.balmaceda.pd.map(d => <option value={d.url}>{d.name}</option>)}
                    </optgroup>
                  ) : null}
                </select>
              </div>
              <button className='go-button' onClick={() => handleDocumentDownload('balmaceda')}>Descargar</button>
            </div>
          </div>
          <div className="selector-container">
            <h2>Chile Chico</h2>
            <div className="selector-content">
              <div className="selector-and-error">
                <select id="chileChico" value={selectedDocument.chileChico} className="selector" onChange={(e) => handleDocumentOptionChange(e)}>
                  <option value="" selected disabled hidden>Seleccionar Documento</option>
                  {devPlan.chileChico.anexos.length > 0 ? (
                    <optgroup label="Anexos">
                      {devPlan.chileChico.anexos.map(d => <option value={d.url}>{d.name}</option>)}
                    </optgroup>
                  ) : null}
                  {devPlan.chileChico.cronograma.length > 0 ? (
                    <optgroup label="Cronograma Base">
                      {devPlan.chileChico.cronograma.map(d => <option value={d.url}>{d.name}</option>)}
                    </optgroup>
                  ) : null}
                  {devPlan.chileChico.fat.length > 0 ? (
                    <optgroup label="Ficha FAT">
                      {devPlan.chileChico.fat.map(d => <option value={d.url}>{d.name}</option>)}
                    </optgroup>
                  ) : null}
                  {devPlan.chileChico.planos.length > 0 ? (
                    <optgroup label="Planos">
                      {devPlan.chileChico.planos.map(d => <option value={d.url}>{d.name}</option>)}
                    </optgroup>
                  ) : null}
                  {devPlan.chileChico.pd.length > 0 ? (
                    <optgroup label="Texto PD">
                      {devPlan.chileChico.pd.map(d => <option value={d.url}>{d.name}</option>)}
                    </optgroup>
                  ) : null}
                </select>
              </div>
              <button className='go-button' onClick={() => handleDocumentDownload('chileChico')}>Descargar</button>
            </div>
          </div>
        </div>
        <div className="data-container">
          <div className="selector-container">
            <h2>Cochrane</h2>
            <div className="selector-content">
              <div className="selector-and-error">
                <select id="cochrane" value={selectedDocument.cochrane} className="selector" onChange={(e) => handleDocumentOptionChange(e)}>
                  <option value="" selected disabled hidden>Seleccionar Documento</option>
                  {devPlan.cochrane.anexos.length > 0 ? (
                    <optgroup label="Anexos">
                      {devPlan.cochrane.anexos.map(d => <option value={d.url}>{d.name}</option>)}
                    </optgroup>
                  ) : null}
                  {devPlan.cochrane.cronograma.length > 0 ? (
                    <optgroup label="Cronograma Base">
                      {devPlan.cochrane.cronograma.map(d => <option value={d.url}>{d.name}</option>)}
                    </optgroup>
                  ) : null}
                  {devPlan.cochrane.fat.length > 0 ? (
                    <optgroup label="Ficha FAT">
                      {devPlan.cochrane.fat.map(d => <option value={d.url}>{d.name}</option>)}
                    </optgroup>
                  ) : null}
                  {devPlan.cochrane.planos.length > 0 ? (
                    <optgroup label="Planos">
                      {devPlan.cochrane.planos.map(d => <option value={d.url}>{d.name}</option>)}
                    </optgroup>
                  ) : null}
                  {devPlan.cochrane.pd.length > 0 ? (
                    <optgroup label="Texto PD">
                      {devPlan.cochrane.pd.map(d => <option value={d.url}>{d.name}</option>)}
                    </optgroup>
                  ) : null}
                </select>
              </div>
              <button className='go-button' onClick={() => handleDocumentDownload('cochrane')}>Descargar</button>
            </div>
          </div>
          <div className="selector-container">
            <h2>Coyhaique</h2>
            <div className="selector-content">
              <div className="selector-and-error">
                <select id="coyhaique" value={selectedDocument.coyhaique} className="selector" onChange={(e) => handleDocumentOptionChange(e)}>
                  <option value="" selected disabled hidden>Seleccionar Documento</option>
                  {devPlan.coyhaique.anexos.length > 0 ? (
                    <optgroup label="Anexos">
                      {devPlan.coyhaique.anexos.map(d => <option value={d.url}>{d.name}</option>)}
                    </optgroup>
                  ) : null}
                  {devPlan.coyhaique.cronograma.length > 0 ? (
                    <optgroup label="Cronograma Base">
                      {devPlan.coyhaique.cronograma.map(d => <option value={d.url}>{d.name}</option>)}
                    </optgroup>
                  ) : null}
                  {devPlan.coyhaique.fat.length > 0 ? (
                    <optgroup label="Ficha FAT">
                      {devPlan.coyhaique.fat.map(d => <option value={d.url}>{d.name}</option>)}
                    </optgroup>
                  ) : null}
                  {devPlan.coyhaique.planos.length > 0 ? (
                    <optgroup label="Planos">
                      {devPlan.coyhaique.planos.map(d => <option value={d.url}>{d.name}</option>)}
                    </optgroup>
                  ) : null}
                  {devPlan.coyhaique.pd.length > 0 ? (
                    <optgroup label="Texto PD">
                      {devPlan.coyhaique.pd.map(d => <option value={d.url}>{d.name}</option>)}
                    </optgroup>
                  ) : null}
                </select>
              </div>
              <button className='go-button' onClick={() => handleDocumentDownload('coyhaique')}>Descargar</button>
            </div>
          </div>
        </div>
        <div className="data-container">
          <div className="selector-container">
            <h2>Puerto Aysen</h2>
            <div className="selector-content">
              <div className="selector-and-error">
                <select id="puertoAysen" value={selectedDocument.puertoAysen} className="selector" onChange={(e) => handleDocumentOptionChange(e)}>
                  <option value="" selected disabled hidden>Seleccionar Documento</option>
                  {devPlan.puertoAysen.anexos.length > 0 ? (
                    <optgroup label="Anexos">
                      {devPlan.puertoAysen.anexos.map(d => <option value={d.url}>{d.name}</option>)}
                    </optgroup>
                  ) : null}
                  {devPlan.puertoAysen.cronograma.length > 0 ? (
                    <optgroup label="Cronograma Base">
                      {devPlan.puertoAysen.cronograma.map(d => <option value={d.url}>{d.name}</option>)}
                    </optgroup>
                  ) : null}
                  {devPlan.puertoAysen.fat.length > 0 ? (
                    <optgroup label="Ficha FAT">
                      {devPlan.puertoAysen.fat.map(d => <option value={d.url}>{d.name}</option>)}
                    </optgroup>
                  ) : null}
                  {devPlan.puertoAysen.planos.length > 0 ? (
                    <optgroup label="Planos">
                      {devPlan.puertoAysen.planos.map(d => <option value={d.url}>{d.name}</option>)}
                    </optgroup>
                  ) : null}
                  {devPlan.puertoAysen.pd.length > 0 ? (
                    <optgroup label="Texto PD">
                      {devPlan.puertoAysen.pd.map(d => <option value={d.url}>{d.name}</option>)}
                    </optgroup>
                  ) : null}
                </select>
              </div>
              <button className='go-button' onClick={() => handleDocumentDownload('puertoAysen')}>Descargar</button>
            </div>
          </div>
          <div className="selector-container">
            <h2>Puerto Chacabuco</h2>
            <div className="selector-content">
              <div className="selector-and-error">
                <select id="puertoChacabuco" value={selectedDocument.puertoChacabuco} className="selector" onChange={(e) => handleDocumentOptionChange(e)}>
                  <option value="" selected disabled hidden>Seleccionar Documento</option>
                  {devPlan.puertoChacabuco.anexos.length > 0 ? (
                    <optgroup label="Anexos">
                      {devPlan.puertoChacabuco.anexos.map(d => <option value={d.url}>{d.name}</option>)}
                    </optgroup>
                  ) : null}
                  {devPlan.puertoChacabuco.cronograma.length > 0 ? (
                    <optgroup label="Cronograma Base">
                      {devPlan.puertoChacabuco.cronograma.map(d => <option value={d.url}>{d.name}</option>)}
                    </optgroup>
                  ) : null}
                  {devPlan.puertoChacabuco.fat.length > 0 ? (
                    <optgroup label="Ficha FAT">
                      {devPlan.puertoChacabuco.fat.map(d => <option value={d.url}>{d.name}</option>)}
                    </optgroup>
                  ) : null}
                  {devPlan.puertoChacabuco.planos.length > 0 ? (
                    <optgroup label="Planos">
                      {devPlan.puertoChacabuco.planos.map(d => <option value={d.url}>{d.name}</option>)}
                    </optgroup>
                  ) : null}
                  {devPlan.puertoChacabuco.pd.length > 0 ? (
                    <optgroup label="Texto PD">
                      {devPlan.puertoChacabuco.pd.map(d => <option value={d.url}>{d.name}</option>)}
                    </optgroup>
                  ) : null}
                </select>
              </div>
              <button className='go-button' onClick={() => handleDocumentDownload('puertoChacabuco')}>Descargar</button>
            </div>
          </div>
        </div>
        <div className="data-container">
          <div className="selector-container">
            <h2>Puerto Cisnes</h2>
            <div className="selector-content">
              <div className="selector-and-error">
                <select id="puertoCisnes" value={selectedDocument.puertoCisnes} className="selector" onChange={(e) => handleDocumentOptionChange(e)}>
                  <option value="" selected disabled hidden>Seleccionar Documento</option>
                  {devPlan.puertoCisnes.anexos.length > 0 ? (
                    <optgroup label="Anexos">
                      {devPlan.puertoCisnes.anexos.map(d => <option value={d.url}>{d.name}</option>)}
                    </optgroup>
                  ) : null}
                  {devPlan.puertoCisnes.cronograma.length > 0 ? (
                    <optgroup label="Cronograma Base">
                      {devPlan.puertoCisnes.cronograma.map(d => <option value={d.url}>{d.name}</option>)}
                    </optgroup>
                  ) : null}
                  {devPlan.puertoCisnes.fat.length > 0 ? (
                    <optgroup label="Ficha FAT">
                      {devPlan.puertoCisnes.fat.map(d => <option value={d.url}>{d.name}</option>)}
                    </optgroup>
                  ) : null}
                  {devPlan.puertoCisnes.planos.length > 0 ? (
                    <optgroup label="Planos">
                      {devPlan.puertoCisnes.planos.map(d => <option value={d.url}>{d.name}</option>)}
                    </optgroup>
                  ) : null}
                  {devPlan.puertoCisnes.pd.length > 0 ? (
                    <optgroup label="Texto PD">
                      {devPlan.puertoCisnes.pd.map(d => <option value={d.url}>{d.name}</option>)}
                    </optgroup>
                  ) : null}
                </select>
              </div>
              <button className='go-button' onClick={() => handleDocumentDownload('puertoCisnes')}>Descargar</button>
            </div>
          </div>
          <div className="selector-container">
            <h2>Puerto Ibañez</h2>
            <div className="selector-content">
              <div className="selector-and-error">
                <select id="puertoIbanez" value={selectedDocument.puertoIbanez} className="selector" onChange={(e) => handleDocumentOptionChange(e)}>
                  <option value="" selected disabled hidden>Seleccionar Documento</option>
                  {devPlan.puertoIbanez.anexos.length > 0 ? (
                    <optgroup label="Anexos">
                      {devPlan.puertoIbanez.anexos.map(d => <option value={d.url}>{d.name}</option>)}
                    </optgroup>
                  ) : null}
                  {devPlan.puertoIbanez.cronograma.length > 0 ? (
                    <optgroup label="Cronograma Base">
                      {devPlan.puertoIbanez.cronograma.map(d => <option value={d.url}>{d.name}</option>)}
                    </optgroup>
                  ) : null}
                  {devPlan.puertoIbanez.fat.length > 0 ? (
                    <optgroup label="Ficha FAT">
                      {devPlan.puertoIbanez.fat.map(d => <option value={d.url}>{d.name}</option>)}
                    </optgroup>
                  ) : null}
                  {devPlan.puertoIbanez.planos.length > 0 ? (
                    <optgroup label="Planos">
                      {devPlan.puertoIbanez.planos.map(d => <option value={d.url}>{d.name}</option>)}
                    </optgroup>
                  ) : null}
                  {devPlan.puertoIbanez.pd.length > 0 ? (
                    <optgroup label="Texto PD">
                      {devPlan.puertoIbanez.pd.map(d => <option value={d.url}>{d.name}</option>)}
                    </optgroup>
                  ) : null}
                </select>
              </div>
              <button className='go-button' onClick={() => handleDocumentDownload('puertoIbanez')}>Descargar</button>
            </div>
          </div>
        </div>
      </div>
    </Page>

  )
}

export default Company