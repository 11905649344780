import React, { useState } from 'react'
import { Link } from 'react-router-dom';

function NavBarMobile({ current }) {
  const [openMenu, setOpenMenu] = useState(false)
  return (
    <div>
      <div className="navbar-top-mobile">
        <div className="navbar-bottom-mobile-logo">
          <a href="/"><img src="/img/logo-aguas.png" alt="logo" /></a>
        </div>
        <div className="main-mobile-button payment-nav-button">
          <button
            type="button"
            onClick={() => { window.location.href = '/pago'; }}
            className="blue-button"
          >
            PAGA AQUÍ
          </button>
        </div>
        <div
          onClick={() => setOpenMenu(!openMenu)}
          className="burger-button"
        >
          <div />
          <div />
          <div />
        </div>
        <div className={"burger-menu" + (!openMenu ? " hidden-burger" : "")}>
          <div className="menu-main-mobile-button payment-nav-button">
            <button
              type="button"
              onClick={() => { window.location.href = '/pago'; }}
              className="blue-button"
            >
              PAGA AQUÍ
            </button>
            <button
              type="button"
              onClick={() => { window.location.href = '/oficinas'; }}
              className="blue-button"
            >
              OFICINAS Y HORARIOS
            </button>
            <button
              type="button"
              onClick={() => { window.location.href = '/boleta'; }}
              className="blue-button"
            >
              MI BOLETA
            </button>
          </div>
          <div className="burger-menu-tabs">
            
            <Link to="/">
              Inicio
            </Link>
            <Link to="/nosotros">
              Nuestra Empresa
            </Link>
            <Link to="/clientes">
              Clientes
            </Link>
            <Link to="/sostenibilidad">
              Sostenibilidad
            </Link>
            <Link to="/educacion">
              Información Educativa
            </Link>
            <Link to="/contacto">
              Contacto
            </Link>
            {/* <a href="https://aguas-patagonia-public-files.s3-sa-east-1.amazonaws.com/Concurso-de-dibujo-Dia-mundial-del-Agua.pdf" target="_blank" rel="noopener noreferrer">
              <a>Dia del Agua 2021</a>
            </a> */}
          </div>
        </div>
        <div
          className={!openMenu ? "hidden-backdrop" : "backdrop"}
          onClick={() => setOpenMenu(false)}
        />
      </div>
      {/* <div className="navbar-bottom-mobile">
        <div className="navbar-bottom-second">
          <p>Infórmate del beneficio Covid-19 Ley 21.304, prórroga de Ley 21.249 haciendo <a href="/beneficios-covid-19">Click Aquí</a> Revisa Ley 21.423 de Subsidio a los servicios básicos haciendo <a href="/ley-covid-21423">Click Aquí</a></p>
        </div>
      </div> */}
    </div>
  )
}

export default NavBarMobile