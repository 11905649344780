import React, { useState, useRef } from 'react';

import axios from 'axios';

import Page from '../../components/Layout/Page';
import './Denuncias.css';

import { 
  parseDenunciaRelacion,
  parseDenunciaLugar,
  parseDenunciaType,
  parseDenunciaContactTime,
  parseDenunciaTime,
  parseDenunciaKnowledge,
  parseDenunciaYesNo,
} from '../../utils/denuncias';
import { validateEmail } from '../../utils/validator';

function Denuncias() {
  const [page, setPage] = useState(1);

  const [delitosOpen, setDelitosOpen] = useState(false);
  const [eticaOpen, setEticaOpen] = useState(false);
  const [acosoLaboralOpen, setAcosoLaboralOpen] = useState(false);
  const [acosoSexualOpen, setAcosoSexualOpen] = useState(false);
  const [violenciaTercerosOpen, setViolenciaTercerosOpen] = useState(false);
  const [consultasOpen, setConsultasOpen] = useState(false);

  const [denunciaRelacion, setDenunciaRelacion] = useState(null);
  const [denunciaRelacionText, setDenunciaRelacionText] = useState(null);

  const [denunciaLugar, setDenunciaLugar] = useState(null);
  const [denunciaLugarText, setDenunciaLugarText] = useState(null);

  const [denunciaType, setDenunciaType] = useState(null);

  const [name, setName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phone, setPhone] = useState('');
  const [contactTime, setContactTime] = useState(null);
  const [email, setEmail] = useState('');

  const [denunciaDate, setDenunciaDate] = useState('');
  const [denunciaTime, setDenunciaTime] = useState(null);
  const [denunciaPlace, setDenunciaPlace] = useState('');
  const [denunciaKnowledge, setDenunciaKnowledge] = useState(null);
  const [denunciaKnowledgeText, setDenunciaKnowledgeText] = useState(null);
  const [denunciaIdentification, setDenunciaIdentification] = useState(null);
  const [denunciaCoverup, setDenunciaCoverup] = useState(null);
  const [denunciaAuthority, setDenunciaAuthority] = useState(null);
  const fileInputRef = useRef(null);
  const [denunciaFile, setDenunciaFile] = useState(null);
  const [denunciaText, setDenunciaText] = useState('');

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const onDenunciaRelacionChange = (e) => {
    setDenunciaRelacion(e.target.value);
  };

  const onDenunciaRelacionTextChange = (e) => {
    if (e.target.value.length < 80) {
      setDenunciaRelacionText(e.target.value);
    }
  };

  const onDenunciaLugarChange = (e) => {
    setDenunciaLugar(e.target.value);
  };

  const onDenunciaLugarTextChange = (e) => {
    if (e.target.value.length < 80) {
      setDenunciaLugarText(e.target.value);
    }
  };

  const onDenunciaRadioChange = (e) => {
    setDenunciaType(e.target.value);
  };

  const onNameChange = (e) => {
    if (e.target.value.length < 50) {
      setName(e.target.value);
    }
  };

  const onLastNameChange = (e) => {
    if (e.target.value.length < 50) {
      setLastName(e.target.value);
    }
  };

  const onPhoneChange = (e) => {
    if (e.target.value.length < 20) {
      setPhone(e.target.value);
    }
  };

  const onContactTimeChange = (e) => {
    setContactTime(e.target.value);
  };

  const onEmailChange = (e) => {
    if (e.target.value.length < 80) {
      setEmail(e.target.value);
    }
  };

  const onDenunciaDateChange = (e) => {
    if (e.target.value.length < 100) {
      setDenunciaDate(e.target.value);
    }
  };

  const onDenunciaTimeChange = (e) => {
    setDenunciaTime(e.target.value);
  };

  const onDenunciaPlaceChange = (e) => {
    if (e.target.value.length < 100) {
      setDenunciaPlace(e.target.value);
    }
  };

  const onDenunciaKnowledgeChange = (e) => {
    setDenunciaKnowledge(e.target.value);
  };

  const onDenunciaKnowledgeTextChange = (e) => {
    if (e.target.value.length < 80) {
      setDenunciaKnowledgeText(e.target.value);
    }
  };

  const onDenunciaIdentificationChange = (e) => {
    setDenunciaIdentification(e.target.value);
  };

  const onDenunciaCoverupChange = (e) => {
    setDenunciaCoverup(e.target.value);
  };

  const onDenunciaAuthorityChange = (e) => {
    setDenunciaAuthority(e.target.value);
  };

  const onDenunciaFileChange = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setDenunciaFile(file);
    }
  };

  const onDenunciaTextChange = (e) => {
    if (e.target.value.length < 300) {
      setDenunciaText(e.target.value);
    }
  };

  const handleSubmit = async () => {
    setLoading(true);
    setError(null);

    if (!validateEmail(email)) {
      setError('Por favor ingrese un correo electrónico válido.');
      return;
    }

    if (denunciaFile) {
      if (denunciaFile.size > 10000000) {
        setError('El tamaño máximo del archivo a subir es de 10 MB');
        return;
      }
    }

    const formData = new FormData();

    formData.append('denunciaRelacion', parseDenunciaRelacion(denunciaRelacion));
    formData.append('denunciaRelacionText', denunciaRelacionText);
    formData.append('denunciaLugar', parseDenunciaLugar(denunciaLugar));
    formData.append('denunciaLugarText', denunciaLugarText);
    formData.append('denunciaType', parseDenunciaType(denunciaType));

    formData.append('name', name);
    formData.append('lastName', lastName);
    formData.append('phone', phone);
    formData.append('contactTime', parseDenunciaContactTime(contactTime));
    formData.append('email', email);

    formData.append('denunciaDate', denunciaDate);
    formData.append('denunciaTime', parseDenunciaTime(denunciaTime));
    formData.append('denunciaPlace', denunciaPlace);
    formData.append('denunciaKnowledge', parseDenunciaKnowledge(denunciaKnowledge));
    formData.append('denunciaKnowledgeText', denunciaKnowledgeText);
    formData.append('denunciaIdentification', parseDenunciaYesNo(denunciaIdentification));
    formData.append('denunciaCoverup', parseDenunciaYesNo(denunciaCoverup));
    formData.append('denunciaAuthority', parseDenunciaYesNo(denunciaAuthority));

    if (denunciaFile) {
      formData.append('file', denunciaFile);
    }

    formData.append('denunciaText', denunciaText);

    try {
      const response = await axios.post('/api/denuncias', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      setLoading(false);

      if (response.data.success) {
        window.location.href = '/compliance';
      } else {
        setError('Ha ocurrido un error. Por favor intente nuevamente más tarde.');
      }
    } catch (err) {
      setError(err.response && err.response.data ? err.response.data.error : 'Ha ocurrido un error. Por favor intente nuevamente más tarde.');
    }
  };

  ////////////////////
  /////  PAGES   /////
  ////////////////////

  const denunciasPageOne = () => {
    return (
      <div>
        <h1>Ingresar Denuncia</h1>
        <h2>Preguntas iniciales</h2>
        <ol>
          <li>
            <p>¿Cuál es su relación con Aguas Patagonia de Aysén S.A?</p>
            <div className="denuncias-item-radio">
              <input
                type="radio"
                id="relacion-trabajador"
                name="relacion"
                value="trabajador"
                checked={denunciaRelacion === "trabajador"}
                onChange={onDenunciaRelacionChange}
              />
              <label htmlFor="relacion-trabajador">Trabajador de Aguas Patagonia</label>
            </div>
            <div className="denuncias-item-radio">
              <input
                type="radio"
                id="relacion-contratista"
                name="relacion"
                value="contratista"
                checked={denunciaRelacion === "contratista"}
                onChange={onDenunciaRelacionChange}
              />
              <label htmlFor="relacion-contratista">Contratista de Aguas Patagonia</label>
            </div>
            <div className="denuncias-item-radio">
              <input
                type="radio"
                id="relacion-cliente"
                name="relacion"
                value="cliente"
                checked={denunciaRelacion === "cliente"}
                onChange={onDenunciaRelacionChange}
              />
              <label htmlFor="relacion-cliente">Cliente de Aguas Patagonia</label>
            </div>
            <div className="denuncias-item-radio">
              <input
                type="radio"
                id="relacion-otro"
                name="relacion"
                value="otro"
                checked={denunciaRelacion === "otro"}
                onChange={onDenunciaRelacionChange}
              />
              <label htmlFor="relacion-otro">Otro</label>
            </div>
            {denunciaRelacion === "otro" ? (<div>
              <input
                id="relacion-otro-text"
                className="denuncias-information-input"
                type="text"
                placeholder="Especifique aquí"
                value={denunciaRelacionText}
                onChange={onDenunciaRelacionTextChange}
              />
            </div>) : null}
          </li>
          <li>
            <p>Lugar donde sucedió el incidente:</p>
            <div className="denuncias-item-radio">
              <input
                type="radio"
                id="lugar-oficinas-corporativas"
                name="lugar"
                value="oficinas-corporativas"
                checked={denunciaLugar === "oficinas-corporativas"}
                onChange={onDenunciaLugarChange}
              />
              <label htmlFor="lugar-oficinas-corporativas">Oficinas Corporativas</label>
            </div>
            <div className="denuncias-item-radio">
              <input
                type="radio"
                id="lugar-oficinas-zonales"
                name="lugar"
                value="oficinas-zonales"
                checked={denunciaLugar === "oficinas-zonales"}
                onChange={onDenunciaLugarChange}
              />
              <label htmlFor="lugar-oficinas-zonales">Oficinas Zonales</label>
            </div>
            <div className="denuncias-item-radio">
              <input
                type="radio"
                id="lugar-plantas"
                name="lugar"
                value="plantas"
                checked={denunciaLugar === "plantas"}
                onChange={onDenunciaLugarChange}
              />
              <label htmlFor="lugar-plantas">Plantas</label>
            </div>
            <div className="denuncias-item-radio">
              <input
                type="radio"
                id="lugar-recintos"
                name="lugar"
                value="recintos"
                checked={denunciaLugar === "recintos"}
                onChange={onDenunciaLugarChange}
              />
              <label htmlFor="lugar-recintos">Recintos</label>
            </div>
            <div className="denuncias-item-radio">
              <input
                type="radio"
                id="lugar-otro"
                name="lugar"
                value="otro"
                checked={denunciaLugar === "otro"}
                onChange={onDenunciaLugarChange}
              />
              <label htmlFor="lugar-otro">Ninguna de las anteriores</label>
            </div>
            {denunciaLugar === "otro" ? (<div>
              <input
                id="lugar-otro-text"
                className="denuncias-information-input"
                type="text"
                placeholder="¿Dónde?"
                value={denunciaLugarText}
                onChange={onDenunciaLugarTextChange}
              />
            </div>) : null}
          </li>
        </ol>
        <br />
        <h2>Identificación del tipo de denuncia</h2>
        <p className="p-small">Por favor, seleccione en la siguiente lista el tipo que mejor represente su caso.</p>
        <p className="p-small">Para mas detalles sobre el tipo de denuncia, pinche en "+info".</p>
        <p>I. Delitos Modelo de Prevención del delito y Ley N° 20.393 (Responsabilidad Penal de la Empresa)</p>
        <div className="denuncias-item-radio">
          <input
            type="radio"
            id="denuncias-soborno-funcionarios-publicos"
            name="denuncias"
            value="soborno-funcionarios-publicos"
            checked={denunciaType === "soborno-funcionarios-publicos"}
            onChange={onDenunciaRadioChange}
          />
          <label htmlFor="denuncias-soborno-funcionarios-publicos">Soborno a funcionarios públicos</label>
        </div>
        <div className="denuncias-item-radio">
          <input
            type="radio"
            id="denuncias-contaminacion-aguas"
            name="denuncias"
            value="contaminacion-aguas"
            checked={denunciaType === "contaminacion-aguas"}
            onChange={onDenunciaRadioChange}
          />
          <label htmlFor="denuncias-contaminacion-aguas">Contaminación de Aguas</label>
        </div>
        <div className="denuncias-item-radio">
          <input
            type="radio"
            id="denuncias-soborno-particulares"
            name="denuncias"
            value="soborno-particulares"
            checked={denunciaType === "soborno-particulares"}
            onChange={onDenunciaRadioChange}
          />
          <label htmlFor="denuncias-soborno-particulares">Soborno entre particulares</label>
        </div>
        <div className="denuncias-item-radio">
          <input
            type="radio"
            id="denuncias-delitos-ley-sociedades-anonimas"
            name="denuncias"
            value="delitos-ley-sociedades-anonimas"
            checked={denunciaType === "delitos-ley-sociedades-anonimas"}
            onChange={onDenunciaRadioChange}
          />
          <label htmlFor="denuncias-delitos-ley-sociedades-anonimas">Delitos Ley de Sociedades Anónimas</label>
        </div>
        <div className="denuncias-item-radio">
          <input
            type="radio"
            id="denuncias-delitos-informaticos"
            name="denuncias"
            value="delitos-informaticos"
            checked={denunciaType === "delitos-informaticos"}
            onChange={onDenunciaRadioChange}
          />
          <label htmlFor="denuncias-delitos-informaticos">Delitos Informáticos</label>
        </div>
        <div className="denuncias-item-radio">
          <input
            type="radio"
            id="denuncias-receptacion"
            name="denuncias"
            value="receptacion"
            checked={denunciaType === "receptacion"}
            onChange={onDenunciaRadioChange}
          />
          <label htmlFor="denuncias-receptacion">Receptación</label>
        </div>
        <div className="denuncias-item-radio">
          <input
            type="radio"
            id="denuncias-falsificacion-documentos"
            name="denuncias"
            value="falsificacion-documentos"
            checked={denunciaType === "falsificacion-documentos"}
            onChange={onDenunciaRadioChange}
          />
          <label htmlFor="denuncias-falsificacion-documentos">Falsificación de documentos</label>
        </div>
        <div className="denuncias-item-radio">
          <input
            type="radio"
            id="denuncias-delitos-ley-sea"
            name="denuncias"
            value="delitos-ley-sea"
            checked={denunciaType === "delitos-ley-sea"}
            onChange={onDenunciaRadioChange}
          />
          <label htmlFor="denuncias-delitos-ley-sea">Delitos Ley SEA</label>
        </div>
        <div className="denuncias-item-radio">
          <input
            type="radio"
            id="denuncias-residuos-peligrosos"
            name="denuncias"
            value="residuos-peligrosos"
            checked={denunciaType === "residuos-peligrosos"}
            onChange={onDenunciaRadioChange}
          />
          <label htmlFor="denuncias-residuos-peligrosos">Residuos Peligrosos</label>
        </div>
        <div className="denuncias-item-radio">
          <input
            type="radio"
            id="denuncias-delitos-aduaneros"
            name="denuncias"
            value="delitos-aduaneros"
            checked={denunciaType === "delitos-aduaneros"}
            onChange={onDenunciaRadioChange}
          />
          <label htmlFor="denuncias-delitos-aduaneros">Delitos Aduaneros</label>
        </div>
        <div className="denuncias-item-radio">
          <input
            type="radio"
            id="denuncias-administracion-desleal"
            name="denuncias"
            value="administracion-desleal"
            checked={denunciaType === "administracion-desleal"}
            onChange={onDenunciaRadioChange}
          />
          <label htmlFor="denuncias-administracion-desleal">Administración Desleal</label>
        </div>
        <div className="denuncias-item-radio">
          <input
            type="radio"
            id="denuncias-apropiacion-indebida"
            name="denuncias"
            value="apropiacion-indebida"
            checked={denunciaType === "apropiacion-indebida"}
            onChange={onDenunciaRadioChange}
          />
          <label htmlFor="denuncias-apropiacion-indebida">Apropiación Indebida</label>
        </div>
        <div className="denuncias-item-radio">
          <input
            type="radio"
            id="denuncias-lavado-activos"
            name="denuncias"
            value="lavado-activos"
            checked={denunciaType === "lavado-activos"}
            onChange={onDenunciaRadioChange}
          />
          <label htmlFor="denuncias-lavado-activos">Lavado de activos</label>
        </div>
        <div className="denuncias-item-radio">
          <input
            type="radio"
            id="denuncias-info-privilegiada"
            name="denuncias"
            value="info-privilegiada"
            checked={denunciaType === "info-privilegiada"}
            onChange={onDenunciaRadioChange}
          />
          <label htmlFor="denuncias-info-privilegiada">Uso Información privilegiada</label>
        </div>
        <div className="denuncias-item-radio">
          <input
            type="radio"
            id="denuncias-delitos-salud-publica"
            name="denuncias"
            value="delitos-salud-publica"
            checked={denunciaType === "delitos-salud-publica"}
            onChange={onDenunciaRadioChange}
          />
          <label htmlFor="denuncias-delitos-salud-publica">Delitos Salud Pública (Envenenamiento Agua potable)</label>
        </div>
        <div className="denuncias-item-radio">
          <input
            type="radio"
            id="denuncias-incumplimiento-normas-sanitarias"
            name="denuncias"
            value="incumplimiento-normas-sanitarias"
            checked={denunciaType === "incumplimiento-normas-sanitarias"}
            onChange={onDenunciaRadioChange}
          />
          <label htmlFor="denuncias-incumplimiento-normas-sanitarias">Ordenar concurrir al lugar de trabajo en incumplimiento de las normas sanitarias</label>
        </div>
        <div className="denuncias-item-row">
          <div className="denuncias-item">
            <div className="denuncias-item-radio">
              <input
                type="radio"
                id="denuncias-negociacion-incompatible"
                name="denuncias"
                value="negociacion-incompatible"
                checked={denunciaType === "negociacion-incompatible"}
                onChange={onDenunciaRadioChange}
              />
              <label htmlFor="denuncias-negociacion-incompatible">Negociación Incompatible</label>
            </div>
            <div className="denuncias-item-info" onClick={() => setDelitosOpen(!delitosOpen)}>{delitosOpen ? '-' : '+'} info</div>
          </div>
          {delitosOpen ? (
            <div className="denuncias-content">
              <span>Soborno a funcionarios Públicos:</span>
              <br />
              <span>Descripción:</span> Es el acto de entregar, ofrecer, consentir en dar, solicitar o aceptar recibir un
              beneficio económico o de otra naturaleza, para sí o un tercero, para que favorezca o por
              haber favorecido la contratación con un oferente por sobre otro en el marco de un proceso
              de licitación o contratación.
              <br />
              <span>Ejemplo:</span> Empleado o mandatario ofrece un beneficio para obtener la adjudicación de un
              contrato en el marco de un proceso de licitación.
              <br/>
              <br/>
              <span>Contaminación de Aguas:</span>
              <br/>
              <span>Descripción:</span> Se refiere al acto de introducir o mandar introducir en el mar, ríos, lagos o
              cualquier otro cuerpo de agua, agentes contaminantes químicos, biológicos o físicos que
              causen daño a los recursos hidrobiológicos, sin autorización, o contraviniendo sus
              condiciones o infringiendo la normativa aplicable.
              <br/>
              <span>Ejemplo:</span> Empleado o mandatario introduce agentes contaminantes en el mar en
              contravención a la normativa, y como consecuencia de ello causa daño a los recursos
              hidrobiológicos.
              <br/>
              <br/>
              <span>Soborno entre Particulares:</span>
              <br/>
              <span>Descripción:</span> Es el acto de entregar, ofrecer, consentir en dar, solicitar o aceptar recibir un
              beneficio económico o de otra naturaleza, para sí o un tercero, para que favorezca o por
              haber favorecido la contratación con un oferente por sobre otro en el marco de un proceso
              de licitación o contratación.
              <br/>
              <span>Ejemplo:</span> Empleado o mandatario ofrece un beneficio para obtener la adjudicación de un
              contrato en el marco de un proceso de licitación.
              <br/>
              <br/>
              <span>Lavado de Activos:</span>
              <br/>
              <span>Descripción:</span> Cualquier acto que implique ocultar o disimular el origen ilícito de
              determinados bienes, ocultar o disimular dichos bienes, teniendo conocimiento de que
              provienen, directa o indirectamente, de la perpetración de delitos, así como también
              cualquier acto que implique adquirir, poseer, tener o usar los referidos bienes, con ánimo
              de lucro, cuando al momento de recibirlos ha conocido su origen ilícito.
              <br/>
              <span>Ejemplo:</span> Contratar a un proveedor que obtiene sus ingresos de actividades ilícitas para
              que de esa manera pueda ocultar el origen ilícito de sus ingresos y generar una apariencia
              de licitud respecto de ellos.
              <br/>
              <br/>
              <span>Financiamiento del Terrorismo:</span>
              <br/>
              <span>Descripción:</span> Cualquier acto que implique solicitar, recaudar o proveer fondos con la
              finalidad de que se utilicen en la comisión de cualquier de los delitos terroristas señalados
              en la Ley 18.314 (secuestros, homicidios, instalación de artefactos explosivos, entre
              otros).
              <br/>
              <span>Ejemplo:</span> Se necesita comprar material con urgencia y no existe stock, por lo que se
              compra a nuevos proveedores sin saber el origen y que estos sean calificados como
              terroristas. Pueden ser proveedores nacionales e internacionales.
              <br/>
              <br/>
              <span>Soborno a funcionarios públicos nacionales o extranjeros:</span>
              <br/>
              <span>Descripción:</span> Oferta, pago o promesa, ya sea de forma directa o indirecta, de dar un
              beneficio económico a un funcionario público chileno o extranjero con el fin de influenciar
              de forma indebida la conducta del beneficiado.
              <br/>
              <span>Ejemplo:</span> La Empresa da a un funcionario público algún regalo o un beneficio de cualquier
              índole para que se le adjudique un contrato o para que omita una fiscalización.
              <br/>
              <br/>
              <span>Receptación:</span>
              <br/>
              <span>Descripción:</span> Cualquier acto que implique almacenar, transportar, comprar, vender,
              transformar o comercializar, en cualquier forma, especies o bienes que hayan sido
              hurtados, robados u objeto de abigeato, de receptación o de apropiación indebida.
              <br/>
              <span>Ejemplo:</span>
              <br />
              <ul>
                <li>Compra de bienes que hayan sido robados.</li>
                <li>Compra de vehículos que hayan sido robados.</li>
              </ul>
              <br />
              <span>Administración Desleal:</span>
              <br />
              <span>Descripción:</span> Se refiere al hecho de infringir los deberes de cuidado y lealtad de una
              persona que está a cargo del patrimonio de otro, esto es, provocarle perjuicio por el
              ejercicio abusivo de sus facultades de administrar, de modo manifiestamente contrario a
              los intereses del titular del patrimonio afectado.
              <br />
              <span>Ejemplo:</span> Se contrata un subcontrato a un precio mayor que el del mercado sin
              justificación verificable que respalde suficientemente el mayor valor y dicha situación
              ocasiona un perjuicio a la Empresa o a sus accionistas.
              <br />
              <br />
              <span>Negociación Incompatible:</span>
              <br />
              <span>Descripción:</span> Se refiere al acto de interesarse (directores, gerentes u otros a quienes les
              apliquen las normas establecidas en materia de sus deberes) directa o indirectamente en
              cualquier negociación, actuación, contrato, operación o gestión que involucre a la
              sociedad, incumpliendo las condiciones establecidas por ley. "Interesarse" se refiere a la
              acción de participar de una operación en la que un conflicto de interés obligaba a
              abstenerse.
              <br />
              <span>Ejemplo:</span> Director o Gerente participa en una negociación en la que la contraparte
              comercial es un familiar y no informa dicho conflicto de interés al Directorio y no se
              abstiene de participar en la toma de decisión.
              <br />
              <br />
              <span>Apropiación Indebida:</span>
              <br />
              <span>Descripción:</span> Consiste en apropiarse o distraer dinero, efectos o cualquiera otra cosa
              mueble que se hubiere recibido en depósito, comisión o administración, o por otro título
              que produzca obligación de entregarla o devolverla.
              <br />
              <span>Ejemplo:</span> Se arrienda maquinaria y al término de la fecha estipulada el contrato o afín no
              se devuelve al arrendador.
              <br />
              <br />
              <span>Ordenar concurrir al lugar de trabajo en incumplimiento de las normas sanitarias.</span>
              <br />
              <span>Descripción:</span> Consiste en que una jefatura instruya a un trabajador para que asista a su
              lugar de trabajo cuando este es distinto a su hogar, en circunstancias de que se encuentra
              en situación de aislamiento sanitario de acuerdo a la normativa señalada por la autoridad
              sanitaria.
              <br />
              <span>Ejemplo:</span> Trabajador se encuentra haciendo cuarentena por dar positivo COVID-19 y se
              necesita urgente apoyo en la planta o existe una emergencia en la localidad por lo que el
              trabajador es llamado urgente para que asista al lugar de trabajo.
              <br />
              <br />
              <span>Delitos Informáticos.</span>
              <br />
              <span>Descripción:</span> Toda persona que ataque, ingrese, intercepte, abuse, o cometa fraude a los
              sistemas informáticos o de la información alojada en sistemas de terceras personas, o a su
              vez almacene o falsifique información de sistemas de terceras personas sin su
              consentimiento.
              <br />
              <ul>
                <li>Realizar Hacking a una base de datos de un sistema para acceder a información privilegiada o confidencial sin autorización.</li>
                <li>Vender, comprar, almacenar o transferir información o datos informáticos que hayan sido obtenidas mediante delitos informáticos.</li>
                <li>Dejar sin efecto o inutilizar una plataforma de base de datos.</li>
              </ul>
            </div>
          ) : null}
        </div>
        <p>II. Infracciones al código de ética</p>
        <div className="denuncias-item-row">
          <div className="denuncias-item">
            <div className="denuncias-item-radio">
              <input
                type="radio"
                id="denuncias-codigo-etica"
                name="denuncias"
                value="codigo-etica"
                checked={denunciaType === "codigo-etica"}
                onChange={onDenunciaRadioChange}
              />
              <label htmlFor="denuncias-codigo-etica">Conductas contrarias al Código de Ética</label>
            </div>
            <div className="denuncias-item-info" onClick={() => setEticaOpen(!eticaOpen)}>{eticaOpen ? '-' : '+'} info</div>
          </div>
          {eticaOpen ? (
            <div className="denuncias-content">
              <span>Descripción:</span> Transgresión o conducta contraria de las personas a lo establecido en el código de ética de la compañía.
              <br />
              <span>Ejemplo:</span> Conducta reñida con los valores y principios corporativos.
            </div>
          ) : null}
        </div>
        <p>III. Ley Karin</p>
        <div className="denuncias-item-row">
          <div className="denuncias-item">
            <div className="denuncias-item-radio">
              <input
                type="radio"
                id="denuncias-acoso-laboral"
                name="denuncias"
                value="acoso-laboral"
                checked={denunciaType === "acoso-laboral"}
                onChange={onDenunciaRadioChange}
              />
              <label htmlFor="denuncias-acoso-laboral">Acoso laboral</label>
            </div>
            <div className="denuncias-item-info" onClick={() => setAcosoLaboralOpen(!acosoLaboralOpen)}>{acosoLaboralOpen ? '-' : '+'} info</div>
          </div>
          {acosoLaboralOpen ? (
            <div className="denuncias-content">
              <span>Descripción:</span> Toda conducta que constituya agresión u hostigamiento ejercida por el
              empleador o por uno o más trabajadores, en contra de otro u otros trabajadores, por
              cualquier medio, ya sea que se manifieste una sola vez o de manera reiterada, y que
              tenga como resultado para el o los afectados su menoscabo, maltrato o humillación, o
              bien que amenace o perjudique su situación laboral o sus oportunidades en el empleo.
              <br />
              <span>Ejemplo:</span>
              <ul>
                <li>Faltas de respeto graves en el trato entre compañeros de trabajo.</li>
                <li>Trato humillante o degradación permanente a trabajadores específicos.</li>
              </ul>
            </div>
          ) : null}
        </div>
        <div className="denuncias-item-row">
          <div className="denuncias-item">
            <div className="denuncias-item-radio">
              <input
                type="radio"
                id="denuncias-acoso-sexual"
                name="denuncias"
                value="acoso-sexual"
                checked={denunciaType === "acoso-sexual"}
                onChange={onDenunciaRadioChange}
              />
              <label htmlFor="denuncias-acoso-sexual">Acoso Sexual</label>
            </div>
            <div className="denuncias-item-info" onClick={() => setAcosoSexualOpen(!acosoSexualOpen)}>{acosoSexualOpen ? '-' : '+'} info</div>
          </div>
          {acosoSexualOpen ? (
            <div className="denuncias-content">
              <span>Descripción:</span> Es aquella conducta en que una persona realiza, en forma indebida, por
              cualquier medio, requerimientos de carácter sexual, no consentidos por quien los recibe y
              que amenacen o perjudiquen su situación laboral o sus oportunidades en el empleo.
              <br />
              <span>Ejemplo:</span> Proposiciones concretas de carácter sexual no consentidas por quien las recibe,
              bajo amenazas que se relacionen con su situación laboral o sus oportunidades en el
              empleo.
            </div>
          ) : null}
        </div>
        <div className="denuncias-item-row">
          <div className="denuncias-item">
            <div className="denuncias-item-radio">
              <input
                type="radio"
                id="denuncias-violencia-terceros"
                name="denuncias"
                value="violencia-terceros"
                checked={denunciaType === "violencia-terceros"}
                onChange={onDenunciaRadioChange}
              />
              <label htmlFor="denuncias-violencia-terceros">Violencia de terceros</label>
            </div>
            <div className="denuncias-item-info" onClick={() => setViolenciaTercerosOpen(!violenciaTercerosOpen)}>{violenciaTercerosOpen ? '-' : '+'} info</div>
          </div>
          {violenciaTercerosOpen ? (
            <div className="denuncias-content">
              <span>Descripción:</span> Se refieren a acciones o circunstancias en las cuales se trata de manera
              injusta o desigual a personas o grupos basados en características como la raza, el género,
              la orientación sexual, la religión, la nacionalidad, la discapacidad u otras diferencias.
              <br />
              <span>Ejemplo:</span>
              <ul>
                <li>No contratar a un candidato cualificado debido a su género, raza, edad o discapacidad, a pesar de que cumplan con los requisitos del puesto.</li>
                <li>No ascender a una persona por ser de otra religión.</li>
              </ul>
            </div>
          ) : null}
        </div>
        <p>IV. Consultas</p>
        <div className="denuncias-item-row">
          <div className="denuncias-item">
            <div className="denuncias-item-radio">
              <input
                type="radio"
                id="denuncias-consultas"
                name="denuncias"
                value="consultas"
                checked={denunciaType === "consultas"}
                onChange={onDenunciaRadioChange}
              />
              <label htmlFor="denuncias-consultas">Consultas</label>
            </div>
            <div className="denuncias-item-info" onClick={() => setConsultasOpen(!consultasOpen)}>{consultasOpen ? '-' : '+'} info</div>
          </div>
          {consultasOpen ? (
            <div className="denuncias-content">
              <span>Descripción:</span> Consultar sobre actos o conductas dudosas que podrían transgredir leyes, normativas o principios establecidos por la empresa y que no ha sido posible tipificar en alguna de las categorías anteriores.
            </div>
          ) : null}
        </div>
        <div>
          <br />
          <button
            className="denuncias-information-submit"
            type="button"
            disabled={!denunciaRelacion || !denunciaLugar || !denunciaType}
            onClick={() => setPage(2)}
          >
            Continuar
          </button>
        </div>
      </div>
    );
  };

  const denunciasPageTwo = () => {
    return (
      <div>
        <h1>Ingresar Denuncia</h1>
        <h2>{parseDenunciaType(denunciaType)}</h2>
        <br />
        <p>1. Por favor, complete la siguiente información:</p>
        <div className="denuncias-item-text">
          <label htmlFor="denuncia-first-name">Nombre: </label>
          <input id="denuncia-first-name" type="text" className="denuncias-information-input" name="firstname" onChange={onNameChange} />
        </div>
        <div className="denuncias-item-text">
          <label htmlFor="denuncia-last-name">Apellido: </label>
          <input id="denuncia-last-name" type="text" className="denuncias-information-input" name="lastname" onChange={onLastNameChange} />
        </div>
        <div className="denuncias-item-text">
          <label htmlFor="denuncia-phone">Número de teléfono: </label>
          <input id="denuncia-phone" type="text" className="denuncias-information-input" name="phone" onChange={onPhoneChange} />
        </div>
        <p>Horario de preferencia para ser contactado (lunes a viernes) (Elección opción)</p>
        <div className="denuncias-item-radio">
          <input
            type="radio"
            id="contacto-9-12"
            name="contacto"
            value="9-12"
            checked={contactTime === "9-12"}
            onChange={onContactTimeChange}
          />
          <label htmlFor="contacto-9-12">09:00 a 12:00</label>
        </div>
        <div className="denuncias-item-radio">
          <input
            type="radio"
            id="contacto-12-15"
            name="contacto"
            value="12-15"
            checked={contactTime === "12-15"}
            onChange={onContactTimeChange}
          />
          <label htmlFor="contacto-12-15">12:00 a 15:00</label>
        </div>
        <div className="denuncias-item-radio">
          <input
            type="radio"
            id="contacto-15-18"
            name="contacto"
            value="15-18"
            checked={contactTime === "15-18"}
            onChange={onContactTimeChange}
          />
          <label htmlFor="contacto-15-18">15:00 a 18:00</label>
        </div>
        <div className="denuncias-item-text">
          <label htmlFor="denuncia-email">Correo electrónico: </label>
          <input id="denuncia-email" type="text" className="denuncias-information-input" name="email" onChange={onEmailChange} />
        </div>
        <p>2. ¿En qué fecha sucedió el incidente? (Escribir)</p>
        <div className="denuncias-item-text">
          <input id="denuncia-date" type="text" className="denuncias-information-input" name="date" onChange={onDenunciaDateChange} value={denunciaDate} />
        </div>
        <p className="p-small">*Ejemplo: Jueves 29 de febrero del 2024-Hoy en la mañana-El viernes de la semana pasada.</p>
        <p>3. ¿Hace cuánto tiempo cree usted que ha estado sucediendo? (Elección opción)</p>
        <div className="denuncias-item-radio">
          <input
            type="radio"
            id="denuncia-time-week"
            name="denuncia-time"
            value="week"
            checked={denunciaTime === "week"}
            onChange={onDenunciaTimeChange}
          />
          <label htmlFor="denuncia-time-week">Menos de una semana</label>
        </div>
        <div className="denuncias-item-radio">
          <input
            type="radio"
            id="denuncia-time-month"
            name="denuncia-time"
            value="month"
            checked={denunciaTime === "month"}
            onChange={onDenunciaTimeChange}
          />
          <label htmlFor="denuncia-time-month">Más de una semana y menos de un mes</label>
        </div>
        <div className="denuncias-item-radio">
          <input
            type="radio"
            id="denuncia-time-six-months"
            name="denuncia-time"
            value="six-months"
            checked={denunciaTime === "six-months"}
            onChange={onDenunciaTimeChange}
          />
          <label htmlFor="denuncia-time-six-months">Más de un mes y menos de 6 meses</label>
        </div>
        <div className="denuncias-item-radio">
          <input
            type="radio"
            id="denuncia-time-six-months-more"
            name="denuncia-time"
            value="six-months-more"
            checked={denunciaTime === "six-months-more"}
            onChange={onDenunciaTimeChange}
          />
          <label htmlFor="denuncia-time-six-months-more">Más de 6 meses</label>
        </div>
        <div className="denuncias-item-radio">
          <input
            type="radio"
            id="denuncia-time-unknown"
            name="denuncia-time"
            value="unknown"
            checked={denunciaTime === "unknown"}
            onChange={onDenunciaTimeChange}
          />
          <label htmlFor="denuncia-time-unknown">No sabe</label>
        </div>
        <p>4. ¿Dónde sucedió la infracción? (Escribir)</p>
        <div className="denuncias-item-text">
          <input id="denuncia-place" type="text" className="denuncias-information-input" name="place" onChange={onDenunciaPlaceChange} value={denunciaPlace} />
        </div>
        <p className="p-small">
          *Entendemos que este incidente puede no haber ocurrido en un lugar físico en particular, sin
          embargo, si éste se registró en alguna documentación o transacción comercial, indíquelo según proceda.
        </p>
        <p>5. ¿Cómo tomó conocimiento de este hecho? (Elección opción)</p>
        <div className="denuncias-item-radio">
          <input
            type="radio"
            id="denuncia-knowledge-heard"
            name="denuncia-knowledge"
            value="heard"
            checked={denunciaKnowledge === "heard"}
            onChange={onDenunciaKnowledgeChange}
          />
          <label htmlFor="denuncia-knowledge-heard">Lo escuché</label>
        </div>
        <div className="denuncias-item-radio">
          <input
            type="radio"
            id="denuncia-knowledge-me"
            name="denuncia-knowledge"
            value="me"
            checked={denunciaKnowledge === "me"}
            onChange={onDenunciaKnowledgeChange}
          />
          <label htmlFor="denuncia-knowledge-me">Me paso a mi</label>
        </div>
        <div className="denuncias-item-radio">
          <input
            type="radio"
            id="denuncia-knowledge-observed"
            name="denuncia-knowledge"
            value="observed"
            checked={denunciaKnowledge === "observed"}
            onChange={onDenunciaKnowledgeChange}
          />
          <label htmlFor="denuncia-knowledge-observed">Lo observé</label>
        </div>
        <div className="denuncias-item-radio">
          <input
            type="radio"
            id="denuncia-knowledge-coworker"
            name="denuncia-knowledge"
            value="coworker"
            checked={denunciaKnowledge === "coworker"}
            onChange={onDenunciaKnowledgeChange}
          />
          <label htmlFor="denuncia-knowledge-coworker">Un compañero de trabajo me lo comentó</label>
        </div>
        <div className="denuncias-item-radio">
          <input
            type="radio"
            id="denuncia-knowledge-outsider"
            name="denuncia-knowledge"
            value="outsider"
            checked={denunciaKnowledge === "outsider"}
            onChange={onDenunciaKnowledgeChange}
          />
          <label htmlFor="denuncia-knowledge-outsider">Alguien fuera de la oficina me lo comentó</label>
        </div>
        <div className="denuncias-item-radio">
          <input
            type="radio"
            id="denuncia-knowledge-document"
            name="denuncia-knowledge"
            value="document"
            checked={denunciaKnowledge === "document"}
            onChange={onDenunciaKnowledgeChange}
          />
          <label htmlFor="denuncia-knowledge-document">Por casualidad me encontré un documento</label>
        </div>
        <div className="denuncias-item-radio">
          <input
            type="radio"
            id="denuncia-knowledge-other"
            name="denuncia-knowledge"
            value="other"
            checked={denunciaKnowledge === "other"}
            onChange={onDenunciaKnowledgeChange}
          />
          <label htmlFor="denuncia-knowledge-other">Otro</label>
        </div>
        {denunciaKnowledge === "other" ? (<div>
          <input
            id="denuncia-knowledge-other-text"
            className="denuncias-information-input"
            type="text"
            placeholder="Especifique aquí"
            value={denunciaKnowledgeText}
            onChange={onDenunciaKnowledgeTextChange}
          />
        </div>) : null}
        <p>6. ¿Puede identificar a las personas comprometidas en esta infracción? (Elección opción)</p>
        <div className="denuncias-item-radio">
          <input
            type="radio"
            id="denuncia-identification-yes"
            name="denuncia-identification"
            value="yes"
            checked={denunciaIdentification === "yes"}
            onChange={onDenunciaIdentificationChange}
          />
          <label htmlFor="denuncia-identification-yes">Si</label>
        </div>
        <div className="denuncias-item-radio">
          <input
            type="radio"
            id="denuncia-identification-no"
            name="denuncia-identification"
            value="no"
            checked={denunciaIdentification === "no"}
            onChange={onDenunciaIdentificationChange}
          />
          <label htmlFor="denuncia-identification-no">No</label>
        </div>
        <div className="denuncias-item-radio">
          <input
            type="radio"
            id="denuncia-identification-unknown"
            name="denuncia-identification"
            value="unknown"
            checked={denunciaIdentification === "unknown"}
            onChange={onDenunciaIdentificationChange}
          />
          <label htmlFor="denuncia-identification-unknown">No sabe / No desea revelar</label>
        </div>
        <p>7. Indique si ha habido personas que hayan intentado encubrir esta situación (Elección opción)</p>
        <div className="denuncias-item-radio">
          <input
            type="radio"
            id="denuncia-coverup-yes"
            name="denuncia-coverup"
            value="yes"
            checked={denunciaCoverup === "yes"}
            onChange={onDenunciaCoverupChange}
          />
          <label htmlFor="denuncia-coverup-yes">Si</label>
        </div>
        <div className="denuncias-item-radio">
          <input
            type="radio"
            id="denuncia-coverup-no"
            name="denuncia-coverup"
            value="no"
            checked={denunciaCoverup === "no"}
            onChange={onDenunciaCoverupChange}
          />
          <label htmlFor="denuncia-coverup-no">No</label>
        </div>
        <div className="denuncias-item-radio">
          <input
            type="radio"
            id="denuncia-coverup-unknown"
            name="denuncia-coverup"
            value="unknown"
            checked={denunciaCoverup === "unknown"}
            onChange={onDenunciaCoverupChange}
          />
          <label htmlFor="denuncia-coverup-unknown">No sabe / No desea revelar</label>
        </div>
        <p>8. Indique si ha hablado con alguien de esto o denunciado el hecho ante alguna autoridad en Aguas Patagonia de Aysén S.A (Elección opción)</p>
        <div className="denuncias-item-radio">
          <input
            type="radio"
            id="denuncia-authority-yes"
            name="denuncia-authority"
            value="yes"
            checked={denunciaAuthority === "yes"}
            onChange={onDenunciaAuthorityChange}
          />
          <label htmlFor="denuncia-authority-yes">Si</label>
        </div>
        <div className="denuncias-item-radio">
          <input
            type="radio"
            id="denuncia-authority-no"
            name="denuncia-authority"
            value="no"
            checked={denunciaAuthority === "no"}
            onChange={onDenunciaAuthorityChange}
          />
          <label htmlFor="denuncia-authority-no">No</label>
        </div>
        <div className="denuncias-item-radio">
          <input
            type="radio"
            id="denuncia-authority-unknown"
            name="denuncia-authority"
            value="unknown"
            checked={denunciaAuthority === "unknown"}
            onChange={onDenunciaAuthorityChange}
          />
          <label htmlFor="denuncia-authority-unknown">No sabe / No desea revelar</label>
        </div>
        <p>9. En el caso de tener algún documento o archivo que respalde su denuncia, le agradeceríamos lo suba pinchando el siguiente botón (Subir Adjunto)</p>
        <div className="denuncias-information-submit-btn">
          <button
            className="denuncias-information-submit-file"
            type="button"
            onClick={onDenunciaFileChange}
          >
            Adjuntar archivo
          </button>
          <input
            type="file"
            ref={fileInputRef}
            style={{ display: 'none' }}
            onChange={handleFileChange}
          />
          <div>
            {denunciaFile ? (
              <p>{denunciaFile.name}</p>
            ) : null}
          </div>
        </div>
        <br />
        <div>
          
        </div>
        <br />
        <p className="p-small">
          * Especificaciones del archivo:<br />
          Sólo puede subir archivos que no excedan los 10 MB<br />
          Se puede subir los tipos de archivos mas habituales, formatos soportados: jpg, jpeg, png, gif, mp3,
          mp4, mov, wmv, doc, docx, xls, xlsx, txt, ppt, pptx, heic
        </p>
        <p>10. A continuación, describa por favor con tanto detalle sea posible el hecho que desea denunciar. Mencione incluso la ubicación de los testigos, hace cuanto tiempo cree usted que ocurre o cualquier otra información que no haya entregado más arriba (Escribir)</p>
        <div>
          <textarea cols="75" rows="5" value={denunciaText} onChange={onDenunciaTextChange} />
        </div>
        <br />
        <br />
        <div className="denuncias-information-submit-btn">
          <button
            className="denuncias-information-submit"
            type="button"
            onClick={() => setPage(1)}
          >
            Volver
          </button>
          <button
            className="denuncias-information-submit"
            type="button"
            onClick={handleSubmit}
            disabled={
              name === ''
              || lastName === ''
              || phone === ''
              || !contactTime
              || email === ''
              || denunciaDate === ''
              || !denunciaTime
              || denunciaPlace === ''
              || !denunciaKnowledge
              || !denunciaIdentification
              || !denunciaCoverup
              || !denunciaAuthority
              || denunciaText === ''
              || loading
            }
          >
            {loading ? 'Cargando...' : 'Enviar'}
          </button>
        </div>
        <br />
        <div>
          {error ? (
            <p className="p-error">{error}</p>
          ) : null}
        </div>
        <br />
        <div>
          <p>
            Nota: La información entregada en este formulario es estrictamente confidencial y
            no puede ser modificada. El correo electrónico que usted ingrese será el utilizado
            como medio de contacto para informarle sobre los avances con respecto a la
            investigación.
          </p>
        </div>
        <div className="denuncia-files">
          <div>
            <a href="https://aguas-patagonia-public-files.s3.sa-east-1.amazonaws.com/denuncias/GG-CI-PRO-003-Codigo-de-Etica.pdf" target="_blank" rel="noopener noreferrer">DESCARGAR EL CODIGO ÉTICA</a>
          </div>
          <div>
            <a href="https://aguas-patagonia-public-files.s3.sa-east-1.amazonaws.com/denuncias/GG-CI-POL-006-Politica-Prevencion-de-Delitos-Ley-20393.pdf" target="_blank" rel="noopener noreferrer">DESCARGAR POLITICA DE PREVENCION DE DELITO</a>
          </div>
          <div>
            <a href="https://aguas-patagonia-public-files.s3.sa-east-1.amazonaws.com/denuncias/GG-CI-POL-007-Politica-Donaciones-Auspicios-y-Patrocinios.pdf" target="_blank" rel="noopener noreferrer">DESCARGAR POLITICA DE DONACIONES AUSPICIO Y PATROCINIOS</a>
          </div>
          <div>
            <a href="https://aguas-patagonia-public-files.s3.sa-east-1.amazonaws.com/denuncias/GG-CI-POL-008-Politica-Relacionamiento-con-Funcionarios-Publicos.pdf" target="_blank" rel="noopener noreferrer">DESCARGAR POLÍTICA RELACIONAMIENTO CON FUNCIONARIOS PÚBLICOS</a>
          </div>
          <div>
            <a href="https://aguas-patagonia-public-files.s3.sa-east-1.amazonaws.com/denuncias/GG-CI-POL-009-Politica-de-Regalos.pdf" target="_blank" rel="noopener noreferrer">DESCARGAR POLÍTICA DE REGALOS</a>
          </div>
          <div>
            <a href="https://aguas-patagonia-public-files.s3.sa-east-1.amazonaws.com/denuncias/GG-CI-POL-11-Politica-de-manejo-de-conflicto-de-interes.pdf" target="_blank" rel="noopener noreferrer">DESCARGAR POLÍTICA DE MANEJO DE CONFLICTO DE INTERES</a>
          </div>
          <div>
            <a href="https://aguas-patagonia-public-files.s3.sa-east-1.amazonaws.com/denuncias/GG-CI-PRO-007-Prevencion-y-Procedimiento-de-Investigacion-de-acoso-sexual-laboral-y-violencia-en-el-trabajo.pdf" target="_blank" rel="noopener noreferrer">DESCARGAR PREVENCIÓN Y PROCEDIMIENTO DE INVESTIGACIÓN DE ACOSO SEXUAL, LABORAL Y VIOLENCIA EN EL TRABAJO</a>
          </div>
        </div>
      </div>
    );
  };

  const denunciasPage = () => {
    if (page === 1) return denunciasPageOne();
    if (page === 2) return denunciasPageTwo();

    return (<div />);
  };

  return (
    <Page
      title="Aguas Patagonia | Denuncias"
      current='denuncias'
    >
      <div className="denuncias-wrapper">
        {denunciasPage()}
      </div>
    </Page>
  )
}

export default Denuncias