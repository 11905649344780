import React from 'react';
import Page from '../../components/Layout/Page';
import './Obras.css';


const Obras = () => (
  <Page
      title="Aguas Patagonia | Presentación de Proyectos, Recepción de Obras y Certificado de Instalaciones"
      current='clients'
    >
    <div className="obras-wrapper">
      <div className="obras">
        <h1>Presentación de Proyectos, Recepción de Obras y Certificado de Instalaciones</h1>
        <h2>Presentación de Proyectos</h2>
        <p>Para ingresar proyecto informativo, se deben enviar los antecedentes al correo <a href="mailto:proyectos@aguaspatagonia.cl">proyectos@aguaspatagonia.cl</a></p>
        <p>Los documentos adjuntos deben ser los estipulados en el párrafo N°2 de Articulo N° 50, del reglamento de instalaciones de Agua Potable y alcantarillado (RIDAA). Como mínimo, se deberá enviar lo siguiente:</p>
        <ul>
          <li>Lámina de Agua Potable (pdf)</li>
          <li>Lámina de Alcantarillado (pdf)</li>
          <li>Certificado de Factibilidad Vigente (pdf)</li>
        </ul>
        <p>Al momento de aprobar el proyecto y comenzar con la ejecución de las obras se deberá adjuntar el Aviso Inicio de Obras. Descargue el archivo <a href="https://aguas-patagonia-public-files.s3.sa-east-1.amazonaws.com/aviso_de_inicio_de_obras_formulario.pdf" target="_blank" rel="noopener noreferrer">aquí</a></p>
        <br />
        <h2>Recepción de Obras</h2>
        <p>Para coordinar la recepción de las obras se deben enviar los siguientes antecedentes al correo <a href="mailto:proyectos@aguaspatagonia.cl">proyectos@aguaspatagonia.cl</a></p>
        <ul>
          <li>Fotos generales del avance de las instalaciones del arranque, medidor y unión domiciliaria (pdf)</li>
          <li>Solicitud de recepción completa con firmas originales de proyectista y contratista en formato PDF y enviar en formato Excel (sin firmas). Descargue los archivos aquí: (<a href="https://aguas-patagonia-public-files.s3.sa-east-1.amazonaws.com/solicitud_de_recepcion_de_arranque_provisorio_formulario.pdf" target="_blank" rel="noopener noreferrer">pdf</a>) y (<a href="https://aguas-patagonia-public-files.s3.sa-east-1.amazonaws.com/solicitud_de_recepcion_propietario.xlsm" target="_blank" rel="noopener noreferrer">excel</a>)</li>
          <li>Aviso inicio de obras con firmas originales (Formato PDF)</li>
          <li>Planos definitivos subsanados firmados por Proyectista, Propietario y Contratista (pdf)</li>
          <li>Certificado N° Municipal (pdf)</li>
          <li>Autorización permisos de ocupación de vía pública y rotura de pavimentos emitidas por el SERVIU y la MUNICIPALIDAD si corresponde. (pdf)</li>
        </ul>
        <p style={{fontWeight: 'bold'}}>Al momento de realizar la recepción, el contratista deberá tener preparada la prueba de presión y las instalaciones deberán cumplir con lo estipulado el Anexo 1, Planos Definitivos y Reglamentación Vigente.</p>
        <p>Descargue el Anexo 1 <a href="https://aguas-patagonia-public-files.s3.sa-east-1.amazonaws.com/solicitud_recepcion_anexo_1.pdf" target="_blank" rel="noopener noreferrer">aquí</a></p>
        <p>Se informa que el inspector en terreno es el único que puede autorizar la conexión a la red pública de agua potable y/o empalme al colector público de A.S., si se cumple con el plano definitivo y la reglamentación vigente. Queda estrictamente prohibido conectar o empalmarse a la red de esta Sanitaria, sin la autorización del inspector en terreno. Caso contrario, se tomará como una conexión o empalme fraudulento, y se aplicarán las sanciones correspondientes.</p>
        <p style={{fontWeight: 'bold'}}>En caso de existir un arranque de agua potable en la propiedad, en la solicitud de recepción deberá indicar si el levantamiento lo realiza con su contratista particular o contrata el servicio con aguas Patagonia, en ambos casos se coordinará con el encargado de terreno para realizar el levantamiento de arranque al mismo tiempo que la recepción de las obras. En aquellos casos que no sea posible coordinar, el contratista o proyectista deberá enviar la lectura del medidor retirado de forma particular.</p>
        <br />
        <h2>Certificado de Instalaciones</h2>
        <p>Para obtener certificado de instalaciones, se deben enviar los siguientes documentos al correo <a href="mailto:proyectos@aguaspatagonia.cl">proyectos@aguaspatagonia.cl</a></p>
        <ul>
          <li>Solicitud de certificado de instalaciones firmado por el solicitante y con todos los datos completos. (pdf) Descargue el documento <a href="https://aguas-patagonia-public-files.s3.sa-east-1.amazonaws.com/Solicitud_Certificado_de_Instalaciones_AP_y_AS_formulario.pdf" target="_blank" rel="noopener noreferrer">aquí</a></li>
          <li>Boleta de agua (pdf)</li>
          <li>Documento adjunto de acuerdo al motivo indicado en la solicitud. (pdf)</li>
        </ul>
      </div>
    </div>
  </Page>
);

export default Obras;
