import React, { useState } from 'react'
import Page from '../../components/Layout/Page'
import './Payment.css';
import axios from './../../utils/axios'

function Payment() {
  const [paymentOption,setPaymentOption]= useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [webPayPlusUrl, setWebPayPlusUrl] = useState(null);
  const [webPayPlusToken, setWebPayPlusToken] = useState(null);
  const [loading, setLoading] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [inputValues, setInputValues] = useState({
    // Basic Info
    name: '',
    surname: '',
    email: '',
    // Billing Info
    billingType: 'boleta',
    company: '',
    rut: '',
    activity: '',
    billingAddress: '',
    billingCommune: '',
    billingCity: '',
  })
  const [serviceNumber, setServiceNumber] = useState(null);

  async function onButtonClick() {
    if (!paymentOption){
      setErrorMessage("Debe seleccionar un medio de pago")
    } else if (paymentOption === 'servipag') {
      window.location.href = 'https://ww3.servipag.com/pagoenlinea/portal/desktop/public/todas/!ut/p/b1/04_Sj9CPykssy0xPLMnMz0vMAfGjzOKNgs0CLZ0MHQ38zQPcDBx9gs1CzAK9Df0DTYAKIvEo8DanTL-HEXH6DXAARwNC-r30o9Jz8pOAXg3Xj8KrGOQXvApAjgUrwOMaP4_83FT9gtzQ0IjKYE9dR0VFADNEynw!/dl4/d5/L2dBISEvZ0FBIS9nQSEh/?idServicio=1&idBiller=58';
    } else if (!serviceNumber && paymentOption === 'webpay-plus') {
      setErrorMessage('Debe ingresar un Número de Servicio');
    } else {
      const BillData = {
        ...inputValues,
        paymentOption,
        serviceNumber
      }
      setErrorMessage(null)
      setLoading(true);
      const newBillResponse = await axios.post('/api/bills/new', BillData);
      setLoading(false);

      if (newBillResponse.data.code === 201) {
        const { paymentData } = newBillResponse.data.data
        if (paymentData.success) {
          if (paymentData.data.payment_method === 'webpay-plus') {
            await setWebPayPlusUrl(paymentData.data.tbk_url)
            await setWebPayPlusToken(paymentData.data.token)
            document.getElementById('form-submit').submit()
          }
        } else {
          setErrorMessage(paymentData.data.errorMessage)
        }
      } else if (newBillResponse.data.code === 400) {
        setErrorMessage('El número de servicio indicado no presenta deuda');
      } else if (newBillResponse.data.code === 404) {
        setErrorMessage('El numero de servicio no existe');
      } else {
        setErrorMessage('Ha ocurrido un error. Por favor póngase en contacto con nostros a través de contacto@aguaspatagonia.cl');
      }
    }
  }

  const handleChange = (e) => {
    setServiceNumber(e.target.value);
  }

  const webPayForm = () => {
    return (
      <form id="form-submit" action={webPayPlusUrl}>
        <input type="hidden" name="token_ws" value={webPayPlusToken} />
      </form>
    )
  }

  const displayPaymentOptions = () => (
    <div className="pay-option-container">
      <button
        type="button"
        className="pay-option"
        onClick={() => setPaymentOption('webpay-plus')}
      >
        <img src="/img/webpay-plus.png" alt="webpay plus" />
        <input
          type="radio"
          onChange={() => setPaymentOption('webpay-plus')}
          checked={paymentOption === 'webpay-plus'}
        />
      </button>
      {/* <button
        type="button"
        className="pay-option"
        onClick={() => setPaymentOption('servipag')}
      >
        <img src="/img/servipag.svg" alt="servipag" />
        <input
          type="radio"
          id="servipag"
          onChange={() => setPaymentOption('servipag')}
          checked={paymentOption === 'servipag'}
        />
      </button> */}
      {webPayForm()}
    </div>
  )

  return (
    <Page
      title="Aguas Patagonia | Pagar mi Cuenta"
      current='payment'
    >
      <div className="payment-page-container">
        <div className='icon-container-payment'>
          <img src='/img/icon1.png' alt='icon'></img>
        </div>
        <div className='text-wrapper'>
          <h1>Pagar mi cuenta</h1>
          <p>Ingrese Número de Servicio <strong>sin dígito verificador</strong></p>
          <label style={{ fontSize: '12px' }}>
            Número Servicio <br />
            <input type="text" className='information-input-ticket' id='number' onChange={handleChange} />
          </label>
          <p>Seleccione un medio de pago</p>
          <p>Para otros medios de pago puedes utilizar Caja Vecina o pago presencial en cualquiera de nuestras oficinas comerciales.</p>
          <div className="paragraph-wrapper">
          {displayPaymentOptions()}
          </div>
          <button className='blue-button payment-button' disabled={loading} onClick={onButtonClick}>Pagar</button>
          <div className="loading">
            {loading ?
              (<img src="/img/loading.gif" alt="Cargando..." />)
            : null}
          </div>
          {errorMessage && <p className="error-message">{errorMessage}</p>}
        </div>
      </div>
    </Page >
  )
}

export default Payment