import React from 'react'
import Page from '../../components/Layout/Page'
import './Shortage.css';

function Shortage() {
    function onButtonClick() {
      window.open('https://www.facebook.com/AguasPatagonia', '_blank');
    }

    return (
        <Page
            title="Aguas Patagonia | Cortes Programados"
            current='clients'
        >
            <div className="shortage-page-container">
                <div className='icon-container-shortage'>
                    <img src='/img/icon5.png' alt='icon'></img>
                </div>
                <div className='text-wrapper-shortage'>
                    <h1>Cortes Programados</h1>
                    <div>
                      <iframe title="Corte" src={`https://scc.aguaspatagonia.cl/Cortesweb.aspx`} frameborder="1" allowfullscreen />
                    </div>
                </div>
                <div>
                  <p>
                    Revisa el link a facebook para más información.
                  </p>
                  <button className='more-information' onClick={onButtonClick}>
                    <img src='/img/facebook.png' alt='FB'></img>
                    Más información
                  </button>
                </div>
            </div>
        </Page >
    )
}

export default Shortage