import React from 'react'
import './TbkFinal.css'
import Page from '../../components/Layout/Page'

function TbkNull() {
  return (
    <Page>
      <div className="container">
        <div className='icon-container-payment'>
          <img src='/img/icon1.png' alt='icon'></img>
        </div>
        <div className="info-title">
          <div className="info-title-img">
            <div className="info-check">
              <img src="/img/cancel.png" alt="fail"/>
            </div>
              <h1>PAGO ANULADO</h1>
          </div>
          <p>
            El pago fue anulado. Ningún cobro se realizó.
          </p>
        </div>
        <button
          type="button"
          className="info-submit"
          onClick={() => {
            window.location.href = '/pago'
          }}
        >
          Volver
        </button>
      </div>
    </Page>
  )
}

export default TbkNull;
