import React from 'react'
import Page from '../../components/Layout/Page'
import './Projects.css';

function Projects() {
  return (
    <Page
      title="Aguas Patagonia | Obras y Proyectos"
      current='company'
    >
      <div className="page-container-projects">
        <div className='icon-container-dev'>
          <img src='/img/icon5.png' alt='icon'></img>
        </div>
        <div className='text-wrapper-projects'>
          <h1>Obras y Proyectos</h1>
          <p>Principales obras desarrolladas durante el año 2009</p>
          <h2>Recuperación de Redes de Recolección</h2>
          <p>Aguas Patagonia, en su constante búsqueda por aplicar la mejor metodología disponible actualmente en la industria del agua, realizó trabajos de recuperación de colectores a través de la implementación de una estrategia de limpieza optimizada mediante el uso de camiones de ciclo combinado, lo que permitió:</p>
          <ul>
            <li>Reducir significativamente las interrupciones por emergencias.</li>
            <li>Controlar problemas de sedimentación en las redes de aguas servidas.</li>
            <li>Mejorar la protección del medio ambiente.</li>
            <li>Optimizar el funcionamiento de los planes de tratamiento.</li>
          </ul>
          <p>En una primera etapa se realizó la recuperación de 46 kilómetros de redes en las localidades de Coyhaique y Puerto Aysén, contemplan el lavado interior de la redes de recolección y cámaras de inspección mediante chorros de agua a muy alta presión, permitiendo recuperar las características iniciales de las redes y eliminando el material sedimentado como grasas, barro, arena, piedras y otros elementos que estas redes no son capaces de transportar. Otra particularidad de la tecnología utilizada es que mediante bombas de vacío de gran poder todo el material recolectado es recogido y almacenado en tanques especialmente habilitados para su posterior disposición en sectores autorizados, evitando con esto ser arrastrados a través de las mismas redes a otros sectores de la ciudad.</p>
          <p>Los trabajos de limpieza fueron complementados con la revisión del estado del 100% de las redes recuperadas mediante la inspección interior a través de cámaras de televisión, permitiendo detectar y catastrar las fallas o problemas que éstas presenten para su posterior reparación.</p>
          <p>Todo lo anterior nos ha permitido alcanzar altos estándares de calidad y mejorar el servicio a nuestros clientes</p>
        </div>
      </div>
    </Page >
  )
}

export default Projects