import React, { useState } from "react";
import Slider from "react-slick";
import "../../../node_modules/slick-carousel/slick/slick.css"; 
import "../../../node_modules/slick-carousel/slick/slick-theme.css";
import "./Carousel.css"

function Arrow(props) {
  const { className, style, onClick, direction } = props;
  return (
    <div
      className={className + ' carousel-arrow-' + direction}
      style={style}
      onClick={onClick}
    />
  );
}

export default function Carousel() {
  // TODO
  // eslint-disable-next-line no-unused-vars
  const [slider, setSlider] = useState(null)

  const titleContainer = (pretitle, posttitle, subtitle, btn, link, newtab) => {
    return (
      <div className='title-wrapper'>
        <div className='title-container' />          
        <div className='title-text'>
          <h1>{pretitle}<br/>{posttitle}</h1>
          <div className='subtitle-container'>
            <p>{subtitle}</p>
            <button className="blue-button" onClick={() => {
              if (newtab) {
                window.open(link, '_blank')
              } else {
                window.location.href = link
              }
            }}>{btn}</button>
          </div>
        </div>
        <div className="mobile-title">
          <h1>{pretitle}<br/>{posttitle}</h1>
          <div className='subtitle-container'>
            <p>{subtitle}</p>
            <button className="blue-button" onClick={() => {
              if (newtab) {
                window.open(link, '_blank')
              } else {
                window.location.href = link
              }
            }}>{btn}</button>
          </div>
        </div>
      </div>
    )
  }

  const newsContainer = (selected) => {
    return (
      <div className='news-container'>
        <h2>Información para Clientes</h2>
        <hr />
        <div className='boxes-container'>
          <div>
            <div className='box-wrapper' onClick={() => {
              // slider.slickGoTo(0)
              window.location.href = '/video'
            }}>
              <img src="/img/play.png" alt="video" />
              {/* <img src='img/slide1.jpg' alt='box1' /> */}
              {/* <div className='news-text-bg' /> */}
              <div className='news-text'>
                video corporativo
              </div>
            </div>
          </div>
          <div>
            <div className='box-wrapper' onClick={() => {
              // slider.slickGoTo(0)
              window.location.href = '/comunicados'
            }}>
              <img src="/img/doc-icon.png" alt="comunicados" />
              {/* <img src='img/slide1.jpg' alt='box1' /> */}
              {/* <div className='news-text-bg' /> */}
              <div className='news-text'>
                comunicados y noticias
              </div>
            </div>
          </div>
          <div>
            <div className='box-wrapper' onClick={() => {
              // slider.slickGoTo(1)
              window.location.href = '/cortes'
            }}>
              <img src="/img/drops-icon.png" alt="cortes-programados" />
              {/* <img src='img/slide2.jpg' alt='box2' /> */}
              {/* <div className='news-text-bg' /> */}
              <div className='news-text'>
                cortes programados
              </div>
            </div>
          </div>
          <div>
            <div className='box-wrapper' onClick={() => {
              // slider.slickGoTo(2)
              window.location.href = '/lugares-y-medios-de-pago'
            }}>
              <img src="/img/money-icon.png" alt="medios-de-pago" />
              {/* <img src='img/slide3.jpg' alt='box3' /> */}
              {/* <div className='news-text-bg' /> */}
              <div className='news-text'>
                lugares y medios de pago
              </div>
            </div>
          </div>
          <div>
            <div className='box-wrapper' onClick={() => {
              // slider.slickGoTo(3)
              window.location.href = '/venta-de-agua-y-servicios'
            }}>
              <img src="/img/truck-icon.png" alt="venta-de-agua-y-servicios" />
              {/* <img src='img/slide4.jpg' alt='box4' /> */}
              {/* <div className='news-text-bg' /> */}
              <div className='news-text'>
                venta de agua y servicios
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 3500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    nextArrow: <Arrow direction='right' />,
    prevArrow: <Arrow direction='left' />
  };

  return (
    <div className="carousel-container">
      <Slider ref={slider => (setSlider(slider))} {...settings}>
        {/* <div className="carousel-item">
          <img src='https://aguas-patagonia-public-files.s3.sa-east-1.amazonaws.com/Banner_PatagonesTodos.png' alt='Patagones Todos' />
          {titleContainer('Fondo Concursable', 'Patagones Todos', 'Juntas de Vecinos Puerto Aysén', 'Ver Más', '/patagones-todos', false)}
          {newsContainer(1)}
        </div> */}
        {/* <div className="carousel-item">
          <img src='https://aguas-patagonia-public-files.s3.sa-east-1.amazonaws.com/banner_portada.png' alt='Horarios Oficinas Comerciales' />
          {titleContainer('Horarios', 'de Atención', 'Oficinas Comerciales', 'Ver Más', '/oficinas', false)}
          {newsContainer(1)}
        </div> */}
        
        {/* <div className="carousel-item">
          <img src='/img/carousel-marcela.jpeg' alt='aguas1' />
          {titleContainer('Horario de', ' Atención', 'Conoce los horarios de atención comercial por fiestas patrias', 'Ver Más', '/horarios-fiestas-patrias', false)}
          {newsContainer(1)}
        </div> */}
        <div className="carousel-item">
          <img src='https://aguas-patagonia-public-files.s3.sa-east-1.amazonaws.com/banner_factibilidad.png' alt='aguas1' />
          {titleContainer('Formulario', 'Solicitud Factibilidad', 'Factibilidad de Agua Potable', 'Ver Más', '/factibilidad', false)}
          {newsContainer(1)}
        </div>
        <div className="carousel-item">
          <img src='https://aguas-patagonia-public-files.s3.sa-east-1.amazonaws.com/banner_factibilidad.png' alt='aguas1' />
          {titleContainer('Solicitud y', 'Recepción de Obras', 'Formularios y Certificados', 'Ver Más', '/obras', false)}
          {newsContainer(1)}
        </div>
        <div className="carousel-item">
          <img src='/img/carousel1.jpg' alt='aguas1' />
          {titleContainer('Formulario', 'Casa Rodante', 'Punto de descarga para casas rodantes / motorhome dumping point', 'Ver Más', '/motorhome', false)}
          {newsContainer(1)}
        </div>
        <div className="carousel-item">
          <img src='/img/carousel6.jpg' alt='aguas1' />
          {titleContainer('Video', 'Corporativo', 'Somos una empresa de la Región de Aysén', 'Ver Video Aquí', '/video', false)}
          {newsContainer(1)}
        </div>
        <div className="carousel-item">
          <img src='/img/carousel1.jpg' alt='aguas1' />
          {titleContainer('Paga tu', 'Cuenta Aquí', 'Ingresa para acceder rápidamente al pago de cuenta de agua', 'Ir a Pagar', '/pago', false)}
          {newsContainer(2)}
        </div>
        <div className="carousel-item">
          <img src='/img/carousel2.jpg' alt='aguas2' />
          {titleContainer('Comunicados', 'y Noticias', 'Revisa todas las noticias publicadas últimamente', 'Ver Más', '/comunicados', false)}
          {newsContainer(3)}
        </div>
        <div className="carousel-item">
          <img src='/img/carousel3.jpg' alt='aguas3' />
          {titleContainer('Cortes', 'Programados', 'Ingresa a ver toda la información acerca de los cortes de agua potable', 'Ver Más', '/cortes', false)}
          {newsContainer(4)}
        </div>
        <div className="carousel-item">
          <img src='/img/carousel4.jpg' alt='aguas4' />
          {titleContainer('Lugares y', 'Medios de Pago', 'Ingresa a ver cómo puedes pagar tu cuenta de agua potable', 'Ver Más', '/lugares-y-medios-de-pago')}
          {newsContainer(5)}
        </div>
        <div className="carousel-item">
          <img src="/img/carousel5.jpg" alt="aguas5" />
          {titleContainer('Venta de agua', 'y Servicios', 'Revisa todos los servicios disponibles para nuestros clientes', 'Ver Más', '/venta-de-agua-y-servicios')}
          {newsContainer(6)}
        </div>
      </Slider>
    </div>
  );
}