/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import './NavBar.css';
import NavBarDesktop from './NavBarTypes/NavBarDesktop';
import NavBarMobile from './NavBarTypes/NavBarMobile';

function NavBar({ current }) {
  return (
    <div className="navbar-container">
      <NavBarDesktop current={current} />
      <NavBarMobile current={current} />
    </div>
  )
}

export default NavBar;