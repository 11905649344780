import React, { useState, useEffect} from 'react'
import { Link } from 'react-router-dom'
import Page from '../../components/Layout/Page'
import './News.css';
import axios from './../../utils/axios'

const WomenCertification = () => (
<div className="alimentadora">
    <h2>Aguas Patagonia certifica a 19 mujeres en curso de gasfitería e instalaciones sanitarias</h2>
    <a href="https://www.eldivisadero.cl/_noticia/Aguas-Patagonia-certifica-a-19-mujeres-en-curso-de-gasfiter%C3%ADa-e-instalaciones-sanitarias/NzE0NDk=" target="_blank" rel="noopener noreferrer">
      <img src="https://aguas-patagonia-public-files.s3.sa-east-1.amazonaws.com/certificacion_mujeres.jpg" alt="Aguas Patagonia certifica a 19 mujeres en curso de gasfitería e instalaciones sanitarias" />
    </a>
  </div>
);

const WinterReinforcement = () => (
  <div className="alimentadora">
    <h2>Aguas Patagonia refuerza campaña de invierno y entrega recomendaciones para la protección de las instalaciones domiciliarias durante este invierno</h2>
    <a href="https://www.rln.cl/regional/115940-aguas-patagonia-refuerza-campana-de-invierno-y-entrega-recomendaciones-para-la-proteccion-de-las-instalaciones-domiciliarias-durante-este-invierno" target="_blank" rel="noopener noreferrer">
      <img src="https://aguas-patagonia-public-files.s3.sa-east-1.amazonaws.com/refuerzo_campana_invierno_2023.jpg" alt="Aguas Patagonia refuerza campaña de invierno y entrega recomendaciones para la protección de las instalaciones domiciliarias durante este invierno" />
    </a>
  </div>
);

const Fugas = () => (
  <div className="alimentadora">
    <h2>Cómo Detectar Fugas en tu Hogar</h2>
    <video controls>
      <source src="https://aguas-patagonia-public-files.s3-sa-east-1.amazonaws.com/AP_TUTORIAL_V4.mp4" type="video/mp4" />
    </video>
  </div>
);

const Alimentadora = () => {
  return (
    <div className="alimentadora">
      <h2>Nueva Alimentadora de Agua Potable en Chile Chico</h2>
      <video controls>
        <source src="https://aguas-patagonia-public-files.s3-sa-east-1.amazonaws.com/alimentadora_chile_chico.mp4" type="video/mp4" />
      </video>
      <p>Aguas Patagonia instaló en Chile Chico una importante obra destinada a mejorar el estado y la presión de la red de agua potable. La iniciativa consistió en el reemplazo de 132 ml de tubería de fibrocemento de 150 mm por tubería plástica de HDPE de 250 mm.</p>
      <p>Este nuevo ducto, de mayor diámetro y calidad, hace más robusto el sistema de alimentación de agua potable desde el estanque frente a sismos o intervención de terceros y mejora las presiones de servicio en los sectores más alejados, permitiendo además ir avanzando en la expansión del servicio hacia nuevos terrenos dentro del territorio operacional de la empresa.</p>
      <p>Esta tubería corresponde a la alimentadora principal que sale desde el estanque de la empresa en el cerro Banderas de la localidad de Chile Chico hasta las redes de distribución que nacen en el ingreso de la carretera Chile Chico – Puerto Guadal.</p>
      <p>Para ejecutar esta obra mayor, se coordinó el trabajo con la Municipalidad y se dio aviso a la comunidad de los cortes programados. Dada la magnitud de la operación, se generaron intermitencias y cortes puntuales en la zona que ocasionaron naturalmente molestias, frente a lo cual se envió personal de refuerzo en terreno.</p>
      <p>Junto con agradecer la comprensión de los vecinos, Aguas Patagonia seguirá en estricta coordinación con la municipalidad para asegurarse de que estas obras cumplan su objetivo de mejorar la calidad del servicio entregado.</p>
    </div>
  )
}

const CovidBenefit = () => {
  return (
    <div>
      <h2>Beneficio COVID-19 Ley 21.249<br></br> Ley de No Corte de Servicios Básicos</h2>
      <h3>Válida hasta el 06 de noviembre de 2020</h3>
      <img src="https://aguas-patagonia-public-files.s3-sa-east-1.amazonaws.com/beneficio-covid.jpg" alt="beneficio covid" />
      <div className='form-link'>
        Revise los detalles en el siguiente <a href='https://aguas-patagonia-public-files.s3-sa-east-1.amazonaws.com/Beneficio_COVID.pdf' target="_blank" rel="noopener noreferrer">documento</a>
      </div>
      <div className="covid-info">
        <p>Si usted cumple con los requisitos postule a través de un correo en el siguiente link, indicando en la casilla de comentarios "Postulación Covid-19":</p>
        <p><Link to="/contacto">Enviar correo</Link></p>
        <p>Una vez recibido nuestros ejecutivos le responderán a la brevedad.</p>
      </div>
      <div className="covid-table">
        <h2>Solicitudes</h2>
        <table cellpadding="2" cellspacing="0" border="1">
          <thead>
            <tr>
              <th>60% más vulnerable</th>
              <th>Tercera edad</th>
              <th>Cesantía</th>
              <th>Total</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>136</td>
              <td>26</td>
              <td>7</td>
              <td>169</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="covid-table">
        <h2>Resultados</h2>
        <table cellpadding="2" cellspacing="0" border="1">
          <thead>
            <tr>
              <th></th>
              <th>60% más vulnerable</th>
              <th>Tercera edad</th>
              <th>Cesantía</th>
              <th>Total</th>
              <th>%</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Otorgados</td>
              <td>117</td>
              <td>21</td>
              <td>7</td>
              <td>145</td>
              <td>86%</td>
            </tr>
            <tr>
              <td>No otorgados</td>
              <td>19</td>
              <td>5</td>
              <td>0</td>
              <td>24</td>
              <td>14%</td>
            </tr>
            <tr>
              <td>En evaluación</td>
              <td>0</td>
              <td>0</td>
              <td>0</td>
              <td>0</td>
              <td>0%</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  )
}

const WinterAdvice = () => {
  return (
    <div>
      <h2>Consejos de Invierno 2020</h2>
      <img src="https://aguas-patagonia-public-files.s3-sa-east-1.amazonaws.com/consejos-de-invierno.jpg" alt="consejos de invierno" />
    </div>
  )
}

const Declaration = () => {
  return (
    <div>
      <h2>Declaración sobre cuentas de Agua Potable y otros servicios</h2>
      <img src="https://aguas-patagonia-public-files.s3-sa-east-1.amazonaws.com/declaracion-1.jpg" alt="declaracion de aguas 1" />
      <img src="https://aguas-patagonia-public-files.s3-sa-east-1.amazonaws.com/declaracion-2.jpg" alt="declaracion de aguas 2" />
    </div>
  )
}

const Compromise = () => {
  return (
    <div>
      <h2>Estamos comprometidos</h2>
      <img src="https://aguas-patagonia-public-files.s3-sa-east-1.amazonaws.com/compromiso.jpg" alt="compromiso" />
    </div>
  )
}

const Offices = () => {
  return (
    <div>
      <h2>Apertura oficina Coyhaique y Puerto Aysén</h2>
      <img src="https://aguas-patagonia-public-files.s3-sa-east-1.amazonaws.com/apertura-oficinas.jpg" alt="apertura de oficinas" />
    </div>
  )
}

const Channels = () => {
  return (
    <div>
      <h2>Recomendación canales de atención</h2>
      <img src="https://aguas-patagonia-public-files.s3-sa-east-1.amazonaws.com/canales-atencion.jpg" alt="canales de atencion" />
    </div>
  )
}

const CovidPrevent = () => {
  return (
    <div>
      <h2>Medidas de prevención CORONAVIRUS COVID-19</h2>
      <img src="https://aguas-patagonia-public-files.s3-sa-east-1.amazonaws.com/covid19-minsal.png" alt="covid-19 minsal" />
      <img src="https://aguas-patagonia-public-files.s3-sa-east-1.amazonaws.com/covid19-mutual.png" alt="covid-19 mutual" />
    </div>
  )
}

function News() {
  const [item, setItem] = useState('women-certification')
  const [currentReport, setCurrentReport] = useState(null)
  const [reports, setReports] = useState([]);

  useEffect(() => {
    const getReports = async () => {
      const res = await axios.get("/api/reports")
      const response = res.data.data
      const reports= response.reports.filter(report => {return report.active})
      reports.sort((a, b) => {
        return a.order - b.order
      })
      setReports(reports);
    }
    getReports();
    }, []);

  function genericNews(text,report){
    setItem(text);
    setCurrentReport(report);
  }

  const Generic = () =>{
    return (
      <div>
        <h2>{currentReport.title}</h2>
        <img src={currentReport.url_file} alt={currentReport.name} />
        {currentReport.url_file_2? <img src={currentReport.url_file_2} alt={currentReport.name} /> : null}
        {currentReport.text? <div>
        <p className="text-generic-news">{currentReport.text}</p> 
        </div>: null}
      </div>
    )
  }
  

  const renderContent = () => {
    switch(item) {
    case 'women-certification':
      return <WomenCertification />

    case 'winter-reinforcement':
      return <WinterReinforcement />

    case 'fugas':
      return <Fugas />

    case 'alimentadora-chile-chico':
      return <Alimentadora />

    case 'covid-benefit':
      return <CovidBenefit />

    case 'winter-advice':
      return <WinterAdvice />

    case 'declaration':
      return <Declaration />

    case 'compromise':
      return <Compromise />

    case 'offices':
      return <Offices />

    case 'channels':
      return <Channels />

    case 'covid-prevent':
      return <CovidPrevent />
    
    case 'generic-news':
      return <Generic />

    default:
      return <CovidBenefit />
    }
  }

  return (
    <Page
      title="Aguas Patagonia | Comunicados y Noticias"
      current='clients'
    >
      <div className="page-container-news">
        <div className='icon-container-dev'>
          <img src='/img/icon5.png' alt='icon'></img>
        </div>
        <div className='text-wrapper-dev'>
          <h1>Comunicados y Noticias</h1>
          <div className="comunicados">
            <div className="com-sidebar">
             {
                reports.map(
                  report => <div className="com-sidebar-item" onClick={() => { genericNews('generic-news',report) }}>
                  {report.short_title}
                  </div>
                )
              }
              <div className="com-sidebar-item" onClick={() => { setItem('women-certification') }}>
                Certificación Cursos
              </div>
              <div className="com-sidebar-item" onClick={() => { setItem('winter-reinforcement') }}>
                Refuerzo Campaña de Invierno
              </div>
              <div className="com-sidebar-item" onClick={() => { setItem('fugas') }}>
                Como Detectar Fugas en tu Hogar
              </div>
              <div className="com-sidebar-item" onClick={() => { setItem('alimentadora-chile-chico') }}>
                Nueva Alimentadora de Agua Potable en Chile Chico
              </div>
              <div className="com-sidebar-item" onClick={() => { setItem('covid-benefit') }}>
                Beneficio Covid-19
              </div>
              <div className="com-sidebar-item" onClick={() => { setItem('winter-advice') }}>
                Consejos de Invierno
              </div>
              <div className="com-sidebar-item" onClick={() => { setItem('declaration') }}>
                Declaración de Aguas
              </div>
              <div className="com-sidebar-item" onClick={() => { setItem('compromise') }}>
                Estamos comprometidos
              </div>
              <div className="com-sidebar-item" onClick={() => { setItem('offices') }}>
                Apertura de Oficinas
              </div>
              <div className="com-sidebar-item" onClick={() => { setItem('channels') }}>
                Canales de Atención
              </div>
              <div className="com-sidebar-item" onClick={() => { setItem('covid-prevent') }}>
                Medidas de Prevención Covid-19
              </div>             
            </div>
            <div className="com-content">
              {renderContent()}
            </div>
          </div>
        </div>
      </div>
    </Page >
  )
}

export default News